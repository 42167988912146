import React, { useContext, useEffect, useRef, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";

// Pages
import TrackingItemsProducts from "./TrackingItemsProducts";

// Context
import ModalContext from "../context/ModalContext";
import AdminContext from "../context/AdminContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function TrackingItems(props) {
  // console.log("Render TrackingItems");

  // Check window size for any changes
  const size = useWindowSize();

  // Context
  const {
    setTrackingModalOpen,
    setSalePaymentChange,
    trackingRefresh,
    setTrackingRefresh,
  } = useContext(ModalContext);
  const {
    salesOrderInfo,
    salesProductData,
    setTrackingSalesOrderInfo,
    setTrackingSalesProductData,
  } = useContext(AdminContext);

  // State
  const [vPaid, setvPaid] = useState(false);
  const [vReady, setvReady] = useState(false);
  const [vDeliverying, setvDeliverying] = useState(false);
  const [vtrackingOrderProductData, setvtrackingOrderProductData] = useState(
    []
  );
  const [vtrackingOrderProductData3Up, setvtrackingOrderProductData3Up] =
    useState([]);
  const [vReceived, setvReceived] = useState(false);
  const [hideImage, setHideImage] = useState(false);
  const [hideCount, setHideCount] = useState(false);
  const [hideImageCount, setHideImageCount] = useState(false);

  const effectRunOnce = useRef(false);

  useEffect(() => {
    // Hide image if more than 2 product data
    if (size.width < 760) {
      setHideImage(false);
      setHideImageCount(true);
    } else if (size.width > 760) {
      setHideImage(true);
      if (hideCount > 0) {
        setHideImageCount(false);
      } else {
        setHideImageCount(true);
      }
    }
  }, [size, hideCount]);

  useEffect(() => {
    effectRunOnce.current = false;

    setvReady(props.orderReady);
    setvDeliverying(props.delivering);
    setvReceived(props.clientReceived);

    // Paid
    if (props.paid === "Yes") {
      setvPaid(true);
    }

    // Update product data
    if (props.products.length > 0) {
      if (trackingRefresh === true) {
        let data = [];
        data = props.products?.filter(
          (data) => data.Order_No === props.Order_No
        );

        // console.log(data);
        if (data.length > 2) {
          // First 2 items
          setvtrackingOrderProductData(data.filter((data) => data.ID < 3));
          // console.log(data.filter((data) => data.id < 3));
          // Rest of the items
          setvtrackingOrderProductData3Up(data.filter((data) => data.ID > 2));
          setHideCount(data.length - 2);
          // console.log(data.length - 2)
        } else {
          setvtrackingOrderProductData(data);
          setvtrackingOrderProductData3Up([]);
        }
        // console.log(data);
      }
      setTrackingRefresh(false);
    }

    effectRunOnce.current = true;
  }, [props, trackingRefresh, hideCount, setTrackingRefresh]);

  const handleClick = () => {
    setSalePaymentChange(false);

    try {
      setTrackingSalesOrderInfo(
        salesOrderInfo?.filter((data) => data.Order_No === props.Order_No)
      );
      setTrackingSalesProductData(
        salesProductData?.filter((data) => data.Order_No === props.Order_No)
      );
      setTrackingModalOpen(true);
    } catch (error) {
      setTrackingModalOpen(false);
    }
  };

  // console.log(vtrackingOrderProductData);
  // console.log(vtrackingOrderProductData3Up);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {/* page */}
      <div className="flex w-100 justify-center font-Montserrat mt-4 mb-5 xl:mb-0">
        {/* Container */}
        <div
          onClick={handleClick}
          className="flex-row w-[98%] lg:w-[85%] md:w-[90%] justify-center bg-white rounded-md border-2 border-BNPblue border-opacity-40 transition-all duration-300"
        >
          <div className="flex justify-center w-100 px-4">
            {/* Status Bar */}
            <div className="relative flex justify-between mt-3 max-w-[500px] w-100 z-0">
              {/* paid */}
              <div className="relative">
                <div className="flex-row w-[50px]">
                  <p
                    className={
                      vPaid
                        ? "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPgoldLight bg-BNPblue text-BNPgoldLight"
                        : "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPblue bg-BNPgoldLight"
                    }
                  >
                    1
                  </p>
                  <p className="flex justify-center mt-3 w-100 text-center">
                    Paid
                  </p>
                  <div
                    className={
                      vReady
                        ? "absolute w-[110px] top-[20px] left-11 z-[1] h-2 bg-BNPblue"
                        : vPaid
                        ? "absolute w-[110px] top-[20px] left-11 -z-[1] h-2 bg-gradient-to-r from-BNPblue to-BNPgoldLight"
                        : "absolute w-[110px] top-[20px] left-11 -z-[1] h-2 bg-BNPgoldLight"
                    }
                  ></div>
                </div>
              </div>

              {/* Order Type Desc */}
              <div className="relative">
                <div className="flex-row w-[50px]">
                  <p
                    className={
                      vReady
                        ? "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPgoldLight bg-BNPblue text-BNPgoldLight"
                        : "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPblue bg-BNPgoldLight"
                    }
                  >
                    2
                  </p>
                  <p className="flex justify-center mt-3 w-100 text-center">
                    {props.orderType}
                  </p>
                  <div
                    className={
                      vDeliverying
                        ? "absolute w-[110px] top-[20px] left-11 z-[1] h-2 bg-BNPblue"
                        : vReady
                        ? "absolute w-[110px] top-[20px] left-11 -z-[1] h-2 bg-gradient-to-r from-BNPblue to-BNPgoldLight"
                        : "absolute w-[110px] top-[20px] left-11 -z-[1] h-2 bg-BNPgoldLight"
                    }
                  ></div>
                </div>
              </div>

              {/* Order out for Delivery */}
              <div className="relative">
                <div className="flex-row w-[50px]">
                  <p
                    className={
                      vDeliverying
                        ? "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPgoldLight bg-BNPblue text-BNPgoldLight"
                        : "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPblue bg-BNPgoldLight"
                    }
                  >
                    3
                  </p>
                  <p className="flex justify-center mt-3 w-100 text-center">
                    {props.deliveryType}
                  </p>
                  <div
                    className={
                      vReceived
                        ? "absolute w-[110px] top-[20px] left-11 z-[1] h-2 bg-BNPblue"
                        : vDeliverying
                        ? "absolute w-[110px] top-[20px] left-11 -z-[1] h-2 bg-gradient-to-r from-BNPblue to-BNPgoldLight"
                        : "absolute w-[110px] top-[20px] left-11 -z-[1] h-2 bg-BNPgoldLight"
                    }
                  ></div>
                </div>
              </div>

              {/* Client Received */}
              <div className="relative">
                <div className="flex-row w-[50px]">
                  <p
                    className={
                      vReceived
                        ? "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPgoldLight bg-BNPblue text-BNPgoldLight"
                        : "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPblue bg-BNPgoldLight"
                    }
                  >
                    4
                  </p>
                  <p className="flex justify-center mt-3 w-100 text-center">
                    Client Received
                  </p>
                  <div
                    className={
                      vReceived
                        ? "absolute w-[5px] top-[20px] left-0 h-2 bg-BNPblue"
                        : "hidden"
                    }
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {/* Body */}
          <div className="flex justify-center w-100 mt-2 mb-4">
            <div className="flex-row lg:flex justify-center w-[85%] p-4 border-2 border-BNPblue border-opacity-60 rounded-md hover:shadow-md hover:shadow-BNPblue cursor-pointer">
              <div className="flex-row s1360:flex space-y-2 s1360:space-y-0 transition-all duration-300">
                {/* Column 1 */}
                <div className="flex justify-center w-auto min-w-fit">
                  <div className="flex-row pr-6">
                    {/* Order Number */}
                    <div className="flex justify-between items-center">
                      <p className="font-semibold text-lg">Order number:</p>
                      {props.Order_No}
                    </div>
                    {/* Sales Date */}
                    <div className="flex justify-between items-center">
                      <p className="font-semibold text-lg mr-10 ">
                        Sales date:
                      </p>
                      <p> {props.SalesDate} </p>
                    </div>
                    {/* Payment Method */}
                    <div className="flex justify-between items-center">
                      <p className="font-semibold text-lg mr-10 ">
                        Payment Type:
                      </p>
                      <p> {props.paymentType} </p>
                    </div>
                    {/* Paid */}
                    <div className="flex justify-between items-center">
                      <p className="font-semibold text-lg mr-10 ">Paid:</p>
                      <p
                        className={
                          vPaid
                            ? "font-semibold"
                            : "text-red-500 font-bold animate-pulse duration-100 border-4 rounded-full p-1 border-red-500"
                        }
                      >
                        {props.paid}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Column 2 */}
                <div className="flex w-100 justify-center lg:justify-start items-center">
                  <div className="flex-row s840:flex mt-2 lg:mt-0 s840:ml-2 ml-0 space-x-0 s840:space-x-2 space-y-2 s840:space-y-0">
                    {/* First 2 Items Images */}
                    {vtrackingOrderProductData.map((data) => (
                      <div key={data.ID}>
                        {/* Sales Item */}
                        <TrackingItemsProducts
                          Order_No={data.Order_No}
                          prodType={data.prodType}
                          size={data.size}
                          reg={data.reg}
                          province={data.province}
                          quant={data.quant}
                        />
                      </div>
                    ))}
                    {/* Remaining Items Images 3up*/}
                    <div
                      className={
                        hideImage
                          ? "hidden"
                          : "flex-row s840:flex mt-2 lg:mt-0 s840:ml-2 ml-0 space-x-0 s840:space-x-2 space-y-2 s840:space-y-0"
                      }
                    >
                      {vtrackingOrderProductData3Up.map((data) => (
                        <div key={data.ID}>
                          {/* Sales Item */}
                          <TrackingItemsProducts
                            Order_No={data.Order_No}
                            prodType={data.prodType}
                            size={data.size}
                            reg={data.reg}
                            province={data.province}
                            quant={data.quant}
                          />
                        </div>
                      ))}
                    </div>
                    <div>
                      <div
                        className={
                          hideImageCount
                            ? "hidden"
                            : "flex justify-center items-center text-BNPblue border-2 border-BNPblue border-opacity-40 rounded-md w-20 h-20 text-xl bg-BNPgoldLightLight"
                        }
                      >
                        <p>+{hideCount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
