import React, { useRef, useState, useContext, useEffect } from "react";
import { Transition } from "@headlessui/react";

// Formatting
import formatProperCase from "../utilities/formatProperCase";

// pages
import ProvinceDD from "../data/Lookups/prices.json";

// Context
import AddressContext from "../context/UserContext";
import ModalContext from "../context/ModalContext";
import UserContext from "../context/UserContext";

// Img
import * as riIcons from "react-icons/ri";

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function ProvinceLookup() {
  // console.log("Render ProvinceLookup");

  // Context
  const { Province, setProvince } = useContext(AddressContext);
  const { provDdOpen, setProvDdOpen, provInValid, setProvInValid, provUpdate } =
    useContext(ModalContext);
  const { setDeliverAddressUpdate } = useContext(UserContext);

  const ProvinceRef = useRef();
  const [ProvinceMove, setProvinceMove] = useState(false);

  // build Province Array

  const [clearText, setClearText] = useState(false);

  const filterData = ProvinceDD.filter(
    (data) => data.prov !== "BP" && data.prov !== "RV"
  );
  filterData.sort((a, b) => (a.name > b.name ? 1 : -1));
  // Filter by input
  const filterData2 = filterData;
  // .filter((data) => {
  //   return data.name.toLowerCase().startsWith(Province.toLowerCase());
  // });

  useEffect(() => {
    if (Province?.length !== 0 || provDdOpen === true) {
      setProvinceMove(true);
    } else {
      setProvinceMove(false);
    }

    if (Province?.length === 0) {
      setClearText(false);
    } else {
      setClearText(true);
      setProvInValid(true);

      if (
        filterData.find((data) => {
          return data.name.toLowerCase() === Province.toLowerCase();
        })
      ) {
        // console.log("match full prov")
        setProvInValid(false);
      } else if (
        // check if dd closed to early
        provDdOpen === false
      ) {
        setProvInValid(true);
      } else if (
        filterData.find((data) => {
          return data.name.toLowerCase().startsWith(Province.toLowerCase());
        })
      ) {
        setProvInValid(false);
      }
    }
  }, [Province, filterData, provDdOpen, setProvInValid]);

  const closeOutSide = (e) => {
    setProvDdOpen(!provDdOpen);
  };

  // Province
  const ProvinceFocusOn = () => {
    setProvinceMove(true);
    ProvinceRef.current.focus();
  };

  const ProvinceFocusOff = () => {
    if (Province?.length !== 0 || provDdOpen === true) {
      setProvinceMove(true);
    } else {
      setProvinceMove(false);
    }
  };

  // Reset Input Field handler
  const clearProvice = () => {
    setProvince("");
    setClearText(false);
    setProvInValid(false);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div onClick={closeOutSide} className="p-1 mt-[7px] text-md">
        <div
          onClick={() => ProvinceFocusOn()}
          className="group w-[250px] h-[40px] BNP-LableDIV cursor-pointer"
        >
          <div className="flex">
            <input
              id="prov_lookup_Close"
              value={Province}
              onChange={(e) => {
                setProvince(formatProperCase(e.target.value));
                setProvDdOpen(true);
                setDeliverAddressUpdate(true);
              }}
              onBlur={() => ProvinceFocusOff()}
              type="text"
              ref={ProvinceRef}
              autoComplete="off"
              className={
                provUpdate
                  ? "w-[250px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-pointer bg-green-100 bg-opacity-50"
                  : provInValid
                  ? "w-[250px] h-[40px] pl-4  BNP-Input-thin text-lg text-BNPblue cursor-pointer border-4 focus:ring-4 focus:ring-red-500 focus:border-red-600 border-red-600 hover:border-[5px] hover:border-red-600" // Error in Text typing
                  : "w-[250px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-pointer"
              }
            />
            <div className="relative flex-row -ml-[36px] top-0 hover:bg-red-400">
              <riIcons.RiArrowDropDownLine
                id="prov_lookup_Close_Arrow"
                className={
                  provDdOpen
                    ? "absolute z-10 w-10 h-10 text-3xl cursor-pointer transition-all duration-500 rotate-180 text-BNPblue"
                    : "absolute z-10 w-10 h-10 text-3xl cursor-pointer transition-all duration-500 text-BNPblue"
                }
              />
            </div>
            <div className="absolute z-20 w-[250px] mt-[37px]">
              <Transition
                show={provDdOpen}
                enter="transition ease-in-out duration-700 transform"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in-out duration-700 transform"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="bg-white text-BNPblue border-2 border-BNPblue rounded-b-md shadow-md focus:ring focus:border-BNPblue hover:border-BNPblue BNP-Trans200"
              >
                <div className="flex-row items-center bg-white max-h-48 overflow-y-auto">
                  {filterData2.map((items) => (
                    <div
                      id={items.name}
                      key={items.name}
                      onClick={(e) => {
                        setProvince(e.target.id);
                        setProvDdOpen(false);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setProvince(e.target.id);
                          setProvDdOpen(false);
                        }
                      }}
                      tabIndex={0}
                      className="pl-4 py-1 text-lg hover:bg-BNPblue hover:text-BNPgoldLight"
                    >
                      {items.name}
                    </div>
                  ))}
                </div>
              </Transition>
            </div>
          </div>
          <h4
            onClick={() => setProvDdOpen(!provDdOpen)}
            className={
              ProvinceMove
                ? "w-[61px] BNP-LableMove -translate-y-[50px]"
                : "w-[100px] BNP-LableStart pt-[4px] bg-opacity-0"
            }
          >
            <div id="prov_lookup_Close" className="flex">
              Province
              <div
                className={
                  ProvinceMove ? "hidden" : "font-semibold pl-1 text-red-700"
                }
              >
                *
              </div>
            </div>
          </h4>
        </div>
      </div>
      <div className="flex w-[250px] h-5 justify-end">
        <Transition
          show={clearText}
          enter="transition ease-in-out duration-500 transform"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in-out duration-500 transform"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <p
            onClick={clearProvice}
            className="w-[55px] BNP-Links -mt-3 mb-0 pb-0"
          >
            clear
          </p>
        </Transition>
      </div>
    </>
  );
}
