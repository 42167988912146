import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// context
import ModalContext from "../context/ModalContext";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function LoggedOutPage() {
  // console.log("Render LoggedOutPage")

  // context
  const { setloginModal, closeModals,  } = useContext(ModalContext);

  // state
  const effectRunOnce = useRef(false);

  useEffect(() => {
    if (effectRunOnce === false) {
      closeModals();
      effectRunOnce.current = true;
    }
  }, [closeModals]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="flex-row w-100 font-Montserrat text-center text-BNPblue pt-7">
      <div className="flex-row justify-center items-center font-semibold space-y-5">
        <h1 className="text-3xl">You have successfully logged out!</h1>
        <div className="flex justify-center items-center">
          <div className="flex items-center pt-3 border-t-4 border-BNPblue border-opacity-40 ">
            <h1 className="text-2xl mr-2">You can</h1>
            <p
              onClick={() => setloginModal(true)}
              className="text-2xl BNP-Links p-0 m-0"
            >
              Login
            </p>
            <h1 className="text-2xl ml-2">
              again to access your personal information
            </h1>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center font-semibold text-xl">
        <h1>or </h1>
      </div>

      <div className="flex justify-center items-center font-semibold text-xl">
        <h1>Go to </h1>

        <div className="BNP-Links">
          <Link to="/">home </Link>
        </div>

        <h1 className="mr-3">page</h1>
      </div>
    </div>
  );
}
