import React, { useContext, useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import axios from "axios";
import md5 from "md5";

//lookups
import DeliveryCost from "../data/Lookups/prices.json";

// Pages
import CartItems from "./SalesPaymentItems";

// Icons
import * as faIcons from "react-icons/fa";

// Context
import userContext from "../context/UserContext";
import UrlContext from "../context/URLContext";
import SalesCartContext from "../context/SalesCartContext";
import modalContext from "../context/ModalContext";

// Utilities
import formatCurrency from "../utilities/formatCurrency";
import formatPhoneNumber from "../utilities/formatPhoneNumber";
import RemoveFunnies from "../utilities/fromatRemoveFunnies";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function CartConfirmation() {
  // console.log("Render SalePayment");

  // Navigate
  const nav = useNavigate();

  // Context
  const {
    BNPdiscounts,
    dealerDiscounts,
    salePush,
    salePushItems,
    userDeliveryAddressUpdate,
    userAddress,
  } = useContext(UrlContext);

  const {
    cartItems,
    cartVol,
    deliveryCost,
    setDeliveryCost,
    cartCost,
    updateCartCost,
    cartDiscount,
    setCartDiscount,
    discountAmt,
    UpdateDiscount,
    setCartClick,
    deliveryCheck,
    totalAmt,
    UpdateCartTotal,
    payMethod,
    setPayMethod,

    setInvNo,
    cashPay,
    saleRefreshPay,
    setSaleRefreshPay,

    dealerPayMethodDisable,
    setDealerPayMethodDisable,
  } = useContext(SalesCartContext);

  const {
    businessUser,
    userID,
    bussinesName,
    ComplexName,
    DeliveryName,
    RecipientsCell,
    Address,
    Suburb,
    City,
    Province,
    PostalCode,
    deliverAddressUpdate,
    setDeliverAddressUpdate,
    updateAddressContext,

    accessToken,
  } = useContext(userContext);

  const {
    setloginModal,
    setdeliveryMethodOpen,
    setDeliveryAddressOpen,
    setMsgOpen,
    msgClean,
    setMsgTxt,
    setMsgType,
    setNavLink,
    setNavLinkTxt,
    setSalePayFastOpen,
    setSalePayShapOpen,
    setSaleYocoCashOpen,
    setSalePaymentCheckLoginOpen,
  } = useContext(modalContext);

  // state
  const [delType, setDelType] = useState("Collect");
  const [cartCountWording, setCartCountWording] = useState("Item");
  const [delCost, setDelCost] = useState("Free");

  const [discountsItems, setdiscountsItems] = useState([]);
  const DiscountVoucherRef = useRef();
  const [DiscountVoucherMove, setDiscountVoucherMove] = useState(false);
  const [ClearText, setClearText] = useState(false);

  const [discountValid, setDiscountValid] = useState(false);
  const [discountInValid, setDiscountInValid] = useState(false);
  const [DiscountVoucher, setDiscountVoucher] = useState("");

  const [addressMove, setAddressMove] = useState(false);

  const [clearError, setClearError] = useState(0);

  const [paymentPayfast, setPaymentPayfast] = useState(false);
  const [paymentDealer, setPaymentDealer] = useState(false);
  const [paymentPayShap, setPaymentPayShap] = useState(false);
  const [paymentYocoCash, setPaymentYocoCash] = useState(false);

  const [dealerDisable, setDealerDisable] = useState(false);
  const [dealerDiscountRefresh, setDealerDiscountRefresh] = useState(false);

  const [btnWait, setBtnWait] = useState(false);

  const effectRunOnce = useRef(false);
  const effectRunDealerLogin = useRef(false);

  // variables
  let loadNewToken = accessToken;

  useEffect(() => {
    // Direct back to sales page if cart is cleared
    if (cartVol === 0) {
      nav("/sales");
    }

    if (deliveryCheck === true) {
      setDelType("Delivery");
      if (payMethod === "cash" || payMethod === "yoco") {
        setPayMethod("payfast");
      }
    } else {
      setDelType("Collect");
    }

    if (cartVol === 1) {
      setCartCountWording("Item  ");
    } else {
      setCartCountWording("Items's  ");
    }

    if (deliveryCost === 0) {
      setDelCost("Free");
    } else {
      setDelCost(formatCurrency(deliveryCost));
    }

    if (cartVol === 0) {
      setDeliveryCost(0);
      setDelCost("Free");
      updateCartCost();
    }

    if (deliveryCheck === true && Province !== "") {
      try {
        setDeliveryCost(
          DeliveryCost.filter((item) => {
            return item.name === Province;
          })[0].delivery
        );
      } catch (error) {
        setCartDiscount(0);
      }
    } else setDeliveryCost(0);

    if (DeliveryName?.length > 0 && deliveryCheck === true) {
      setAddressMove(true);
    } else {
      setAddressMove(false);
    }

    // Disable pay button if not logged into account
    if (userID === "" && payMethod === "dealer" && businessUser === 0) {
      setDealerDisable(true);
    } else {
      setDealerDisable(false);
    }

    if (payMethod === "payfast") {
      setPaymentPayfast(true);
      setPaymentDealer(false);
      setPaymentPayShap(false);
      setPaymentYocoCash(false);
    } else if (payMethod === "dealer") {
      setPaymentPayfast(false);
      setPaymentDealer(true);
      setPaymentPayShap(false);
      setPaymentYocoCash(false);
    } else if (payMethod === "payshap") {
      setPaymentPayfast(false);
      setPaymentDealer(false);
      setPaymentPayShap(true);
      setPaymentYocoCash(false);
    } else if (payMethod === "yoco" || payMethod === "cash") {
      setPaymentPayfast(false);
      setPaymentDealer(false);
      setPaymentPayShap(false);
      setPaymentYocoCash(true);
    }

    if (DiscountVoucher?.length > 0) {
      setDiscountVoucherMove(true);
    }

    if (effectRunOnce.current === false) {
      if (payMethod === "dealer") {
        setPayMethod("payfast");
      }
      effectRunOnce.current = true;
    }
  }, [
    userID,
    deliveryCheck,
    DeliveryName,
    cartVol,
    deliveryCost,
    updateCartCost,
    setDeliveryCost,
    Province,
    delCost,
    setCartDiscount,
    nav,
    payMethod,
    setPayMethod,
    DiscountVoucher,
    businessUser,
  ]);

  const deliveryMethod = () => {
    setdeliveryMethodOpen(true);

    if (userID !== "") {
      if (
        DeliveryName?.length === 0 ||
        typeof DeliveryName === "undefined" ||
        RecipientsCell?.length === 0 ||
        typeof RecipientsCell === "undefined" ||
        Address?.length === 0 ||
        typeof Address === "undefined" ||
        Suburb?.length === 0 ||
        typeof Suburb === "undefined" ||
        City?.length === 0 ||
        typeof City === "undefined" ||
        Province?.length === 0 ||
        typeof Province === "undefined" ||
        PostalCode?.length === 0 ||
        typeof PostalCode === "undefined"
      ) {
        loadAddressinfo(userID);
      }
    }
  };

  // Load address info if missing and cookie login
  const loadAddressinfo = async (i) => {
    // Validate JWT accessToken
    document.getElementById("validateClick")?.click();
    // console.log(loadNewToken);
    // console.log("update address info with cookie login");

    // request data from mySQL db
    let url = userAddress + i;
    const data = await axios
      .get(url, { headers: { Authorization: loadNewToken } })
      .then((res) => res.data[0])
      .catch((err) => {
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });
    // console.log(data);
    updateAddressContext(data);
  };

  // DiscountVoucher
  const DiscountVoucherFocusOn = () => {
    setDiscountVoucherMove(true);
    DiscountVoucherRef.current.focus();
  };

  const DiscountVoucherFocusOff = () => {
    if (DiscountVoucher?.length !== 0) {
      setDiscountVoucherMove(true);
    } else {
      setDiscountVoucherMove(false);
    }
  };

  const handleDiscount = () => {
    if (discountValid === false) {
      try {
        setCartDiscount(
          discountsItems.filter((item) => {
            return item.discountCode === DiscountVoucher;
          })[0].perc
        );
      } catch (error) {
        setCartDiscount(0);
      }
      setDiscountValid(false);
      setDiscountInValid(true);
      DiscountVoucherRef.current.focus();
    }
  };

  // get Discount %
  useEffect(() => {
    if (cartDiscount === 0 && DiscountVoucher?.length === 0) {
      setDiscountValid(false);
      setDiscountInValid(false);
      setClearText(false);
    } else {
      if (cartDiscount !== 0 && DiscountVoucher?.length !== 0) {
        setDiscountValid(true);
        setDiscountInValid(false);
      }
    }

    UpdateDiscount();
    UpdateCartTotal();
  }, [cartDiscount, DiscountVoucher, UpdateDiscount, UpdateCartTotal]);

  // Reset Input Field handler
  const clearDiscText = () => {
    setCartDiscount(0);
    setDiscountVoucher("");
    setClearText(false);
    setDiscountInValid(false);
    setDiscountValid(false);
  };

  // Load available discounts from DB
  const loadDiscounts = async () => {
    //  Hash build
    let pw = md5("BNPDiscounts" + process.env.REACT_APP_UCNPASSPHRASE);

    try {
      // request data from mySQL db
      let url = BNPdiscounts;
      // console.log(url);
      const data = await axios
        .get(url, { params: { y: "BNPDiscounts", z: pw } })
        .then((res) => res.data)
        .catch((err) => {
          // console.log(err);
        });
      // console.log(data[0]);

      setdiscountsItems(data[0]);
      // console.log(data);
    } catch (error) {
      console.log("Error loading discounts");
    }
  };

  useEffect(() => {
    if (clearError === 0) {
      msgClean();
      setClearError(1);
    }
  }, [clearError, msgClean]);

  const handleDealerClick = () => {
    if (dealerDisable === true) {
      msgClean();
      setMsgTxt(
        "Please Log into your Dealership account before continuing with you order placement!"
      );
      setMsgType("Warning");
      setNavLink("loginModal");
      setNavLinkTxt("Login");
      setMsgOpen(true);
    } else {
      // alert("run dealer process");
      sendDataDB();
    }
  };

  useEffect(() => {
    if (effectRunDealerLogin.current === false) {
      if (payMethod === "dealer" && userID !== "") {
        if (dealerDiscountRefresh === true) {
          // Load available Dealer discounts from DB
          async function fetchData() {
            // request data from mySQL db
            let url = dealerDiscounts + userID;
            const data = await axios
              .get(url, { headers: { Authorization: loadNewToken } }) // JWS Token
              .then((res) => res.data[0])
              .catch((err) => {});
            // console.log(data);
            // console.log(userID);
            try {
              setDiscountVoucher(data[0].discountCode);
              setCartDiscount(data[0].perc);
              setDiscountValid(true);
              setDiscountInValid(false);
            } catch (error) {}
          }

          fetchData();
          effectRunDealerLogin.current = true;
          setDealerDiscountRefresh(false);
        }
      }
    }
  }, [
    payMethod,
    userID,
    setCartDiscount,
    setMsgOpen,
    msgClean,
    setMsgTxt,
    setMsgType,
    discountValid,
    dealerDiscounts,
    loadNewToken,
    dealerDiscountRefresh,
  ]);

  //Pay Now - Push Sale data to mySQL db.
  const sendDataDB = async () => {
    setBtnWait(true);
    setClearError();
    let run = 1;

    // Delivery method = delivery
    if (deliveryCheck === true) {
      // Check delivery details
      if (
        DeliveryName?.length === 0 ||
        typeof DeliveryName === "undefined" ||
        RecipientsCell?.length === 0 ||
        typeof RecipientsCell === "undefined" ||
        Address?.length === 0 ||
        typeof Address === "undefined" ||
        Suburb?.length === 0 ||
        typeof Suburb === "undefined" ||
        City?.length === 0 ||
        typeof City === "undefined" ||
        Province?.length === 0 ||
        typeof Province === "undefined" ||
        PostalCode?.length === 0 ||
        typeof PostalCode === "undefined"
      ) {
        run = 0;
        msgClean();
        setMsgTxt(
          "You have selected Delivery, but no delivery details are pressent!"
        );
        setMsgType("Warning");
        setNavLink("AddressModal");
        setNavLinkTxt("Update Delivery Details");
        setMsgOpen(true);
      }
    }

    // Check Dealer login validation
    if (businessUser === 0 && payMethod === "dealer") {
      run = 0;
      msgClean();
      setMsgTxt(
        "You are Unfortunately not registered as a Dealer with us.  Please make contact with us to complete the Vetting and Registration process."
      );
      setMsgType("Warning");
      setNavLink("/Contacts");
      setNavLinkTxt("Contact Us");
      setMsgOpen(true);

      // Disable Dealership payments
      setDealerPayMethodDisable(true);
      setPayMethod("payfast");
    }

    // Check user logged in
    if (userID?.length === 0) {
      run = 0;
      console.log("Not Logged in!!!!  Login");
      setSalePaymentCheckLoginOpen(true);
    } else {
      // Validate JWT accessToken
      document.getElementById("validateClick")?.click();
      // console.log(loadNewToken);
      // console.log("Push sale data to mySQL DB");
    }

    if (run === 1) {
      // Push User data to mySQL db.
      let error = 0;
      let deliveryType = 0;
      if (deliveryCheck === true) {
        deliveryType = 1;
      }

      loadNewToken = accessToken;
      let url = salePush;
      const result = await axios
        .post(
          url,
          {
            payMethod,
            userID,
            cartVol,
            cartCost,
            DiscountVoucher,
            discountAmt,
            deliveryType,
            deliveryCost,
            totalAmt,
          },
          { headers: { Authorization: loadNewToken } } // JWS Token
        )
        .then((res) => res)
        .catch((err) => {
          error = 1;
          msgClean();
          setMsgTxt(
            "Network error!  Please check your Internet connection and try again."
          );
          setMsgType("Error");
          setMsgOpen(true);
        });
      // console.log(result);

      if (error === 0) {
        if (result.status === 200) {
          if (result.data.length > 0) {
            const data = result.data[0];

            let n = data[0].OrderNumber;
            var zerofilled = ("00000" + n).slice(-5);
            setInvNo("INV" + zerofilled);

            // Send product info to db
            sendDataDBSalesItem(n); // Sales items details
            sendDeliveryDataDB(userID); // Delivery address details update

            // PayFast
            if (payMethod === "payfast") {
              setSalePayFastOpen(true);
            }
            // Dealers
            if (payMethod === "dealer") {
              nav("/PaySuccess");
            }
            // PayShap
            if (payMethod === "payshap") {
              setSalePayShapOpen(true);
            }
            // Yoco & Cash
            if (payMethod === "yoco" || payMethod === "cash") {
              setSaleYocoCashOpen(true);
            }
          } else {
            setInvNo("");
          }
        } else {
          setInvNo("");
        }
      }
    }
    setBtnWait(false);
  };

  const sendDataDBSalesItem = async (i) => {
    setClearError();

    let c = cartItems?.length;
    let l = 0;

    while (l < c) {
      let Order_ID = i;
      let UnqID = cartItems[l].id;
      let Prod_Type = cartItems[l].prodType;
      let Quant = cartItems[l].quant;
      let Province = cartItems[l].province;
      let size = cartItems[l].size;
      let Reg = RemoveFunnies(cartItems[l].reg.toUpperCase());
      let Plate = cartItems[l].plate;
      let Price = cartItems[l].price;
      let Name = cartItems[l].name;
      let Surname = cartItems[l].surname;
      let IDno = cartItems[l].idNo;
      let IDType = cartItems[l].IDType;
      let IDFile = cartItems[l].IDfile;
      let RegFile = cartItems[l].Regfile;
      let SelfieFile = cartItems[l].Selfie;

      // Push User data to mySQL db.
      let error = 0;

      let url = salePushItems;
      const result = await axios
        .post(
          url,
          {
            Order_ID,
            UnqID,
            Prod_Type,
            Quant,
            Province,
            size,
            Reg,
            Plate,
            Price,
            Name,
            Surname,
            IDno,
            IDType,
            IDFile,
            RegFile,
            SelfieFile,
          },
          { headers: { Authorization: loadNewToken } } // JWS Token
        )
        .then((res) => res)
        .catch((err) => {
          error = 1;
          msgClean();
          setMsgTxt(
            "Network error!  Please check your Internet connection and try again."
          );
          setMsgType("Error");
          setMsgOpen(true);
        });
      // console.log(result);

      if (error === 0) {
        if (result.status === 200) {
          if (result.data.length > 0) {
            msgClean();
            setMsgTxt("Product data send to db");
            setMsgType("Info");
            //setMsgOpen(true);
          } else {
            msgClean();
            setMsgTxt("Error with data supplied!  Check info and try again");
            setMsgType("Error");
            setMsgOpen(true);
          }
        }
      }

      l = l + 1;
    }
  };

  // Push Delivery details to db
  const sendDeliveryDataDB = async (i) => {
    // console.log("Deliver add ID");
    // console.log(i);
    if (deliverAddressUpdate === true) {
      // Update payment received to db.
      let url = userDeliveryAddressUpdate + i;
      const data = await axios
        .patch(
          url,
          {
            Recipient_Name: DeliveryName,
            Recipient_Cell: RemoveFunnies(RecipientsCell),
            Business_Name: bussinesName,
            Complex_Building: ComplexName,
            Street_Address: Address,
            Suburb: Suburb,
            City: City,
            Province: Province,
            Postal_Code: PostalCode,
          },
          { headers: { Authorization: loadNewToken } } // JWS Token
        )
        .then((res) => res)
        .catch((err) => {
          msgClean();
          setMsgTxt(
            "Network error!  Please check your Internet connection and try again."
          );
          setMsgType("Error");
          setMsgOpen(true);
        });
      // console.log(data);

      if (data.status === 200) {
        try {
          if (data.data.affectedRows > 0) {
            setDeliverAddressUpdate(false);
            console.log("Delivery Address info updated.");
          }
        } catch (error) {
          setDeliverAddressUpdate(false);
          console.log("Delivery Address info NOT updated.");
        }
      }
    }
  };

  useEffect(() => {
    if (saleRefreshPay === 1) {
      // PayFast
      if (payMethod === "payfast") {
        document.getElementById("SalesSendDataPF")?.click();
        // console.log("PayFast")
      }
      // PayShap
      if (payMethod === "payshap") {
        document.getElementById("SalesSendDataPS")?.click();
        // console.log("PayShap")
      }
      // Yoco & Cash
      if (payMethod === "yoco" || payMethod === "cash") {
        document.getElementById("SalesSendDataYocoCash")?.click();
        // console.log("Yoco Cash")
      }

      setSaleRefreshPay(0);
    }
  }, [saleRefreshPay, setSaleRefreshPay, payMethod, cashPay]);

  // console.log(payMethod);
  // console.log(dealerPayMethodDisable);
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="max-h-full font-Montserrat pb-10 overflow-y-scroll text-BNPblue">
        <div className="p-4 pl-12 text-xl font-bold ">Review your Order</div>
        <div className="flex-row xl:flex xl:pl-12">
          {/* column 1 */}
          <div className="flex-row xl:w-2/3 w-2/3 min-w-[350px] max-w-[800px] ml-12 mr-7 space-y-3">
            {/* Delivery Method */}
            <div className="p-4 bg-white">
              <div className="flex justify-between items-center">
                <p className="cursor-pointer">Delivery Method</p>
                <div
                  onClick={() => deliveryMethod()}
                  className="BNP-Links text-sm"
                >
                  Change
                </div>
              </div>
              <div className="flex font-semibold">{delType}</div>
            </div>

            {/* Delivery Address */}
            <div className="p-4 bg-white">
              <div className="flex justify-between items-center">
                <p>Delivery Address</p>
                <div
                  onClick={() => {
                    if (deliveryCheck === true) {
                      setDeliveryAddressOpen(true);
                    } else {
                      msgClean();
                      setMsgTxt(
                        "Please change " +
                          String.fromCodePoint(34) +
                          "Delivery Method" +
                          String.fromCodePoint(34) +
                          " before updating your Delivery address details!"
                      );
                      setMsgType("Info");
                      setMsgOpen(true);
                    }
                  }}
                  className="BNP-Links text-sm"
                >
                  Change
                </div>
              </div>

              <div
                className={
                  addressMove
                    ? "transition-height duration-700 ease-in-out h-[120px] flex font-semibold pt-1"
                    : "transition-height duration-300 ease-in-out h-0 opacity-0 flex font-semibold pt-1"
                }
              >
                <div className="flex-row w-1/3">
                  <p>{DeliveryName}</p>
                  <p>{formatPhoneNumber(RecipientsCell)}</p>
                  <p> {bussinesName}</p>
                  <p> {ComplexName}</p>
                </div>
                <div className="flex-row">
                  <p> {Address}</p>
                  <p> {Suburb}</p>
                  <p> {City}</p>
                  <p> {Province}</p>
                  <p> {PostalCode}</p>
                </div>
              </div>
            </div>

            {/* Delivery date */}
            <div className="p-4 bg-white">
              Delivery by
              <div className="flex justify-between items-center">
                date dynamic
              </div>
            </div>

            {/* Discount Voucher */}
            <div className="flex-row p-4 bg-white h-[120px] s740:h-[95px] BNP-Trans500">
              <div className="flex-row s740:flex items-center space-y-1 s740:space-y-0 BNP-Trans500">
                <div className="py-1 text-md">
                  <div
                    onClick={() => {
                      DiscountVoucherFocusOn();
                      // console.log("discount load");
                      loadDiscounts();
                    }}
                    className="relative group w-[250px] h-[40px] BNP-LableDIV cursor-text"
                  >
                    <input
                      value={DiscountVoucher}
                      onChange={(e) => {
                        setDiscountVoucher(e.target.value);
                        setClearText(true);
                        setDiscountInValid(false);
                        setCartDiscount(0);
                        setDiscountValid(false);
                      }}
                      onBlur={() => DiscountVoucherFocusOff()}
                      type="text"
                      ref={DiscountVoucherRef}
                      autoComplete="off"
                      className="w-[250px] h-[40px] pl-4 BNP-Input-thin  text-lg text-BNPblue cursor-text"
                    />
                    <h4
                      className={
                        DiscountVoucherMove
                          ? "w-[115px] BNP-LableMove -translate-y-[50px]"
                          : "w-[160px] BNP-LableStart pt-[3px] bg-opacity-0"
                      }
                    >
                      <div className="flex">Discount voucher</div>
                    </h4>

                    {/* Discount Validation icons */}
                    <Transition
                      show={discountValid}
                      enter="transition ease-in-out duration-500 transform"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition ease-in-out duration-500 transform"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="absolute bottom-0 right-0 mr-2 mb-[10px]"
                    >
                      <faIcons.FaRegCheckCircle className="text-green-600 text-xl" />
                    </Transition>

                    <Transition
                      show={discountInValid}
                      enter="transition ease-in-out duration-500 transform"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition ease-in-out duration-500 transform"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="absolute bottom-0 right-0 mr-2 mb-[10px]"
                    >
                      <faIcons.FaRegTimesCircle className="text-red-500 text-xl" />
                    </Transition>

                    {/* Clear text */}
                    <Transition
                      show={ClearText}
                      enter="transition ease-in-out duration-700 transform"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition ease-in-out duration-700 transform"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="absolute bottom-0 right-0 mr-2 -mb-[25px]"
                    >
                      <div className="flex justify-end w-[250px]">
                        <div
                          onClick={clearDiscText}
                          className="w-auto BNP-Links p-0 m-0"
                        >
                          clear
                        </div>
                      </div>
                    </Transition>
                  </div>
                </div>

                <button
                  onClick={() => handleDiscount()}
                  className="ml-0 s740:ml-3 p-0 BNP-SubmitButtonsBlue text-center h-[40px] w-32 ease-in-out"
                >
                  Validate
                </button>
              </div>
            </div>

            {/* Payment Method */}
            <div className="p-4 bg-white s1260:w-auto ">
              <div className="flex justify-between items-center text-BNPblue">
                Payment Methods
              </div>
              <div className="flex flex-wrap s1260:flex pb-2 gap-x-4 md:gap-y-4 p-3 h-[440px] s700:h-[290px] s1260:h-auto BNP-Trans500">
                {/* PayFast */}
                <div
                  onClick={() => setPayMethod("payfast")}
                  className="flex-row justify-center h-[130px] hover:shadow-md p-2 hover:shadow-BNPblue rounded-lg hover:scale-105 hover:cursor-pointer BNP-Trans300"
                >
                  <div className="text-nowrap justify-center font-semibold text-center">
                    Credit & Debit Card
                  </div>
                  <div className="flex-row pb-1">
                    <div className="flex justify-center items-center h-[65px]">
                      <div className="flex h-10 mt-4 my-3 justify-center">
                        <img
                          src="../images/visaMasterCard.png"
                          alt=""
                          id="visaMasterCard"
                        />
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <input
                        type="radio"
                        name="payfastbtn"
                        checked={payMethod === "payfast"}
                        onChange={() => setPayMethod("payfast")}
                        className="text-center hover:cursor-pointer w-6 h-6"
                      />
                    </div>
                  </div>
                </div>
                {/* Dealership */}
                <div
                  onClick={() => {
                    if (dealerPayMethodDisable === false) {
                      setPayMethod("dealer");
                      if (userID === "") {
                        setloginModal(true);
                      } else {
                        // Validate JWT accessToken
                        document.getElementById("validateClick")?.click();
                        effectRunDealerLogin.current = false;
                      }
                    }
                  }}
                  className={
                    dealerPayMethodDisable
                      ? "flex-row justify-center h-[130px] opacity-50"
                      : "flex-row justify-center h-[130px] hover:shadow-md p-2 hover:shadow-BNPblue rounded-lg hover:scale-105 hover:cursor-pointer BNP-Trans300"
                  }
                >
                  <div className="flex justify-center font-semibold ">
                    Dealerships
                  </div>

                  <div
                    className={
                      dealerPayMethodDisable ? "flex-row pb-1" : "flex-row pb-1"
                    }
                  >
                    <div className="flex justify-center items-center h-[65px]">
                      <div className="flex justify-center h-[80px]">
                        <img
                          src="../images/Dealerships.png"
                          alt=""
                          id="dealers"
                        />
                      </div>
                    </div>
                    <div className="flex justify-center hover:cursor-pointer">
                      <input
                        type="radio"
                        name="dealerbtn"
                        disabled={dealerPayMethodDisable}
                        checked={payMethod === "dealer"}
                        onChange={() => {
                          setPayMethod("dealer");
                          if (userID === "") {
                            setloginModal(true);
                            setDealerDiscountRefresh(false);
                          } else {
                            // Validate JWT accessToken
                            document.getElementById("validateClick")?.click();
                            setDealerDiscountRefresh(true);
                            // console.log(loadNewToken);
                            // console.log("Check for Dealer login");
                          }
                        }}
                        className="text-center hover:cursor-pointer w-6 h-6"
                      />
                    </div>
                  </div>
                </div>

                {/* PayShap */}
                <div
                  onClick={() => setPayMethod("payshap")}
                  className="flex-row justify-center h-[130px] hover:shadow-md p-2 hover:shadow-BNPblue rounded-lg hover:scale-105 hover:cursor-pointer BNP-Trans300"
                >
                  <div className="flex justify-center font-semibold">
                    PayShap
                  </div>
                  <div className="flex-row pb-1">
                    <div className="flex justify-center items-center h-[65px]">
                      <div className="flex h-9">
                        <img src="../images/payShap.png" alt="" id="payShap" />
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <input
                        type="radio"
                        name="payShapbtn"
                        checked={payMethod === "payshap"}
                        onChange={() => setPayMethod("payshap")}
                        className="text-center hover:cursor-pointer w-6 h-6"
                      />
                    </div>
                  </div>
                </div>

                {/* Yoco Tap */}
                <div
                  className={
                    deliveryCheck
                      ? "transition-height duration-700 ease-in-out h-[130px] opacity-0 flex-row justify-center"
                      : "transition-height duration-300 ease-in-out h-[130px] flex-row justify-center hover:shadow-md p-2 hover:shadow-BNPblue rounded-lg hover:scale-105 BNP-Trans300"
                  }
                >
                  <div className="flex justify-center font-semibold">Yoco</div>
                  <div
                    className={
                      deliveryCheck
                        ? "flex-row pb-1"
                        : "flex-row pb-1 hover:cursor-pointer"
                    }
                    onClick={() => {
                      if (deliveryCheck === false) {
                        setPayMethod("yoco");
                      }
                    }}
                  >
                    <div className="flex justify-center items-center h-[65px]">
                      <div className="flex justify-center mt-2 h-[75px]">
                        <img
                          src="../images/YocoTap2.webp"
                          alt=""
                          id="yocoTap"
                        />
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <input
                        type="radio"
                        name="yocobtn"
                        disabled={deliveryCheck}
                        checked={payMethod === "yoco"}
                        onChange={() => setPayMethod("yoco")}
                        className={
                          deliveryCheck
                            ? "text-center w-6 h-6"
                            : "text-center hover:cursor-pointer w-6 h-6"
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* Cash */}

                <div className={cashPay ? "" : "hidden"}>
                  <div
                    onClick={() => {
                      if (deliveryCheck === false) {
                        setPayMethod("cash");
                      }
                    }}
                    className={
                      deliveryCheck
                        ? "transition-height duration-700 ease-in-out h-[130px] opacity-0 flex-row justify-center"
                        : "transition-height duration-300 ease-in-out h-[130px] flex-row justify-center hover:shadow-md p-2 hover:shadow-BNPblue rounded-lg hover:scale-105 hover:cursor-pointer BNP-Trans300"
                    }
                  >
                    <div className="flex justify-center font-semibold">
                      Cash
                    </div>
                    <div className="flex-row pb-1">
                      <div className="flex justify-center items-center h-[65px]">
                        <div className="flex justify-center h-[55px] -mt-2">
                          <img src="../images/cash.png" alt="" id="cashRands" />
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <input
                          type="radio"
                          name="cashbtn"
                          disabled={deliveryCheck}
                          checked={payMethod === "cash"}
                          onChange={() => setPayMethod("cash")}
                          className={
                            deliveryCheck
                              ? "text-center w-6 h-6"
                              : "text-center hover:cursor-pointer w-6 h-6"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* column 2 */}
          <div className="flex-row xl:w-1/3 w-2/3 max-w-[500px] ml-12 xl:ml-0 xl:mt-0 mt-3 mr-7 space-y-3">
            {/* Order Summary */}
            <div className="p-4 bg-white">
              <p className="flex-row mt-1 font-semibold">Order Summary</p>
              {/* Items & Cost */}
              <div className="flex justify-between items-center text-sm mt-3">
                <div className="flex">
                  <p>{cartCountWording}</p>
                  <p className="pl-4 "> {cartVol} </p>
                </div>
                `<p>R {formatCurrency(cartCost)}</p>
              </div>

              {/* Discount */}
              <div
                className={
                  discountValid
                    ? "transition-height duration-700 ease-in-out h-[24px] flex justify-between items-center text-sm mt-3 text-red-600"
                    : "transition-height duration-700 ease-in-out h-0 opacity-0 "
                }
              >
                <p>Discount {cartDiscount}%</p>
                <p>R {formatCurrency(discountAmt)}</p>
              </div>

              <dir className="flex justify-end mt-2 mb-2 ">
                <dir className="w-28 border-t-2 border-dashed border-BNPblue border-opacity-40"></dir>
              </dir>

              {/* Delivery & Cost */}
              <div className="flex justify-between items-center text-sm">
                <p>Delivery Cost</p>
                <p>{delCost}</p>
              </div>

              <div className="flex justify-between items-center border-t-2 border-dashed border-BNPblue border-opacity-40 mt-4 pt-4 pl-7">
                <p className="font-semibold">TO PAY:</p>
                <p className="font-semibold text-lg text-green-700">
                  R {formatCurrency(totalAmt)}
                </p>
              </div>

              <div className="relative flex justify-center w-100 h-[100px]">
                {/* Ping Payfast */}

                <Transition
                  show={paymentPayfast}
                  enter="transition ease-in-out duration-700 transform"
                  enterFrom="opacity-25"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-700 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-25"
                  className="absolute w-100"
                >
                  <button
                    id="SalesSendDataPF"
                    onMouseEnter={() => {
                      // Validate JWT accessToken
                      document.getElementById("validateClick")?.click();
                    }}
                    onClick={() => sendDataDB()}
                    className={
                      btnWait
                        ? "BNP-SubmitButtonsBlueWait w-100 mt-4"
                        : "BNP-SubmitButtonsBlue w-100 mt-4"
                    }
                  >
                    PAY NOW!
                  </button>

                  <div className="flex items-center justify-center pt-3">
                    <faIcons.FaUserLock />
                    <p className="pl-4 text-center">Secure Checkout with</p>
                    <img
                      src="../images/PayFast.png"
                      alt=""
                      id="visaMasterCard"
                      className="ml-2 h-6"
                    />
                  </div>
                </Transition>

                {/* Dealer payment */}
                <Transition
                  show={paymentDealer}
                  enter="transition ease-in-out duration-700 transform"
                  enterFrom="opacity-25"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-700 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-25"
                  className="absolute w-100 "
                >
                  <button
                    onClick={() => handleDealerClick()}
                    onMouseEnter={() => {
                      // Validate JWT accessToken
                      document.getElementById("validateClick")?.click();
                    }}
                    className="BNP-SubmitButtonsBlue w-100 mt-4"
                  >
                    Place Order NOW!
                  </button>

                  <div className="flex items-center justify-center pt-3">
                    <faIcons.FaUserLock />
                    <p className="pl-4 text-center">Secure Checkout</p>
                  </div>
                </Transition>

                {/* PayShap payment */}
                <Transition
                  show={paymentPayShap}
                  enter="transition ease-in-out duration-700 transform"
                  enterFrom="opacity-25"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-700 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-25"
                  className="absolute w-100 "
                >
                  <button
                    id="SalesSendDataPS"
                    onMouseEnter={() => {
                      // Validate JWT accessToken
                      document.getElementById("validateClick")?.click();
                    }}
                    onClick={() => sendDataDB()}
                    className={
                      btnWait
                        ? "BNP-SubmitButtonsBlueWait w-100 mt-4"
                        : "BNP-SubmitButtonsBlue w-100 mt-4"
                    }
                  >
                    Place Order NOW!
                  </button>

                  <div className="flex items-center justify-center pt-3">
                    <faIcons.FaUserLock />
                    <p className="pl-4 text-center">Secure Checkout with</p>
                    <img
                      src="../images/payShap.png"
                      alt=""
                      id="visaMasterCard"
                      className="ml-1 h-6 -mt-[9px]"
                    />
                  </div>
                </Transition>

                {/* Yoco or Cash payment */}
                <Transition
                  show={paymentYocoCash}
                  enter="transition ease-in-out duration-700 transform"
                  enterFrom="opacity-25"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-700 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-25"
                  className="absolute w-100 "
                >
                  <button
                    id="SalesSendDataYocoCash"
                    onMouseEnter={() => {
                      // Validate JWT accessToken
                      document.getElementById("validateClick")?.click();
                    }}
                    onClick={() => sendDataDB()}
                    className={
                      btnWait
                        ? "BNP-SubmitButtonsBlueWait w-100 mt-4"
                        : "BNP-SubmitButtonsBlue w-100 mt-4"
                    }
                  >
                    PAY NOW!
                  </button>

                  <div className="flex items-center justify-center pt-3">
                    <faIcons.FaUserLock />
                    <p className="pl-4 text-center">Secure Checkout!</p>
                  </div>
                </Transition>
              </div>
            </div>

            {/* Items for Delivery */}
            <div className="p-4 bg-white">
              <div className="flex justify-between items-center">
                <p className="flex font-semibold text-sm pb-2">
                  Items for delivery
                </p>
                <div
                  onClick={() => setCartClick(true)}
                  className="BNP-Links text-sm"
                >
                  Show Details
                </div>
              </div>
              <CartItems />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
