import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Transition } from "@headlessui/react";
import md5 from "md5";
import axios from "axios";

// Context
// import { DarkContext } from "../context/DarkContext";
// import DarkMode from "./DarkMode";

import UserContext from "../context/UserContext";
import ModalContext from "../context/ModalContext";
import URLContext from "../context/URLContext";
import SalesCartContext from "../context/SalesCartContext";
import AdminContext from "../context/AdminContext";

// Img
import * as bsIcons from "react-icons/bs";
import * as biIcons from "react-icons/bi";
import * as cgIcons from "react-icons/cg";

// Data
import { logoutDropDownData } from "../data/Lookups/logoutDropDown";

// Functions
import getNewToken from "../utilities/userNewToken";
import testAccessToken from "../utilities/validateAccessToken";
import dateTime from "../utilities/convertDateTime";

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
export default function Header() {
  // console.log("Render Header");
  // Navigate
  const nav = useNavigate();
  let location = useLocation();

  // Context
  const {
    userID,
    userLogin,
    userLoginCookie,
    setUserLoginCookie,
    userAdmin,
    userLogOut,
    PrefName,
    setuserEmail,
    setUserLogin,
    setUserPassword,
    setIDno,
    setUserAdmin,
    setBusinessUser,
    setPrefName,
    updateAddressContext,
    setAcctInfoUpdate,

    setAccessToken,
    setRefreshToken,
    refreshToken,
    accessToken,
    tokenTime,
    setTokenTime,
    userEmail,
  } = useContext(UserContext);

  const {
    pwResteLock,
    setPwRestLock,
    setCookieModal,
    cookieModalDelay,
    loginModal,
    setloginModal,
    // sidebarSize,
    setTrackingVisible,
    //  dark, setDark
    logoutDropDown,
    setLogoutDropDown,
    setCurrentPage,
    msgClean,
    setMsgOpen,
    setMsgTxt,
    setMsgType,
    testing,
    closeModals,
  } = useContext(ModalContext);

  const { cartVol, cartClick, setCartClick } = useContext(SalesCartContext);

  const {
    setAdminPgRefresh,
    salesVol,
    setCallBackRefresh,
    callBackVol,
    setSalesVol,
    setCallBackVol,
  } = useContext(AdminContext);

  const {
    userCookieLogin,
    userLoginTracking,
    userAddress,
    userTokenClear,

    userToken,
    userTestAccessToken,

    userAccInfo,
  } = useContext(URLContext);

  // State

  const [cartHover, setCartHover] = useState(false);
  const [cartPing, setCartPing] = useState(false);

  const [adminSalesHover, setAdminSalesHover] = useState(false);
  const [adminSalesCase, setAdminSalesCase] = useState("pr-2");
  const [adminSalesCase2, setAdminSalesCase2] = useState("pr-2");

  const [callBackPing, setCallBackPing] = useState("");

  const effectRunOnce = useRef(false);

  // Enable Dark mode
  // const clickDark = () => {
  //   setDark(!dark);
  // };

  // Screen Size effect
  useEffect(() => {
    if (adminSalesHover === true) {
      // Sales Vol Ping
      if (salesVol < 1) {
        setAdminSalesCase("px-2 w-7 h-6 text-red-600 transition duration-300");
      } else if (salesVol < 10) {
        setAdminSalesCase(
          "px-2 w-7 h-6 text-red-600 transition duration-300 border-4 border-red-600 rounded-full animate-ping"
        );
      } else {
        setAdminSalesCase(
          "px-2 w-10 h-6 text-red-600 transition duration-300 border-4 border-red-600 rounded-full animate-ping"
        );
      }
      setAdminSalesCase2(
        "absolute top-0 left-2 text-red-600 transition duration-300"
      );
    } else {
      if (salesVol < 1) {
        setAdminSalesCase(
          "px-2 w-7 h-6 text-BNPgoldLight transition duration-300"
        );
      } else if (salesVol < 10) {
        setAdminSalesCase(
          "px-2 w-7 h-6 text-BNPgoldLight transition duration-300 border-4 border-BNPgoldLight rounded-full animate-ping"
        );
      } else {
        setAdminSalesCase(
          "px-2 w-10 h-6 text-BNPgoldLight transition duration-300 border-4 border-BNPgoldLight rounded-full animate-ping"
        );
      }
      setAdminSalesCase2(
        "absolute top-0 left-2 text-BNPgoldLight transition duration-300"
      );

      // Call back ping
      let cb = callBackVol;
      if (cb > 0) {
        setCallBackPing(
          "absolute -top-[7px] left-[1px] w-[30px] h-[30px] transition duration-300 border-4 border-green-600 rounded-full animate-ping"
        );
      } else {
        setCallBackPing(
          "absolute -top-[7px] left-[1px] w-[30px] h-[30px] transition duration-300"
        );
      }
    }

    if (PrefName === "" || PrefName === null) {
      setPrefName(userEmail);
    }
  }, [
    salesVol,
    adminSalesHover,
    callBackVol,
    PrefName,
    userEmail,
    setPrefName,
  ]);

  const closeOutSide = (e) => {
    if (e.target.id === "logoutDD") setLogoutDropDown(false);
    // console.log(e.target);
  };

  // click logout drop down items
  const logoutDDClick = (i) => {
    if (i === "logout") {
      // Update Tokens on DB
      logoutTokenClear(userID);

      setCurrentPage(location.pathname);
      nav("/LoggedOut");

      userLogOut();
      closeModals();
      setTrackingVisible(false);
      setLogoutDropDown(false);
    } else {
      // got to page
      setLogoutDropDown(false);
      nav(i);
    }
  };

  const LoginClick = () => {
    setloginModal(!loginModal);
  };

  const cartClickUpdate = () => {
    // Check if password Rest acitve
    if (pwResteLock === false) {
      if (cartVol > 0) {
        setCartClick(!cartClick);
      }
    }
  };

  const cartHoverEnterhandle = () => {
    setCartHover(true);
  };
  const cartHoverLeavehandle = () => {
    setCartHover(false);
  };

  useEffect(() => {
    // Cart ping
    if (cartVol !== 0) {
      setCartPing(true);
    } else {
      setCartPing(false);
    }
  }, [cartVol]);

  // cookie login with tracking
  useEffect(() => {
    let loadNewToken = ""; // JWS Token

    // console.log(userLoginTracking)
    if (userLoginCookie) {
      if (userID !== "" && userLoginTracking !== "") {
        if (effectRunOnce.current === false) {
          let error = 0;
          //  Hash build
          let pw = md5(userID + process.env.REACT_APP_UCNPASSPHRASE);

          // variables

          async function cookieLogin() {
            let url = userCookieLogin + userID;
            const data = await axios
              .get(url, { params: { z: pw } })
              .then((res) => res.data)
              .catch((err) => {
                console.log(err);
                error = 1;
              });
            try {
              setRefreshToken(data.sqlData[0][0].refreshToken);
              setTokenTime(new Date().getTime() + 300000);
              setAccessToken(data.accessToken);
              loadNewToken = data.accessToken;
              // console.log(loadNewToken);

              if (error === 0) {
                if (data.length === 0) {
                  setUserLogin(false);
                } else {
                  // console.log("cookie login");
                  let admin = data.sqlData[0][0].admin;
                  setuserEmail(data.sqlData[0][0].Email_Address);
                  setUserPassword(data.sqlData[0][0].Password);
                  setIDno(data.sqlData[0][0].IDno);
                  setUserLogin(true);
                  setPrefName(data.sqlData[0][0].Prefered_Name); // Change name here
                  setBusinessUser(data.sqlData[0][0].busClient);
                  try {
                    // console.log("User login via Cookie.");
                    if (window.location.pathname !== "/PaySuccess") {
                      // console.log("track")
                      loginTrackData(userID);
                    } //else {console.log("don't track")}

                    // Load Address
                    loadAddressinfo(data.sqlData[0][0].ucn);
                  } catch (error) {
                    // console.log("User not logged in.");
                  }

                  if (admin === 1) {
                    setUserAdmin(true);
                    setSalesVol(data.sqlData[0][0].adminVol);
                    setCallBackVol(data.sqlData[0][0].CallBackVol);
                  } else {
                    setUserAdmin(false);
                  }
                }
              }
            } catch (error) {}
          }

          cookieLogin();

          //  insert Login Tracking
          async function loginTrackData(ucn) {
            let url = userLoginTracking;
            const data = await axios
              .post(
                url,
                {
                  track_ucn: ucn,
                },
                { headers: { Authorization: loadNewToken } } // JWS Token
              )
              .then((res) => res)
              .catch((err) => {});
            // console.log(data);

            if (data.status === 200) {
              try {
                if (data.data.affectedRows > 0) {
                  // console.log("User Tracking info updated.");
                }
              } catch (error) {
                // console.log("User Tracking info NOT updated.");
              }
            }
            setUserLoginCookie(false);
          }

          // Load address info
          const loadAddressinfo = async (i) => {
            if (i !== "") {
              // request data from mySQL db
              let url = userAddress + i;
              const data = await axios
                .get(
                  url,
                  { headers: { Authorization: loadNewToken } } // JWS Token
                )
                .then((res) => res.data)
                .catch((err) => {
                  msgClean();
                  setMsgTxt(
                    "Network error!  Please check your Internet connection and try again."
                  );
                  setMsgType("Error");
                  setMsgOpen(true);
                });
              // console.log(data);

              updateAddressContext(data[0]);
            }
          };
          effectRunOnce.current = true;
        }
      }
    }
  }, [
    userID,
    userLoginCookie,
    userLoginTracking,
    userCookieLogin,
    setUserLoginCookie,
    setBusinessUser,
    setCallBackVol,
    setIDno,
    setPrefName,
    setSalesVol,
    setUserAdmin,
    setUserLogin,
    setRefreshToken,
    setUserPassword,
    setuserEmail,
    msgClean,
    setMsgOpen,
    setMsgTxt,
    setMsgType,
    updateAddressContext,
    userAddress,
    setTokenTime,
    setAccessToken,
  ]);

  // Logout / Clear Token
  const logoutTokenClear = async (i) => {
    // Validate JWT accessToken
    validateToken();
    // console.log(loadNewToken);
    console.log("User logout.");

    // request data from mySQL db
    let url = userTokenClear + i;
    const data = await axios
      .get(
        url,
        { headers: { Authorization: accessToken } } // JWS Token
      )
      .then((res) => res.data)
      .catch((err) => {
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
      });

    if (1 === 2) {
      console.log(data);
    }
  };

  // Validate accessToken/ get new accessToken if expired
  const validateToken = async () => {
    const currentTime = new Date().getTime();

    if (testing) {
      // check token times
      // console.log(refreshToken);
      console.log(dateTime(currentTime));
      console.log(dateTime(tokenTime));
    }

    if (currentTime > tokenTime) {
      // accessToken has expired
      if (refreshToken) {
        console.log("accessToken has expired");
        console.log("New accessToken requested.");
      }
      // console.log("RefreshToken: " + refreshToken);

      async function newToken() {
        let a = await getNewToken(userToken, refreshToken, userEmail);
        // console.log(a);
        try {
          if (
            a.data?.response.status === 409 ||
            a.data?.response.status === 403
          ) {
            if (testing === 1) console.log(refreshToken);

            userLogOut();
            closeModals();
            nav("/Session-Expied");
          }
        } catch (error) {}
        try {
          if (a.data.accessToken?.length > 0) {
            setAccessToken(a.data.accessToken);
            setTokenTime(a.timestamp);
          }
        } catch (error) {}
      }
      newToken();
    } else {
      // Token is still valid
      if (accessToken) {
        let url = userTestAccessToken;
        const authToken = await testAccessToken(url, accessToken);
        if (authToken) {
          // console.log(accessToken)
          console.log(authToken);
        }
      }
    }
  };

  const handleAcctInfo = async () => {
    // console.log("AcctInfo pull data");
    // Validate JWT accessToken
    document.getElementById("validateClick")?.click();

    // request data from mySQL db
    let url = userAccInfo + userID;
    const data = await axios
      .get(
        url,
        { headers: { Authorization: accessToken } } // JWS Token
      )
      .then((res) => res.data)
      .catch((err) => {
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });
    // console.log(data[0][0])

    if (data[0]?.length > 0) {
      setAcctInfoUpdate(data[0][0]);
    }
  };

  // console.log("PrefName: " + PrefName);
  // console.log("email: " + userEmail)

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {/* Header Section */}
      <div className="contaner w-100 top-0 left-0">
        {/* drop down */}
        <div
          onClick={closeOutSide}
          id="logoutDD"
          className="absolute flex top-0 right-8 text-xs pt-[110px] z-10"
        >
          <Transition
            show={logoutDropDown}
            enter="transition ease-in-out duration-700 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="font-Montserrat flex justify-center w-[240px] py-3 bg-white rounded-md border-2 border-BNPblue border-opacity-40">
              <div className="flex-row items-center space-y-2 bg-opacity-100">
                {/* loop per item */}
                {logoutDropDownData.map((items, index) => (
                  <div key={index}>
                    <div
                      onClick={() => {
                        logoutDDClick(items.link);
                        if (items.link === "logout") {
                          setPwRestLock(false);
                        }
                        if (items.link === "/AccountInfo") {
                          handleAcctInfo();
                        }
                      }}
                      className={items.case}
                    >
                      <div className={items.iconcase}>{items.icon}</div>
                      {items.Description}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Transition>
        </div>
        {/* Sidebare shrink */}
        <div className="flex  text-BNPblue">
          <div className="flex items-center">
            <div className="mt-6 bg-red-200">
              {/*  Dark Mode */}
              {/* <div onClick={clickDark}>
                      <div className={sidebarSize ? "mt-20" : "hidden"}>
                        <div className="mt-4">        
                            <DarkMode />
                        </div>
                      </div>
                    </div> */}
            </div>
          </div>

          {/* Flex Container For Nav Items */}
          <div className={testing ? "flex-row " : "hidden"}>
            <button id="validateClick" onClick={() => validateToken()}>
              Validate
            </button>
          </div>

          <div className="flex-auto bg-BNPgoldLightLight BNP-Trans500">
            <div className="flex-row s1060:flex items-center justify-between pt-3 px-5 s1060:pb-0 pb-3 bg-BNPgoldLightLight BNP-Trans500">
              {/* logo */}
              <div className="flex justify-center BNP-Trans500 s1600:ml-0 -ml-14 ">
                <div className="ml-5 pl-[2%] h-32 w-32 md:h-28 md:w-28  BNP-Trans500">
                  <img src="../images/BNPf.png" alt="BNP Logo" id="logo" />
                </div>
              </div>

              <div className="">
                {/* Menu Items */}
                <div className="flex justify-center md:mr-[10%] scale-75 md:scale-100 BNP-Trans500">
                  <div className="flex items-center space-x-10 uppercase">
                    {/*  Dark Mode */}
                    {/* <div className={sidebarSize ? "hidden" : "scale-[1.2]"}>
                      <div onClick={clickDark}>
                          <DarkMode />
                      </div>
                    </div> */}

                    {/* Call Backs */}
                    <Link to={"/CallBacks"}>
                      <div
                        onClick={() => setCallBackRefresh(1)}
                        className={
                          userAdmin
                            ? "flex items-center justify-between"
                            : "hidden"
                        }
                      >
                        <div className="flex items-center rounded-full p-3 BNP-SubmitButtons">
                          <div className="relative px-2 scale-[1.2] text-green-600">
                            <div className={callBackPing}></div>

                            <bsIcons.BsFillTelephoneFill />
                          </div>
                          <div className="px-2">Call</div>
                          <div className="">
                            <div>{callBackVol}</div>
                          </div>
                        </div>
                      </div>
                    </Link>
                    {/* Sales */}
                    <Link to={"/salesAdmin"}>
                      <div
                        onClick={() => setAdminPgRefresh(1)}
                        className={
                          userAdmin
                            ? "flex items-center justify-between shadow-3xl"
                            : "hidden"
                        }
                      >
                        <div
                          onMouseEnter={() => setAdminSalesHover(false)}
                          onMouseLeave={() => setAdminSalesHover(true)}
                          className="flex items-center rounded-full p-3 BNP-SubmitButtons"
                        >
                          <div className="px-2 scale-[1.7]">
                            <cgIcons.CgBrowse />
                          </div>
                          <div className="px-2">Sales</div>
                          <div className="relative">
                            <div className={adminSalesCase}></div>
                            <div className={adminSalesCase2}>{salesVol}</div>
                          </div>
                        </div>
                      </div>
                    </Link>
                    {/* Cart */}

                    <div
                      onClick={cartClickUpdate}
                      onMouseOver={cartHoverEnterhandle}
                      onMouseLeave={cartHoverLeavehandle}
                      className="flex justify-between w-28 h-[51px] py-3 rounded-full p-2 bg-BNPgoldLight border-2 border-BNPblue hover:bg-BNPblue hover:text-BNPgoldLight
                              hover:shadow-md hover:shadow-BNPgoldDark items-center hover:border-BNPgoldLight cursor-pointer transition-colors duration-300"
                    >
                      <div className="pl-3 pr-2 scale-[1.5]">
                        <bsIcons.BsCart3 />
                      </div>
                      <div className={cartPing ? "absolute ml-11" : "hidden"}>
                        <div
                          className={
                            cartHover
                              ? "px-2 border-BNPgoldLight border-2 rounded-full animate-pulse transition-all duration-1000 "
                              : "px-2 border-BNPblue border-2 rounded-full animate-pulse transition-all duration-1000 "
                          }
                        >
                          {cartVol}
                        </div>
                      </div>

                      <div className={cartPing ? "hidden" : "absolute ml-12 px-[10px]"}>
                        {cartVol}
                      </div>
                    </div>
                    {/* Login */}
                    <div
                      onClick={() => {
                        if (cookieModalDelay === true) {
                          setCookieModal(true);
                        } else {
                          if (userLogin === true) {
                            setLogoutDropDown(!logoutDropDown);
                          } else {
                            LoginClick();
                          }
                        }
                      }}
                      className="flex items-center justify-between rounded-full p-3 BNP-SubmitButtons"
                    >
                      {userLogin === false ? (
                        <div className="flex items-center">
                          <div className="px-3 scale-[1.7]">
                            <biIcons.BiUserCircle />
                          </div>
                          <div className="pl-2 pr-2">{PrefName}</div>
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <div className="px-3 scale-[1.7]">
                            <biIcons.BiUserCircle />
                          </div>
                          <div className="pl-2 pr-2 flex items-center">
                            {PrefName}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="flex w-[95%] h-1 border-b-2 border-BNPblue border-opacity-40"></div>
            </div>
          </div>
        </div>
        <button
          id="acctInfoUpdateClick"
          className="hidden"
          onClick={() => handleAcctInfo()}
        >
          accInfoUpdate
        </button>
      </div>
    </>
  );
}
