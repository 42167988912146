import React, { useContext, useEffect, useState } from "react";

// Pages
import SalesInvoicePrintItems from "./SalesInvoicePrintItems";

// lookup data
import BNPInfoData from "../data/Lookups/BNPinfo.json";

// Utilities
import formatPhoneNumber from "../utilities/formatPhoneNumber";
import formatCurrency from "../utilities/formatCurrency";

// Context
import AdminContext from "../context/AdminContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function SalesInvoiceModal() {
  const { trackingSalesOrderInfo, trackingSalesProductData } = useContext(AdminContext);

  // State
  // const componentRef = useRef();
  const [clientName, setClientName] = useState("");
  const [paidImg, setPaidImg] = useState(false);

  const website = BNPInfoData[0].website;

  useEffect(() => {
    if (trackingSalesOrderInfo[0].bussinesName?.length > 0) {
      setClientName(trackingSalesOrderInfo[0].bussinesName);
    } else {
      setClientName(trackingSalesOrderInfo[0].DeliveryName);
    }

    if (trackingSalesOrderInfo[0].paid === "Yes") {
      setPaidImg(true);
    }
  }, [trackingSalesOrderInfo]);

  // const handleEmail = useReactToPrint({

  // });

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {/* Invoice */}
      <div
        pagestyle="@page { size: 2.5in 4in }"
        className="flex flex-col h-screen font-Montserrat scale-95"
      >
        <div className="flex-grow p-5">
          <div className="flex-row">
            {/* Header */}
            <div className="flex items-center justify-between mb-3 w-full">
              <div>
                <h1 className="font-bold uppercase tracking-wide text-4xl">
                  Invoice
                </h1>
              </div>
              <div className="mr-[80px]">
                <div className="flex justify-center items-center w-32">
                  <img src="../images/BNPs.png" alt="BNP logo" id="logo" />
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              {/* ClientDetails */}
              <section className="">
                <h2 className="text-2xl uppercase font-bold mb-1">
                  {clientName}
                </h2>
                <p>{trackingSalesOrderInfo[0].ComplexName}</p>
                <p>{trackingSalesOrderInfo[0].Address}</p>
                <p>{trackingSalesOrderInfo[0].Suburb}</p>
                <p>{trackingSalesOrderInfo[0].City}</p>
                <p>{trackingSalesOrderInfo[0].Province}</p>
                <p>{trackingSalesOrderInfo[0].PostalCode}</p>
              </section>

              {/* My Details  */}
              <section className="flex flex-col items-end justify-end">
                <h2 className="font-bold text-2xl uppercase mb-1">
                  {BNPInfoData[0].name}
                </h2>
                <p>{BNPInfoData[0].street_address}</p>
                <p>{BNPInfoData[0].suburb}</p>
                <p>{BNPInfoData[0].City}</p>
                <p>{BNPInfoData[0].province}</p>
                <p>{BNPInfoData[0].post_code}</p>
              </section>
            </div>
            {/* Invoice Details */}
            <article className="mt-5 mb-5 flex items-end justify-end">
              <ul>
              <div className="flex justify-between p-1">
                  <span className="font-bold">Invoice number:</span>
                  <p className="w-[100px] text-right">
                    {trackingSalesOrderInfo[0].Inv_No}
                  </p>
                </div>
                <div className="flex p-1">
                  <span className="font-bold">Payment method:</span>
                  <p className="w-[100px] text-right">
                    {trackingSalesOrderInfo[0].paymentType}
                  </p>
                </div>
                <div className="flex justify-between p-1">
                  <span className="font-bold">Sale date:</span>
                  <p className="w-[100px] text-right">
                    {trackingSalesOrderInfo[0].SalesDate}
                  </p>
                </div>
                <div className="relative flex p-1 ">
                  <span className="font-bold">Paid:</span>

                  <div className={paidImg ? "" : "hidden"}>
                    <img
                      src="../images/paid.png"
                      alt=""
                      className="absolute h-20 right-8 rotate-45 -mt-7 opacity-90 z-20"
                    />
                  </div>
                  <div
                    className={
                      paidImg
                        ? "hidden"
                        : "flex justify-end w-100 font-semibold"
                    }
                  >
                    No
                  </div>
                </div>
              </ul>
            </article>
            {/* Table */}
            <div className="relative pl-2">
              <div className="absolute top-[26px] -left-[2px] w-100 border-b-2 border-BNPblue border-opacity-80"></div>
              <table width="100%">
                <thead>
                  <tr>
                    <td className="font-bold text-left">Description</td>
                    <td className="font-bold text-center">Quantity</td>
                    <td className="font-bold text-right pr-4">Amount</td>
                  </tr>
                </thead>

                {trackingSalesProductData.map((pInfo) => (
                  <React.Fragment key={pInfo.ID}>
                    {/* Table items */}
                    <SalesInvoicePrintItems
                      prodType={pInfo.prodType}
                      size={pInfo.size}
                      province={pInfo.province}
                      reg={pInfo.reg}
                      quant={pInfo.quant}
                      price={pInfo.price}
                    />
                  </React.Fragment>
                ))}
              </table>
            </div>
            {/* Amounts */}
            {/* Discount */}
            <div className="flex items-center justify-end text-gray-900 h-10 m-0 p-0">
              <div className="flex justify-between w-1/2">
                <h2 className="">Discount:</h2>
                <p>{trackingSalesOrderInfo[0]?.Disc_Perc}%</p>
                <h2 className="text-right pr-4">
                  {formatCurrency(trackingSalesOrderInfo[0]?.Disc_Amt)}
                </h2>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="flex mt-1 border-t-2 border-BNPblue border-opacity-80 w-1/2"></div>
            </div>
            {/* Net Amt */}
            <div className="flex items-center justify-end text-gray-900 text-xl font-semibold mt-1">
              <h2 className="text-right pr-4">
                {formatCurrency(trackingSalesOrderInfo[0]?.SUM_Net_Amt)}
              </h2>
            </div>
            {/* Delivery Amt */}
            <div className="flex items-center justify-end text-gray-900 h-10">
              <div className="flex justify-between w-1/2">
                <h2 className="mr-8">Delivery:</h2>
                <h2 className="text-right pr-4">
                  {formatCurrency(trackingSalesOrderInfo[0]?.Delivery_Amt)}
                </h2>
              </div>
            </div>
            {/* Total Amt */}
            <div className="flex items-end justify-end text-gray-900 text-2xl font-bold mt-1">
              <div className="flex justify-between w-1/2">
                <h2 className="mr-8">Total:</h2>
                <div className="flex">
                  <h2 className="mr-8">R</h2>
                  <h2 className="text-right pr-4">
                    {formatCurrency(trackingSalesOrderInfo[0]?.Total_Amt)}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Notes if needed */} 

        {/* Footer */}
        <footer className="flex justify-center w-100 mt-auto">
          <div className="border-t-2 border-BNPblue border-opacity-80 w-full pt-5">
            <ul className="flex justify-between">
              <div className="flex-row">
                <li className="flex justify-between">
                  <span className="font-bold">Bank:</span>
                  {BNPInfoData[0].bank_name}
                </li>
                <li className="flex justify-between">
                  <span className="font-bold mr-2">Account name:</span>
                  {BNPInfoData[0].name}
                </li>
                <li className="flex justify-between">
                  <span className="font-bold mr-2">Account type:</span>
                  {BNPInfoData[0].acc_type}
                </li>
                <li className="flex justify-between">
                  <span className="font-bold mr-2">Account number:</span>
                  {BNPInfoData[0].acc_number}
                </li>
                <li className="flex justify-between">
                  <span className="font-bold mr-2">Branch code:</span>
                  {BNPInfoData[0].branch_code}
                </li>
              </div>
              <div className="flex-row">
                <li className="flex justify-between">
                  <span className="font-bold mr-2">Website:</span>
                  <a href={website} target="_blank" rel="noopenner noreferrer">
                    {website}
                  </a>
                </li>
                <li className="flex justify-between">
                  <span className="font-bold mr-2">Email:</span>
                  <span
                    className=" hover:cursor-pointer"
                    href={BNPInfoData[0].sales_eMail_Address}
                  >
                    {BNPInfoData[0].sales_eMail_Address}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="font-bold mr-2">Phone number:</span>
                  {formatPhoneNumber(BNPInfoData[0].sales_contact)}
                </li>
              </div>
            </ul>
          </div>
        </footer>
      </div>
    </>
  );
}

export default SalesInvoiceModal;
