import React from "react";
import * as biIcons from "react-icons/bi";
import * as ioIcons from "react-icons/io";
// import * as cgIcons from "react-icons/cg";

export const logoutDropDownData = [
  {
    Description: "LOGOUT",
    link: "logout",
    case:"flex items-center pl-4 py-1 text-lg font-bold BNP-SubmitButtons border-4 h-[42px] w-[210px]",
    icon: <biIcons.BiLogOut />,
    iconcase: "-ml-2 mr-4 scale-150"
  },
  {
    Description: "Change password",
    link: "/PasswordChange",
    case:"flex items-center pl-4 py-1 text-sm BNP-SubmitButtons h-[40px] w-[210px]",
    icon: <ioIcons.IoIosKey />,
    iconcase: "-ml-2 mr-3 scale-[150%]"
  },
  {
    Description: "Account info",
    link: "/AccountInfo",
    case:"flex items-center pl-4 py-1 text-sm BNP-SubmitButtons h-[40px] w-[210px]",
    icon: <biIcons.BiUserCircle />,
    iconcase: "-ml-2 mr-3 scale-[175%]"
  },
  // {
  //   Description: "Invoices",
  //   link: "/Invoices",
  //   case:"flex items-center pl-4 py-1 text-sm BNP-SubmitButtons h-[40px] w-[210px]",
  //   icon: <cgIcons.CgFileDocument />,
  //   iconcase: "-ml-2 mr-3 scale-[170%]"
  // },
];
