import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";

// formating
import RemoveFunnies from "./fromatRemoveFunnies";
import validateRegNo from "./validateRegistrationNo";
// import isNumeric from "./isNumeric";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default function RegPlateimg(props) {
  // console.log("Render test regPlateimg")
  // console.log(props);

  const [open0, setOpen0] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [pWordingCase, setPWordingCase] = useState("text-blue-950");

  const [sz520, setSZ520] = useState(false);
  const [sz440, setSZ440] = useState(false);
  const [sz250, setSZ250] = useState(false);

  const [plateWording, setPlateWording] = useState("REG");
  const [plateProvince, setPlateProvince] = useState("");

  const [provHide, setProvHide] = useState(true);

  useEffect(() => {
    // Check if it is a Plate or other item - Only run for plates

    if (props.Cformat === "0") {
      setOpen0(true);
    } else if (props.Cformat === "1") {
      setOpen1(true);
    } else if (props.Cformat === "2") {
      setOpen2(true);
    } else {
      setOpen0(false);
      setOpen1(false);
      setOpen2(false);
    }

    if (props.CType === "plate") {
      let provClean = props.CregNo.toUpperCase();
      let pSize = "520";

      // Validate RegNo
      let pWording = "Reg";
      let pProvince = "";
      if (provClean.length > 0) {
        if (
          // GP, NC, WP, EC, ZN, MP, FS, NW
          provClean.substring(provClean.length - 2) === "GP" ||
          provClean.substring(provClean.length - 2) === "NC" ||
          provClean.substring(provClean.length - 2) === "WP" ||
          provClean.substring(provClean.length - 2) === "EC" ||
          provClean.substring(provClean.length - 2) === "ZN" ||
          provClean.substring(provClean.length - 2) === "MP" ||
          provClean.substring(provClean.length - 2) === "FS" ||
          provClean.substring(provClean.length - 2) === "NW"
        ) {
          pWording = provClean.substring(0, provClean.length - 2);
          pProvince = provClean.substring(provClean.length - 2);
        } else if (
          // L
          provClean.substring(provClean.length - 1) === "L"
        ) {
          pWording = provClean.substring(0, provClean.length - 1);
          pProvince = provClean.substring(provClean.length - 1);
        } else {
          pWording = provClean;
          pProvince = "";
        }
        // console.log(pWording)
        // console.log(pProvince)

        setPlateWording(pWording);
        setPlateProvince(pProvince);
      }

      if (pProvince === "" && pWording.length > 0) {
        pProvince = props.CpID.substring(0, props.CpID.indexOf("^"));
      }

      let valid = validateRegNo(RemoveFunnies(provClean), pProvince);
      // Remove Province for KZN and CA old numbers
      if (valid.substring(0, 2) === "WP") {
        setProvHide(true);
      } else if (valid.substring(0, 3) === "KZN") {
        setProvHide(true);
      } else {
        setProvHide(false);
      }

      // Get color Array
      if (pProvince === "NC") {
        setPWordingCase("text-green-950 BNP-Trans300 BNP-STDcss");
      } else if (pProvince === "WP") {
        setPWordingCase("text-black BNP-Trans300 BNP-STDcss");
      } else if (valid.substring(0, 2) === "WP") {
        setPWordingCase("text-black BNP-Trans300 BNP-STDcss");
      } else if (pProvince === "NW") {
        setPWordingCase("text-black BNP-Trans300 BNP-STDcss");
      } else if (pProvince === "FS") {
        if (valid === "OT-S") {
          setPWordingCase("text-black BNP-Trans300 BNP-STDcss");
        } else {
          setPWordingCase("text-green-800 BNP-Trans300 BNP-STDcss");
        }
      } else if (pProvince === "GP") {
        setPWordingCase("text-blue-950 BNP-Trans300 BNP-STDcss");
      } else if (pProvince === "MP") {
        setPWordingCase("text-black BNP-Trans300 BNP-STDcss");
      } else if (pProvince === "L") {
        setPWordingCase("text-black BNP-Trans300 BNP-STDcss");
      } else if (
        valid.substring(0, 5) === "KZN-3" ||
        valid.substring(0, 5) === "KZN-2"
      ) {
        setPWordingCase("text-blue-900 BNP-Trans300 BNP-STDcss");
      } else if (pProvince === "ZN") {
        setPWordingCase("text-green-800 BNP-Trans300 BNP-STDcss");
      } else if (pProvince === "EC") {
        setPWordingCase("text-black BNP-Trans300 BNP-STDcss");
      } else {
        setPWordingCase("text-blue-950 BNP-Trans300 BNP-STDcss");
      }

      // KZN New plate - AB 12 CD ZN
      // let RegClean = RemoveFunnies(provClean);
      // if (
      //   pProvince === "ZN" &&
      //   isNumeric(RegClean.substring(0, 1)) === false &&
      //   isNumeric(RegClean.substring(1, 2)) === false &&
      //   isNumeric(RegClean.substring(2, 3)) === true &&
      //   isNumeric(RegClean.substring(3, 4)) === true &&
      //   isNumeric(RegClean.substring(4, 5)) === false &&
      //   isNumeric(RegClean.substring(5, 6)) === false
      // ) {
      //   setPWordingCase("text-blue-950 BNP-Trans300");
      // }

      // Get size form ID info
      if (props.CpID.includes("^520^") === true) {
        // console.log("^520^:" + props.CpID.includes("^520^"))
        pSize = "520";
        setSZ520(true);
        setSZ440(false);
        setSZ250(false);
      } else if (props.CpID.includes("^440^") === true) {
        // console.log("^440^:" + props.CpID.includes("^440^"))
        pSize = "440";
        setSZ520(false);
        setSZ440(true);
        setSZ250(false);
      } else if (props.CpID.includes("^250^") === true) {
        // console.log("^250^:" + props.CpID.includes("^250^"))
        pSize = "250";
        setSZ520(false);
        setSZ440(false);
        setSZ250(true);
      } else {
        // console.log("size error")
        setSZ520(false);
        setSZ440(false);
        setSZ250(false);
      }

      // Remove Province when RegNo it too long
      if (
        pProvince.length >= 2 &&
        (pProvince.length === 0 ||
          valid.substring(0, 5) === "KZN-3" ||
          valid.substring(0, 5) === "KZN-2" ||
          valid.substring(0, 4) === "WP-3" ||
          valid.substring(0, 4) === "WP-2")
      ) {
        setProvHide(true);
        // console.log("hidden");
        pProvince = "";
      } else {
        setProvHide(false);
        // console.log("not hidden");
      }

      // Remove spacing for 440 ZN and GP new structure
      if (
        (pProvince === "GP" && pSize === "440") ||
        (pProvince === "ZN" && pSize === "440") ||
        pSize === "440" ||
        pSize === "250"
      ) {
        // console.log("Make them 440/250 text");
        setPlateWording(RemoveFunnies(pWording));
        // console.log("size fixing");
      }
    }

    if (props.CType !== "plate") {
      setPWordingCase("hidden");
    }

    // if (props.CType === "plate") {
    //   console.log("a");
    //   console.log(props.CType);
    //   console.log(pProvince);
    //   console.log(valid);
    //   console.log(plateWording);
    //   console.log(valid.substring(0, 5));
    // }

    // console.log(props);
  }, [props, provHide, plateWording, plateProvince]);

  // console.log(props.Ccase);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div className="">
        {/* Small 250px format plates */}
        <Transition
          show={open2}
          enter="transition ease-in-out duration-500 transform"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in-out duration-500 transform"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={props.Ccase}>
            <div className="relative flex justify-center items-center">
              {/* Plate IMG */}
              <img src={props.Cimgs} alt="" />

              {/* NoPlate letters */}

              <div className="absolute BNP-STDcss font-sans font-bold text-[205%] h-100 scale-x-100 scale-y-125">
                {/* //////////////////////////////////////////////////// */}
                {/* 520 */}
                <Transition
                  show={sz520}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={pWordingCase}
                >
                  <div className="flex justify-between items-center w-100">
                    <p className="flex justify-center items-center w-100 pl-[2%] transition-all duration-500 whitespace-nowrap  ">
                      {plateWording}
                    </p>
                    <p
                      className={
                        provHide
                          ? "hidden"
                          : "flex justify-end items-center w-[45%] pr-[3%]"
                      }
                    >
                      {plateProvince}
                    </p>
                  </div>
                </Transition>
                {/* //////////////////////////////////////////////////// */}
                {/* 440 */}
                <Transition
                  show={sz440}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={pWordingCase}
                >
                  <div className="flex justify-between items-center w-100">
                    <p
                      className={
                        provHide
                          ? "flex justify-center items-center w-100 ml-[2%] transition-all duration-500 whitespace-nowrap"
                          : "flex justify-center items-center w-100 ml-[2%] transition-all duration-500 whitespace-nowrap"
                      }
                    >
                      {plateWording}
                    </p>

                    <p
                      className={
                        provHide
                          ? "hidden"
                          : "flex justify-end items-center w-[47%] pr-[3%]"
                      }
                    >
                      {plateProvince}
                    </p>
                  </div>
                </Transition>
                {/* //////////////////////////////////////////////////// */}
                {/* 250 */}
                <Transition
                  show={sz250}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={pWordingCase}
                >
                  <div className="flex-row w-100 text-[80%]">
                    <p className="flex justify-center w-[69%]">
                      {plateWording.substring(0, 3)}
                    </p>

                    <div className="flex justify-center items-center w-100 -mt-[10%] pl-[2px]">
                      <p className="flex justify-center items-center ">
                        {plateWording.substring(3, 10)}
                      </p>

                      <p
                        className={
                          provHide
                            ? "hidden"
                            : "flex justify-end items-center mx-[4%]"
                        }
                      >
                        {RemoveFunnies(plateProvince)}
                      </p>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </Transition>

        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */}

        {/* Medium 280px format plates */}
        <Transition
          show={open0}
          enter="transition ease-in-out duration-500 transform"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in-out duration-500 transform"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={props.Ccase}>
            <div className="relative flex justify-center items-center">
              {/* Plate IMG */}
              <img src={props.Cimgs} alt="" />

              {/* NoPlate letters */}

              <div className="absolute BNP-STDcss font-sans font-bold text-[235%] h-100 scale-x-100 scale-y-125">
                {/* //////////////////////////////////////////////////// */}
                {/* 520 */}
                <Transition
                  show={sz520}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={pWordingCase}
                >
                  <div className="flex justify-between items-center w-100">
                    <p className="flex justify-center items-center w-100 pl-[1%] transition-all duration-500 whitespace-nowrap  ">
                      {plateWording}
                    </p>
                    <p
                      className={
                        provHide
                          ? "hidden"
                          : "flex justify-end items-center w-[42%] mr-[3%]"
                      }
                    >
                      {plateProvince}
                    </p>
                  </div>
                </Transition>
                {/* //////////////////////////////////////////////////// */}
                {/* 440 */}
                <Transition
                  show={sz440}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={pWordingCase}
                >
                  <div className="flex justify-between items-center w-100">
                    <p
                      className={
                        provHide
                          ? "flex justify-center items-center w-100 ml-[2%] transition-all duration-500 whitespace-nowrap"
                          : "flex justify-center items-center w-100 ml-[2%] transition-all duration-500 whitespace-nowrap"
                      }
                    >
                      {plateWording}
                    </p>

                    <p
                      className={
                        provHide
                          ? "hidden"
                          : "flex justify-end items-center w-[47%] pr-[3%]"
                      }
                    >
                      {plateProvince}
                    </p>
                  </div>
                </Transition>
                {/* //////////////////////////////////////////////////// */}
                {/* 250 */}
                <Transition
                  show={sz250}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={pWordingCase}
                >
                  <div className="flex-row w-100">
                    <p className="flex justify-center w-[70%]">
                      {plateWording.substring(0, 3)}
                    </p>

                    <div className="flex justify-center items-center w-100 -mt-[10%]">
                      <p className="flex justify-center items-center ">
                        {plateWording.substring(3, 10)}
                      </p>

                      <p
                        className={
                          provHide
                            ? "hidden"
                            : "flex justify-end items-center mx-[4%]"
                        }
                      >
                        {RemoveFunnies(plateProvince)}
                      </p>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </Transition>

        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */}

        {/* Big format plates */}
        <Transition
          show={open1}
          enter="transition ease-in-out duration-500 transform"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in-out duration-500 transform"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={props.Ccase}>
            <div className="relative flex justify-center items-center">
              {/* Plate IMG */}
              <img src={props.Cimgs} alt="" />

              {/* NoPlate letters */}

              <div className="absolute BNP-STDcss font-sans font-bold text-[70px] h-100 scale-x-100 scale-y-125">
                {/* //////////////////////////////////////////////////// */}
                {/* 520 */}
                <Transition
                  show={sz520}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={pWordingCase}
                >
                  <div className="flex justify-between items-center w-100">
                    <p className="flex justify-center items-center w-100 pl-[1%] transition-all duration-500 whitespace-nowrap  ">
                      {plateWording}
                    </p>
                    <p
                      className={
                        provHide
                          ? "hidden"
                          : "flex justify-end items-center w-[42%] mr-[3%]"
                      }
                    >
                      {plateProvince}
                    </p>
                  </div>
                </Transition>
                {/* //////////////////////////////////////////////////// */}
                {/* 440 */}
                <Transition
                  show={sz440}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={pWordingCase}
                >
                  <div className="flex justify-between items-center w-100">
                    <p
                      className={
                        provHide
                          ? "flex justify-center items-center w-100 ml-[2%] transition-all duration-500 whitespace-nowrap"
                          : "flex justify-center items-center w-100 ml-[2%] transition-all duration-500 whitespace-nowrap"
                      }
                    >
                      {plateWording}
                    </p>

                    <p
                      className={
                        provHide
                          ? "hidden"
                          : "flex justify-end items-center w-[47%] pr-[3%]"
                      }
                    >
                      {plateProvince}
                    </p>
                  </div>
                </Transition>
                {/* //////////////////////////////////////////////////// */}
                {/* 250 */}
                <Transition
                  show={sz250}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={pWordingCase}
                >
                  <div className="flex-row w-100">
                    <p className="flex justify-center w-[70%]">
                      {plateWording.substring(0, 3)}
                    </p>

                    <div className="flex justify-center items-center w-100 -mt-[10%]">
                      <p className="flex justify-center items-center ">
                        {plateWording.substring(3, 10)}
                      </p>

                      <p
                        className={
                          provHide
                            ? "hidden"
                            : "flex justify-end items-center mx-[4%]"
                        }
                      >
                        {RemoveFunnies(plateProvince)}
                      </p>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
}
