import React, { useEffect, useState } from "react";
// import ReactToPrint from "react-to-print";

// Data lookups
import storePlateInfo from "../data/Lookups/plates.json";
import otherSalesInfo from "../data/Lookups/otherForSaleData.json";

// Utilities
import formatCurrency from "../utilities/formatCurrency";
import validateRegNo from "../utilities/validateRegistrationNo";
import formatRegistrationNo from "../utilities/formatRegistrationNo";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default function SalesInvoicePrintItems(props) {
  // console.log("Render SalesInvoicePrintItems")

  // State
  const [prodDesc, setProdDesc] = useState("");

  useEffect(() => {
    let provID;
    let prodType;
    let sizeDesc;
    let provinceDesc;
    let newReg = props.reg;

    // Get Array index
    if (props.province === "NC") {
      provID = 0;
    } else if (props.province === "WP") {
      provID = 1;
    } else if (props.province === "NW") {
      provID = 2;
    } else if (props.province === "FS") {
      provID = 3;
    } else if (props.province === "GP") {
      provID = 4;
    } else if (props.province === "MP") {
      provID = 5;
    } else if (props.province === "L") {
      provID = 6;
    } else if (props.province === "ZN") {
      provID = 7;
    } else if (props.province === "EC") {
      provID = 8;
    } else if (props.province === "BP") {
      provID = 9;
    }

    // Size Index
    if (props.size === "520") {
      sizeDesc = "520mm x 110mm";
    } else if (props.size === "440") {
      sizeDesc = "440mm x 120mm";
    } else if (props.size === "250") {
      sizeDesc = "250mm x 205mm";
    } else {
      sizeDesc = "";
    }
    // console.log(sizeDesc);

    // plate info
    if (props.prodType === "plate") {
      prodType = "Number plate";
      // Format Reg no
      let valid = validateRegNo(props.reg, props.province);
      newReg = formatRegistrationNo(props.reg, props.size, valid);

      const numbers = [storePlateInfo[provID]];
      const newPlateInfo = numbers.find((prov) => (prov = 1));

      // Set Proving desc
      if (props.province?.length === 0) {
        provinceDesc = "";
      } else {
        provinceDesc = newPlateInfo?.name;
      }
      setProdDesc(
        prodType + "," + sizeDesc + "," + provinceDesc + "," + newReg
      );
    }

    // backing plate info
    if (props.prodType === "bplate") {
      prodType = "Backing plate";
      setProdDesc(prodType + "," + sizeDesc);
    }

    // Rivet Into
    if (props.prodType === "rivet") {
      const rivets = [otherSalesInfo[0]];
      const rivetNewInfo = rivets.find((id) => (id = 1));
      setProdDesc(rivetNewInfo.desc);
    }
  }, [props]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <tbody>
        <tr className="h-10">
          <td>{prodDesc}</td>
          <td className="text-center">{props.quant}</td>
          <td className="text-right pr-4">{formatCurrency(props.price)}</td>
        </tr>
      </tbody>
    </>
  );
}
