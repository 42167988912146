// Write Verified Status back to db
// Write Verified Image back to db
// build Camera features

import { useContext, useEffect, useState, useRef } from "react";
import { Transition } from "@headlessui/react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import compressor from "compressorjs";

// Context
import AdminContext from "../context/AdminContext";
import UrlContext from "../context/URLContext";
import modalContext from "../context/ModalContext";
import UserContext from "../context/UserContext";

// Data lookups
import plateData from "../data/Lookups/plates.json";
import bplateData from "../data/Lookups/bplates.json";
import plateCaseData from "../data/Lookups/plateCase.json";
import otherSalesInfo from "../data/Lookups/otherForSaleData.json";

// formating
import ViewRegImg from "../utilities/regPlateimgView";
import formatRegistrationNo from "../utilities/formatRegistrationNo";
import validateRegNo from "../utilities/validateRegistrationNo";
import RemoveFunniesIMG from "../utilities/formatRemoveFunniesIMG";

// React Icons
import * as aiIcons from "react-icons/ai";
import * as biIcons from "react-icons/bi";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function OrderModalProdInfo(props) {
  // console.log("Render ProductInfo");

  // Context
  const {
    setRegChecksCount,
    setAdminPgRefresh,
    orderPaid,
    setOrdersModalValidation,
  } = useContext(AdminContext);
  const { updateValidated, googleImgUpload } = useContext(UrlContext);
  const {
    msgClean,
    setMsgOpen,
    setMsgTxt,
    setMsgType,
    setPrevType,
    setPrevDoc,
    setTrackingModalImgPreview,
  } = useContext(modalContext);
  const { userID } = useContext(UserContext);

  // Max img size to Google cloud
  const uploadSize = 512000; // 512000 is 500kb

  // State
  const [productImg, setProductImg] = useState("");
  const [plateReg, setPlateReg] = useState("");
  const [imgCase, setImgCase] = useState("");
  const [imgValidate, setimgValidate] = useState(false);
  const [plateSize, setPlateSize] = useState("520mm x 110mm");
  const [rivetSize, setRivetSize] = useState(false);
  const [L1, setL1] = useState("");
  const [L1view, setL1view] = useState(true);
  const [L2, setL2] = useState("");
  const [L2view, setL2view] = useState(true);
  const [L3, setL3] = useState("");
  const [L3view, setL3view] = useState(true);
  const [L4, setL4] = useState("");
  const [L4view, setL4view] = useState(true);
  const [L5, setL5] = useState("");
  const [L5view, setL5view] = useState(true);
  const [L6, setL6] = useState("");
  const [L6view, setL6view] = useState(true);
  const [L7, setL7] = useState("");
  const [L7view, setL7view] = useState(true);
  const [L8, setL8] = useState("");
  const [L8view, setL8view] = useState(true);
  const [L9, setL9] = useState("");
  const [L9view, setL9view] = useState(true);

  const L1Ref = useRef();
  const [DocVerCheck, setDocVerCheck] = useState(false);
  const [DocVerCheckDisable, setDocVerCheckDisable] = useState(false);
  const [L1Check, setL1Check] = useState(false);
  const [L2Check, setL2Check] = useState(false);
  const [L3Check, setL3Check] = useState(false);
  const [L4Check, setL4Check] = useState(false);
  const [L5Check, setL5Check] = useState(false);
  const [L6Check, setL6Check] = useState(false);
  const [L7Check, setL7Check] = useState(false);
  const [L8Check, setL8Check] = useState(false);
  const [L9Check, setL9Check] = useState(false);
  const [checksDisable, setChecksDisable] = useState(false);
  const [loopStop, setloopStop] = useState(false);
  const [checkCounts, setCheckCounts] = useState(0);
  const [verifiedStamp, setVerifiedStamp] = useState(false);
  const [lastClick, setLastClick] = useState("");
  const [checkCase, setCheckCase] = useState(
    "flex text-3xl text-red-600 space-x-3"
  );

  const [verificationFileWording, setVerificationFileWording] = useState(
    "Upload a Verification Image :"
  );
  const [validatedNewName, setValidatedNewName] = useState("");
  const [verificationFile, setVerificationFile] = useState([]);
  const [verificationMove, setVerificationMove] = useState(false);
  const [verificationImgError, setVerificationImgError] = useState(false);
  const [verificationErrorMsg, setVerificationErrorMsg] = useState("");
  const [verificationCase, setVerificationCase] = useState("");

  const [verificationDoc, setVerificationDoc] = useState(null);
  const [uploadImgView, setuploadImgView] = useState(true);
  const [imgSelectActive, setImgSelectActive] = useState(false);
  const [yesActive, setYesActive] = useState(true);
  const [imgLoading, setIMGLoading] = useState(true);

  const [regDoc, setRegDoc] = useState(false);

  useEffect(() => {
    if (props.prodType === "plate") {
      setimgValidate(true);
      // build reg validate split
      setL1(props.reg.substring(0, 1));
      setL2(props.reg.substring(1, 2));
      setL3(props.reg.substring(2, 3));
      setL4(props.reg.substring(3, 4));
      setL5(props.reg.substring(4, 5));
      setL6(props.reg.substring(5, 6));
      setL7(props.reg.substring(6, 7));
      setL8(props.reg.substring(7, 8));
      setL9(props.reg.substring(8, 9));

      if (props.verified) {
        setChecksDisable(true);
        setDocVerCheckDisable(true);
      }

      if (orderPaid === "No") {
        setDocVerCheck(false);
      }
    } else {
      setimgValidate(false);
    }

    // Plate size and Province select
    if (productImg.length === 0) {
      let provID;
      let plateSizeID;

      // Get Array index
      if (props.province === "NC") {
        provID = 0;
      } else if (props.province === "WP") {
        provID = 1;
      } else if (props.province === "NW") {
        provID = 2;
      } else if (props.province === "FS") {
        provID = 3;
      } else if (props.province === "GP") {
        provID = 4;
      } else if (props.province === "MP") {
        provID = 5;
      } else if (props.province === "L") {
        provID = 6;
      } else if (props.province === "ZN") {
        provID = 7;
      } else if (props.province === "EC") {
        provID = 8;
      } else if (props.province === "BP") {
        provID = 9;
      }

      // Size Index
      if (props.size === 520) {
        plateSizeID = 0;
        setPlateSize("520mm");
      } else if (props.size === 440) {
        plateSizeID = 1;
        setPlateSize("440mm");
      } else if (props.size === 250) {
        plateSizeID = 2;
        setPlateSize("250mm");
      } else {
        plateSizeID = 0;
      }

      // Case Info
      const cInfo = [plateCaseData[plateSizeID]];
      const newcInfo = cInfo.find((size) => (size = 1));
      // console.log(newcInfo);

      // Plate province select
      if (props.prodType === "plate") {
        // Plate Info
        const pInfo = [plateData[provID]];
        const newpInfo = pInfo.find((prov) => (prov = 1));
        // console.log(newpInfo)

        // Plate Img and Case
        if (props.size === 520) {
          setProductImg(newpInfo.size520);
          setImgCase(newcInfo.pSizeOrders520);
        } else if (props.size === 440) {
          setProductImg(newpInfo.size440);
          setImgCase(newcInfo.pSizeOrders440);
        } else if (props.size === 250) {
          setProductImg(newpInfo.size250);
          setImgCase(newcInfo.pSizeOrders250);
        }
      }

      // // bPlate
      if (props.prodType === "bplate") {
        // Case Info
        const bpInfo = [bplateData[plateSizeID]];
        const newbpInfo = bpInfo.find((size) => (size = 1));
        // console.log(newbpInfo)

        // Backing plate Img and Case
        if (props.size === 520) {
          setProductImg(newbpInfo.bpsize520);
          setImgCase(newcInfo.bSizeValid520);
        } else if (props.size === 440) {
          setProductImg(newbpInfo.bpsize440);
          setImgCase(newcInfo.bSizeValid440);
        } else if (props.size === 250) {
          setProductImg(newbpInfo.bpsize250);
          setImgCase(newcInfo.bSizeValid250);
        }
      }

      // Rivet Into
      if (props.prodType === "rivet") {
        setRivetSize(true);
        const rivets = [otherSalesInfo[0]];
        const rivetNewInfo = rivets.find((id) => (id = 1));
        // console.log(rivetNewInfo);

        setProductImg(rivetNewInfo.img);
        setImgCase(newcInfo.tracking);
        // console.log(newcInfo);
      }
    }

    // Update Verified checkboxes
    if (L1.length === 0) {
      setL1view(false);
    } else {
      setL1view(true);
    }
    if (L2.length === 0) {
      setL2view(false);
    } else {
      setL2view(true);
    }
    if (L3.length === 0) {
      setL3view(false);
    } else {
      setL3view(true);
    }
    if (L4.length === 0) {
      setL4view(false);
    } else {
      setL4view(true);
    }
    if (L5.length === 0) {
      setL5view(false);
    } else {
      setL5view(true);
    }
    if (L6.length === 0) {
      setL6view(false);
    } else {
      setL6view(true);
    }
    if (L7.length === 0) {
      setL7view(false);
    } else {
      setL7view(true);
    }
    if (L8.length === 0) {
      setL8view(false);
    } else {
      setL8view(true);
    }
    if (L9.length === 0) {
      setL9view(false);
    } else {
      setL9view(true);
    }
  }, [productImg, props, L1, L2, L3, L4, L5, L6, L7, L8, L9, orderPaid]);

  // Verify Documents loaded
  const handleDocVerChecked = () => {
    if (orderPaid === "Yes") {
      setDocVerCheck(!DocVerCheck);
    }
  };

  // Check if Validation file loaded
  const handleChecked = (value) => {
    let i = 0;
    try {
      if (
        verificationFile.name.length > 0 &&
        orderPaid === "Yes" &&
        DocVerCheck === true
      ) {
        i = 1;
      }
    } catch (error) {
      setVerificationErrorMsg("Please select a file to preview!");
      setVerificationImgError(true);
    }

    if (i > 0) {
      if (checksDisable === false) {
        if (props.prodType === "plate") {
          setloopStop(false);
          setLastClick(value);

          if (value === "L1") {
            setL1Check(!L1Check);
          }
          if (value === "L2") {
            setL2Check(!L2Check);
          }
          if (value === "L3") {
            setL3Check(!L3Check);
          }
          if (value === "L4") {
            setL4Check(!L4Check);
          }
          if (value === "L5") {
            setL5Check(!L5Check);
          }
          if (value === "L6") {
            setL6Check(!L6Check);
          }
          if (value === "L7") {
            setL7Check(!L7Check);
          }
          if (value === "L8") {
            setL8Check(!L8Check);
          }
          if (value === "L9") {
            setL9Check(!L9Check);
          }
        }
      }
    }
  };

  useEffect(() => {
    // Plate Format and Validate
    if (props.prodType === "plate") {
      // Format Reg number
      let valid = validateRegNo(props.reg.toUpperCase(), props.province);
      setPlateReg(formatRegistrationNo(props.reg, props.size, valid));

      // Validated set checkboxes all true
      if (checksDisable === true) {
        setDocVerCheck(true);
        setL1Check(true);
        setL2Check(true);
        setL3Check(true);
        setL4Check(true);
        setL5Check(true);
        setL6Check(true);
        setL7Check(true);
        setL8Check(true);
        setL9Check(true);
        setCheckCounts(8);
        setVerificationMove(true);
        setCheckCase("flex text-3xl text-green-700 space-x-3");

        setIMGLoading(true);
        let fileStart = "https://storage.cloud.google.com/bnp-upload-files/";
        let fileEnd = "?authuser=1";
        setVerificationDoc(fileStart + props.verifiedImg + fileEnd);
        setuploadImgView(false);
        setIMGLoading(false);
      } else {
        // loop validated check which checkboxes ticked
        if (loopStop === false) {
          let UnqID =
            props.Order_No +
            "^" +
            props.province +
            "^" +
            props.size +
            "^" +
            props.reg;

          let l1 = 0;
          let l2 = 0;
          let l3 = 0;
          let l4 = 0;
          let l5 = 0;
          let l6 = 0;
          let l7 = 0;
          let l8 = 0;
          let l9 = 0;

          if (L1Check === true) {
            l1 = 1;
          }
          if (L2Check === true) {
            l2 = 1;
          }
          if (L3Check === true) {
            l3 = 1;
          }
          if (L4Check === true) {
            l4 = 1;
          }
          if (L5Check === true) {
            l5 = 1;
          }
          if (L6Check === true) {
            l6 = 1;
          }
          if (L7Check === true) {
            l7 = 1;
          }
          if (L8Check === true) {
            l8 = 1;
          }
          if (L9Check === true) {
            l9 = 1;
          }

          let CheckCount = l1 + l2 + l3 + l4 + l5 + l6 + l7 + l8 + l9;
          setCheckCounts(CheckCount);

          if (CheckCount === props.reg.length) {
            setCheckCase("flex text-3xl text-green-700 space-x-3");
            setVerifiedStamp(true);
          } else setCheckCase("flex text-3xl text-red-600 space-x-3");

          const plateChecked = {
            id: UnqID,
            L1: L1Check,
            L2: L2Check,
            L3: L3Check,
            L4: L4Check,
            L5: L5Check,
            L6: L6Check,
            L7: L7Check,
            L8: L8Check,
            L9: L9Check,
            CheckCount: CheckCount,
          };

          setRegChecksCount((regChecksCount) => {
            if (regChecksCount.find((item) => item.id === UnqID) == null) {
              return [...regChecksCount, plateChecked];
            } else {
              return regChecksCount.map((item) => {
                if (item.id === UnqID) {
                  return {
                    ...item,
                    L1: L1Check,
                    L2: L2Check,
                    L3: L3Check,
                    L4: L4Check,
                    L5: L5Check,
                    L6: L6Check,
                    L7: L7Check,
                    L8: L2Check,
                    L9: L9Check,
                    CheckCount: CheckCount,
                  };
                } else {
                  return item;
                }
              });
            }
          });
          setloopStop(true);
        }
      }
    }
  }, [
    checksDisable,
    loopStop,
    setRegChecksCount,
    props,
    L1Check,
    L2Check,
    L3Check,
    L4Check,
    L5Check,
    L6Check,
    L7Check,
    L8Check,
    L9Check,
  ]);

  const handleNoClick = () => {
    setVerifiedStamp(false);
    handleChecked(lastClick);

    // clear checkbox if no image present
    if (yesActive === true) {
      setL1Check(false);
      setL2Check(false);
      setL3Check(false);
      setL4Check(false);
      setL5Check(false);
      setL6Check(false);
      setL7Check(false);
      setL8Check(false);
      setL9Check(false);
    }
  };

  const handleYesClick = () => {
    setChecksDisable(true);
    setDocVerCheckDisable(true);
    setVerifiedStamp(false);
    setOrdersModalValidation(true);
    // Update validated in DB and upload Image to Google cloud
    googleCloud();
  };

  const googleCloud = async () => {
    let img = verificationFile;
    let name = validatedNewName;

    const file = img;
    let uploadProgress = 0;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("image_Newname", name.trim());

    let url = googleImgUpload;
    const result = await axios({
      method: "post",
      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        uploadProgress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log("Upload Progres: " + uploadProgress + "%");
      },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
    // console.log(result);

    if (result.status === 200 && uploadProgress === 100) {
      updateValidateDB();
    } else {
      msgClean();
      setMsgTxt("Error with Image supplied!  Check your Image and try again");
      setMsgType("Error");
      setMsgOpen(true);
    }
  };

  // update validated
  const updateValidateDB = async () => {
    let url = updateValidated + props.Sales_ID;

    const data = await axios
      .patch(url, {
        Validated: true,
        VerifiedImg: validatedNewName,
        EmbossUCN: userID,
      })
      .then((res) => res)
      .catch((err) => {
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });
    // console.log(data);

    if (data.status === 200) {
      setAdminPgRefresh(1);
    }
  };

  useEffect(() => {
    // Set Preview Cient docs
    if (props.reg?.length === 0) {
      setRegDoc(false);
    } else {
      setRegDoc(true);
    }
    // console.log(newPlateInfo)

    if (imgSelectActive === true) {
      if (verificationFile.length === 0) {
        setVerificationImgError(true);
        setVerificationErrorMsg("Please select a file to preview!");
        setuploadImgView(true);
        setVerificationFileWording("Upload a Verification Image :");
        setYesActive(true);
      } else {
        setVerificationDoc(URL.createObjectURL(verificationFile));
        setuploadImgView(false);
        setYesActive(false);

        // New file name
        const date = new Date();
        setValidatedNewName(
          date.toISOString().split("T")[0].replace(/-/g, "") +
            "-" +
            props.reg +
            "-Verified|" +
            RemoveFunniesIMG(verificationFile.name)
        );
      }
      if (verificationImgError === true) {
        setuploadImgView(true);
      }
    }

    if (checksDisable === false) {
      if (verificationImgError === true) {
        setVerificationCase("BNP-ErrorBoarder rounded-md cursor-pointer");
      } else {
        setVerificationCase(
          "border-2 hover:border-BNPblue cursor-pointer rounded-md"
        );
      }
    } else {
      setVerificationCase("border-2");
    }
  }, [
    imgSelectActive,
    verificationFile,
    verificationImgError,
    checksDisable,
    props.reg,
  ]);

  // Verification import info
  const ChangeVerificationfile = (event) => {
    try {
      // Error message clear
      setVerificationImgError(false);
      setVerificationErrorMsg("");
      VerificationFocusOn();

      

      let file = event.target.files[0];
      let imgLen = file.size;
      let imgName = file.name;

      // Check file type
      if (imgName.length !== 0) {
        if (
          imgName.substring(imgName.length - 4).toLowerCase() !== ".jpg" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".png" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".gif" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== "jpeg" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".bmp" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".svg"
        ) {
          setVerificationImgError(true);
          setVerificationErrorMsg(
            "Please use a prefered file formats! i.e. jpg, jpeg, bmp, png, svg, gif."
          );
          setVerificationMove(false);
          setVerificationFileWording("Upload a Verification Image:");
        } else {
          setVerificationImgError(false);
          setVerificationErrorMsg("");
        }
      } else {
        setVerificationImgError(false);
        setVerificationErrorMsg("");
      }

      // Check size of img size for compressing
      console.log("original size: ",imgLen);
      if (imgLen >= uploadSize) {
        // compress image
        new compressor(file, {
          quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
          success(result) {
            // console.log("compressed file: ", result.size)
            setVerificationFile(result);
          },
        });
      } else {
        // File is smaller than 500kb, no compression needed
        setVerificationFile(file);
      }

      setVerificationFile(event.target.files[0]);

      PreviewVerificationload();
    } catch (error) {
      setVerificationMove(false);
      setVerificationFile([]);
    }
  };
 
  const VerificationFocusOn = () => {
    console.log("bbb")
    setVerificationMove(true);
    setVerificationFileWording("Verification Image:");
  };

console.log(verificationFile)
  
  const PreviewVerificationload = () => {
    setImgSelectActive(true);
    if (verificationFile.length !== 0) {
      setVerificationDoc(URL.createObjectURL(verificationFile));
    }
  };

  const PreviewRegDocs = (i) => {
    let fileStart = "https://storage.cloud.google.com/bnp-upload-files/";
    let fileEnd = "?authuser=1";

    if (i === "ID") {
      let img = props.IDImg.replace("|", "%7C").replace("|", "%7C");
      img = fileStart + img.replace(/ /g, "%20") + fileEnd;

      // console.log(img);
      try {
        setPrevDoc(img);
      } catch (error) {}
      setPrevType("ID");
      setTrackingModalImgPreview(true);
    }
    if (i === "Reg") {
      let img = props.RegImg.replace("|", "%7C").replace("|", "%7C");
      img = fileStart + img.replace(/ /g, "%20") + fileEnd;
      // console.log(img);
      try {
        setPrevDoc(img);
      } catch (error) {}
      setPrevType("Reg");
      setTrackingModalImgPreview(true);
    }
    if (i === "Selfie") {
      let img = props.SelfieImg.replace("|", "%7C").replace("|", "%7C");
      img = fileStart + img.replace(/ /g, "%20") + fileEnd;
      // console.log(img);
      try {
        setPrevDoc(img);
      } catch (error) {}
      setPrevType("Selfie");
      setTrackingModalImgPreview(true);
    }
  };

  // open camer for photo
  const cameraClick = () => {};

  // console.log(props)
  // console.log(imgCase)
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="flex-row font-Montserrat items-center p-3 text-BNPblue bg-white">
        <div className={regDoc ? "flex-row justify-center" : "hidden"}>
          <div className="flex justify-center">
            <div className="flex-row justify-center w-[70%]">
              <div className="flex justify-center space-x-10">
                <p className="font-semibold">View Documents:</p>
              </div>
              <div className="flex justify-center space-x-10">
                <div
                  onClick={() => PreviewRegDocs("ID")}
                  className="BNP-Links p-0 m-0 mr-7"
                >
                  ID/Passport
                </div>
                <div
                  onClick={() => PreviewRegDocs("Reg")}
                  className="BNP-Links p-0 m-0 mr-7"
                >
                  Vehile Registration
                </div>
                <div
                  onClick={() => PreviewRegDocs("Selfie")}
                  className="BNP-Links p-0 m-0 mr-7"
                >
                  Selfie
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <p className="text-2xl mr-4 text-red-600 font-semibold">
                Docs verified
              </p>
              <input
                type="checkbox"
                checked={DocVerCheck}
                disabled={DocVerCheckDisable}
                onChange={() => handleDocVerChecked()}
                className="scale-150"
              />
            </div>
          </div>

          <div className={regDoc ? "flex justify-center" : "hidden"}>
            <div className="flex justify-center h-2 w-[95%] my-2 border-t-2 border-BNPblue border-opacity-40"></div>
          </div>
        </div>
        <div className="flex">
          {/* body - img and details */}
          {/* <img src={productImg} alt="" className={imgCase} /> */}

          <ViewRegImg // Has to start with Uppercase letter
            Cformat={"1"}
            CType={props.prodType}
            Cimgs={productImg}
            CpID={props.CpID}
            CregNo={plateReg}
            Ccase={imgCase}
          />

          <div className="flex-row items-center w-[210px] m-3">
            <div className="flex justify-between items-end font-semibold">
              <p>Quantity</p>
              <p className="text-xl">{props.quant}</p>
            </div>
            <div
              className={
                rivetSize
                  ? "hidden"
                  : "flex justify-between items-end font-semibold"
              }
            >
              <p>Size</p>
              <p className="text-xl">{plateSize}</p>
            </div>

            <div
              className={
                imgValidate
                  ? "flex justify-between items-end font-semibold"
                  : "hidden"
              }
            >
              <p>Reg No</p>
              <p className="text-xl">{plateReg}</p>
            </div>
          </div>
        </div>
        {/* Verification img and checkboxes*/}
        <div className={imgValidate ? "relative flex-row" : "hidden"}>
          <div className="flex-row p-2 font-semibold">
            <div className={checkCase}>
              <p>Registration letters verified!</p>
              <p>{checkCounts} </p>
            </div>
            {/* Check boxes */}
            <div className="flex justify-between px-3">
              <div
                className={L1view ? "flex-row  text-center text-3xl" : "hidden"}
              >
                <p>{L1}</p>
                <input
                  ref={L1Ref}
                  type="checkbox"
                  checked={L1Check}
                  disabled={checksDisable}
                  onChange={() => handleChecked("L1")}
                  className="scale-150"
                />
              </div>
              <div
                className={L2view ? "flex-row text-center text-3xl" : "hidden"}
              >
                <p>{L2}</p>
                <input
                  type="checkbox"
                  checked={L2Check}
                  disabled={checksDisable}
                  onChange={() => handleChecked("L2")}
                  className="scale-150"
                />
              </div>
              <div
                className={L3view ? "flex-row text-center text-3xl" : "hidden"}
              >
                <p>{L3}</p>
                <input
                  type="checkbox"
                  checked={L3Check}
                  disabled={checksDisable}
                  onChange={() => handleChecked("L3")}
                  className="scale-150"
                />
              </div>
              <div
                className={L4view ? "flex-row text-center text-3xl" : "hidden"}
              >
                <p>{L4}</p>
                <input
                  type="checkbox"
                  checked={L4Check}
                  disabled={checksDisable}
                  onChange={() => handleChecked("L4")}
                  className="scale-150"
                />
              </div>
              <div
                className={L5view ? "flex-row text-center text-3xl" : "hidden"}
              >
                <p>{L5}</p>
                <input
                  type="checkbox"
                  checked={L5Check}
                  disabled={checksDisable}
                  onChange={() => handleChecked("L5")}
                  className="scale-150"
                />
              </div>
              <div
                className={L6view ? "flex-row text-center text-3xl" : "hidden"}
              >
                <p>{L6}</p>
                <input
                  type="checkbox"
                  checked={L6Check}
                  disabled={checksDisable}
                  onChange={() => handleChecked("L6")}
                  className="scale-150"
                />
              </div>
              <div
                className={L7view ? "flex-row text-center text-3xl" : "hidden"}
              >
                <p>{L7}</p>
                <input
                  type="checkbox"
                  checked={L7Check}
                  disabled={checksDisable}
                  onChange={() => handleChecked("L7")}
                  className="scale-150"
                />
              </div>
              <div
                className={L8view ? "flex-row text-center text-3xl" : "hidden"}
              >
                <p>{L8}</p>
                <input
                  type="checkbox"
                  checked={L8Check}
                  disabled={checksDisable}
                  onChange={() => handleChecked("L8")}
                  className="scale-150"
                />
              </div>
              <div
                className={L9view ? "flex-row text-center text-3xl" : "hidden"}
              >
                <p>{L9}</p>
                <input
                  type="checkbox"
                  checked={L9Check}
                  disabled={checksDisable}
                  onChange={() => handleChecked("L9")}
                  className="scale-150"
                />
              </div>
            </div>
            {/* Verified Stamp */}
            <Transition
              show={checksDisable}
              enter="transition ease-in-out duration-700 transform"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in-out duration-700 transform"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute z-50 -top-2 ml-[5%] w-[90%] -rotate-6"
            >
              <img src="./images/verifiedStamp.png" alt="" />
            </Transition>
          </div>

          {/* Verification img import */}
          {/* Container  */}
          <div className="flex-row py-2">
            {/* Image loaded */}

            <div className={verificationCase}>
              <div className="flex-row">
                <h4
                  className={
                    verificationMove
                      ? "w-[125px] BNP-LableMove -translate-y-[10px]"
                      : "w-[250px] BNP-LableStart py-[4px] translate-y-1 bg-opacity-0"
                  }
                >
                  <div className="flex">
                    {verificationFileWording}
                    <div
                      className={
                        verificationMove
                          ? "hidden"
                          : "font-semibold pl-1 text-red-700"
                      }
                    >
                      *
                    </div>
                  </div>
                </h4>

                <div
                  onClick={() => {
                    document.getElementById("VerificationClick")?.click();
                    L1Ref.current.focus();
                  }}
                  className="flex justify-center w-100 h-[180px]"
                >
                  <img
                    src={verificationDoc}
                    alt=""
                    className={uploadImgView ? "hidden" : ""}
                  />
                  <img
                    src="./images/uploadImg.png"
                    alt=""
                    className={
                      uploadImgView
                        ? "scale-75 text-BNPblue opacity-50"
                        : "hidden"
                    }
                  />
                  {imgLoading && <CircularProgress />}
                </div>
              </div>
            </div>

            {/* container */}
            <div className="flex justify-between mt-2">
              <div className="flex">
                {/* File name */}
                <p className="ml-1 mr-3">File name:</p>

                {/* file name or Error message */}
                <div>
                  <p className={verificationImgError ? "hidden" : ""}>
                    {verificationFile?.name}
                  </p>
                  {/*  Error Message */}
                  <Transition
                    show={verificationImgError}
                    enter="transition ease-in-out duration-500 transform"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in-out duration-500 transform"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="ml-3 font-semibold text-red-600 mr-1 w-[300px]"
                  >
                    {verificationErrorMsg}
                  </Transition>
                </div>
              </div>
              <div
                disabled={checksDisable}
                className={
                  checksDisable
                    ? "BNP-SubmitButtons rounded-full scale-90 opacity-50 hover:cursor-default hover:bg-BNPgoldLight hover:text-current hover:border-current hover:shadow-none"
                    : "BNP-SubmitButtons rounded-full scale-90"
                }
              >
                <biIcons.BiSolidCamera
                  onClick={() => cameraClick()}
                  className="h-10 w-10 p-1"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Extra items on page but hidden/functions */}
        {/* Base border */}
        <div className="flex border-b-2 border-BNPblue opacity-70 pt-3"></div>
        {/* Modal verify Letters */}
        <Transition
          show={verifiedStamp}
          enter="transition ease-in-out duration-700 transform"
          enterFrom="-translate-y-[50%] opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transition ease-in-out duration-700 transform"
          leaveFrom="translate-y-0 opacity-100"
          leaveTo="-translate-y-[50%] opacity-0"
          className="absolute flex justify-center items-center top-0 left-0 h-[100%] w-100 bg-white bg-opacity-80"
        >
          <div className="flex-row justify-center text-center p-4 border-2 border-BNPblue rounded-md shadow-md shadow-BNPblue bg-BNPgoldLightLight">
            <aiIcons.AiOutlineClose
              className={
                yesActive
                  ? "m-2 mb-3 rounded-full focus:outline-none transition-all duration-500 scale-[2.2] hover:font-semibold hover:scale-[2.9] hover:rotate-90 hover:border-2 hover:rounded-full hover:border-BNPblue cursor-pointer hover:transition-all hover:duration-700"
                  : "hidden"
              }
              onClick={handleNoClick}
            />

            <p className={yesActive ? "hidden" : "text-lg"}>
              You are about to mark the new Number Plate as correct
            </p>
            <p
              className={
                yesActive
                  ? "hidden"
                  : "font-semibold text-3xl mt-2 animate-pulse duration-100"
              }
            >
              Are you sure!
            </p>
            <p
              className={
                yesActive
                  ? "w-[350px] font-semibold text-3xl my-2 text-red-600 "
                  : "hidden"
              }
            >
              You need to first upload an image to validate against!
            </p>
            <div className="flex justify-center">
              <div className="px-5 pt-5">
                <button
                  onClick={handleYesClick}
                  className={
                    yesActive
                      ? "hidden"
                      : "BNP-SubmitButtonsBlue w-28 mx-2 text-xl"
                  }
                >
                  Yes
                </button>

                <button
                  onClick={handleNoClick}
                  className={
                    yesActive
                      ? "hidden"
                      : "BNP-SubmitButtonsBlue w-28 mx-2 text-xl"
                  }
                >
                  <p>No</p>
                </button>
              </div>
            </div>
          </div>
        </Transition>
        {/* Image Import click */}
        <input
          id="VerificationClick"
          type="file"
          onChange={ChangeVerificationfile}
          accept="image/*"
          disabled={checksDisable}
          className="hidden"
        />
      </div>
    </>
  );
}
