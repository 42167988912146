import { createContext, useState } from "react";

// Context
const ModalContext = createContext();

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
export function ModalProvider({ children }) {
  // State

  const testing = 0; // 0 = Live; 1 = Testing

  const [cookieModal, setCookieModal] = useState(false);
  const [cookieModalDelay, setCookieModalDelay] = useState(false);
  const [dark, setDark] = useState(false);
  const [loginModal, setloginModal] = useState(false);
  const [sidebarSize, setSidebarSize] = useState(null);
  const [logoutDropDown, setLogoutDropDown] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [pwResteLock, setPwRestLock] = useState(false);
  const [provUpdate, setProvUpdate] = useState(false);
  const [IDTypeUpdate, setIDTypeUpdate] = useState(false);

  const [TsAndCsButton, setTsAndCsButton] = useState(true);
  const [TsAndCsSaleButton, setTsAndCsSaleButton] = useState(false);

  const [salesModalPrevOpen, setSalesModalPrevOpen] = useState(false);
  const [prevType, setPrevType] = useState("Nothing");
  const [prevDoc, setPrevDoc] = useState(null);

  const [deliveryMethodOpen, setdeliveryMethodOpen] = useState(false);

  const [deliveryAddressOpen, setDeliveryAddressOpen] = useState(false);
  const [provInValid, setProvInValid] = useState(false);

  const [ordersModalOpen, setOrdersModalOpen] = useState(false);

  const [provDdOpen, setProvDdOpen] = useState(false);
  const [IdTypeDdOpen, setIdTypeDdOpen] = useState(false);
  const [IDTypeInValid, setIDTypeInValid] = useState(false);

  const [msgOpen, setMsgOpen] = useState(false); // Open Error message modal
  const [msgHeader, setMsgHeader] = useState(""); // Header Error message
  const [msgTxt, setMsgTxt] = useState(""); // Main Error message
  const [msgType, setMsgType] = useState(""); // Message type "Success, Error, Help, Info"
  const [linkMsg, setLinkMsg] = useState(""); // link Message
  const [navLink, setNavLink] = useState(""); // link navigation
  const [navLinkTxt, setNavLinkTxt] = useState(""); // link text

  const [trackingVisible, setTrackingVisible] = useState(false);
  const [trackingModalOpen, setTrackingModalOpen] = useState(false);
  const [trackingModalImgPreview, setTrackingModalImgPreview] = useState(false);

  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

  const [salePayFastOpen, setSalePayFastOpen] = useState(false);
  const [salePayShapOpen, setSalePayShapOpen] = useState(false);
  const [saleYocoCashOpen, setSaleYocoCashOpen] = useState(false);
  const [salePaymentCheckLoginOpen, setSalePaymentCheckLoginOpen] =
    useState(false);
  const [trackingRefresh, setTrackingRefresh] = useState(false);
  const [openPaymentTypes, setOpenPaymentTypes] = useState(false);

  // Create account
  const [userCreateClick, setUserCreateClick] = useState(false);
  const [userInfoCompleted, setUserInfoCompleted] = useState(false);
  const [addressCompleted, setAddressCompleted] = useState(false);
  const [userAccClientInfo, setUserAccClientInfo] = useState([]);
  const [userAccAddress, setUserAccAddress] = useState([]);

  // FAQ links
  const [faq3show, setFAQ3show] = useState(false);
  const [faqBackShow, setFAQbackShow] = useState(false);

  // Payment complete or Change
  const [salePaymentChange, setSalePaymentChange] = useState(false);
  const [saleOrderNo, setSaleOrderNo] = useState("");
  const [salePaymentType, setSalePaymentType] = useState("");

  const msgClean = () => {
    setMsgHeader("");
    setMsgTxt("");
    setMsgType("");
    setLinkMsg("");
    setNavLink("");
    setNavLinkTxt("");
  };

  const closeModals = () => {
    setCookieModal(false);
    setCookieModalDelay(false);
    setloginModal(false);
    setLogoutDropDown(false);
    setSalesModalPrevOpen(false);
    setdeliveryMethodOpen(false);
    setDeliveryAddressOpen(false);
    setOrdersModalOpen(false);
    setProvDdOpen(false);
    setIdTypeDdOpen(false);
    setTrackingVisible(false);
    setTrackingModalOpen(false);
    setInvoiceModalOpen(false);
    setSalePayFastOpen(false);
    setSalePayShapOpen(false);
    setSaleYocoCashOpen(false);
    setSalePaymentCheckLoginOpen(false);
    setFAQ3show(false);
    setTrackingModalImgPreview(false);
    setIDTypeInValid(false);
    setProvInValid(false);

    msgClean();
    setPrevType("Nothing");
    setPrevDoc(null);
    setTrackingRefresh(false);
    setOpenPaymentTypes(false);
    setUserCreateClick(false);
    setUserInfoCompleted(false);
    setAddressCompleted(false);
    setFAQbackShow(false);
    setSalePaymentChange(false);
    setPwRestLock(false);
    setSalePaymentType("");
    setSaleOrderNo("");
    setTsAndCsSaleButton(false);
    setTsAndCsButton(false);
    setUserAccClientInfo([]);
    setUserAccClientInfo([]);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <ModalContext.Provider
      value={{
        testing,

        closeModals,

        cookieModal,
        setCookieModal,
        cookieModalDelay,
        setCookieModalDelay,
        loginModal,
        setloginModal,
        sidebarSize,
        setSidebarSize,
        TsAndCsButton,
        setTsAndCsButton,
        TsAndCsSaleButton,
        setTsAndCsSaleButton,
        dark,
        setDark,
        pwResteLock,
        setPwRestLock,
        provUpdate,
        setProvUpdate,
        IDTypeUpdate,
        setIDTypeUpdate,

        logoutDropDown,
        setLogoutDropDown,
        currentPage,
        setCurrentPage,

        // ID/Pasport open modal
        IdTypeDdOpen,
        setIdTypeDdOpen,
        IDTypeInValid,
        setIDTypeInValid,

        // Sale Img Prev
        salesModalPrevOpen,
        setSalesModalPrevOpen,
        prevType,
        setPrevType,
        prevDoc,
        setPrevDoc,

        // Delivery Method Open
        deliveryMethodOpen,
        setdeliveryMethodOpen,

        // Delivery address modal open
        deliveryAddressOpen,
        setDeliveryAddressOpen,
        provInValid,
        setProvInValid,

        // Orders Modal Open
        ordersModalOpen,
        setOrdersModalOpen,

        // Province DropDown
        provDdOpen,
        setProvDdOpen,

        // Error message open
        msgClean,
        msgOpen,
        setMsgOpen,
        msgHeader,
        setMsgHeader,
        msgTxt,
        setMsgTxt,
        msgType,
        setMsgType,
        linkMsg,
        setLinkMsg,
        navLink,
        setNavLink,
        navLinkTxt,
        setNavLinkTxt,

        // Tracking Details Modal
        trackingVisible,
        setTrackingVisible,
        trackingModalOpen,
        setTrackingModalOpen,
        trackingModalImgPreview,
        setTrackingModalImgPreview,

        // Invoice
        invoiceModalOpen,
        setInvoiceModalOpen,

        // Sales modals
        salePayShapOpen,
        setSalePayShapOpen,
        saleYocoCashOpen,
        setSaleYocoCashOpen,
        salePayFastOpen,
        setSalePayFastOpen,
        salePaymentCheckLoginOpen,
        setSalePaymentCheckLoginOpen,

        salePaymentChange,
        setSalePaymentChange,
        saleOrderNo,
        setSaleOrderNo,
        salePaymentType,
        setSalePaymentType,

        trackingRefresh,
        setTrackingRefresh,
        openPaymentTypes,
        setOpenPaymentTypes,

        faq3show,
        setFAQ3show,
        faqBackShow,
        setFAQbackShow,

        // Create new user
        userCreateClick,
        setUserCreateClick,
        userInfoCompleted,
        setUserInfoCompleted,
        addressCompleted,
        setAddressCompleted,
        userAccClientInfo,
        setUserAccClientInfo,
        userAccAddress,
        setUserAccAddress,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export default ModalContext;
