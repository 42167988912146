import { useContext, useEffect, useState } from "react";

// Context
import modalContext from "../context/ModalContext";
import AdminContext from "../context/AdminContext";

// formating
import formatPhoneNumber from "../utilities/formatPhoneNumber";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function AdminSalesItems(props) {
  // Context
  const { setOrdersModalOpen } = useContext(modalContext);
  const { setSelectOrderNo, setOrderStatus } = useContext(AdminContext);

  // State
  const [paidCase, setPaidCase] = useState("");
  const [typeCase, setTypeCase] = useState(false);

  useEffect(() => {
    if (props.paid === "No") {
      setPaidCase(
        "text-red-500 font-bold animate-pulse duration-100 border-4 rounded-full p-1 border-red-500 "
      );
    } else {
      setPaidCase("");
    }
    if (props.SaleType === "Plate") {
      setTypeCase(true);
    } else {
      setTypeCase(false);
    }
  }, [props.paid, props.SaleType]);

  const handleClick = () => {
    setSelectOrderNo(props.id);
    setOrdersModalOpen(true);
    setOrderStatus(false);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div onClick={handleClick} className=" text-BNPblue">
        <div className="flex justify-between font-Montserrat p-4 bg-white">
          {/* Column 1 */}
          <div className="flex-row w-[460px] items-center pr-6">
            {/* Order Number */}
            <div className="flex justify-between items-center">
              <p className="font-semibold text-lg">Order number:</p>
              {props.id}
            </div>
            {/* Sales Date */}
            <div className="flex justify-between items-center">
              <p className="font-semibold text-lg mr-10 ">Sales date:</p>
              <p> {props.SalesDate} </p>
            </div>
            {/* Sales Type and Volume */}
            <div className="flex justify-between items-center space-x-10">
              <div className="flex justify-between items-center w-[50%]">
                <p className="font-semibold text-lg mr-10 ">Type:</p>
                <p className={typeCase ? "font-semibold text-red-700" : ""}>
                  {props.SaleType}
                </p>
              </div>
              <div className="flex justify-between items-center w-[50%]">
                <p className="font-semibold text-lg mr-10 ">Items:</p>
                <p> {props.SaleVol} </p>
              </div>
            </div>

            {/* Payment Method */}
            <div className="flex justify-between items-center">
              <p className="font-semibold text-lg mr-10 ">Payment Type:</p>
              <p> {props.paymentType} </p>
            </div>
            {/* Paid */}
            <div className="flex justify-between items-center">
              <p className="font-semibold text-lg mr-10 ">Paid:</p>
              <p className={paidCase}>{props.paid}</p>
            </div>
          </div>

          {/* Column 2 */}

          <div className="flex-row w-[350px] items-center">
            {/* Address Info */}
            <div className="flex items-center">
              <p className="font-semibold text-lg mr-10 ">Address Info:</p>
            </div>

            <div className="flex-row"> {props.Province} </div>
            <div className="flex-row"> {props.City} </div>
            <div className="flex-row"> {props.Suburb} </div>
          </div>

          {/* Column 3 */}
          <div className="flex-row w-[400px] items-center">
            {/* Client Info */}
            <div className="flex items-center">
              <p className="font-semibold text-lg mr-10 ">Client Info:</p>
            </div>
            <p>{props.bussinesName}</p>
            <p>{props.DeliveryName}</p>
            <p>{formatPhoneNumber(props.RecipientCell)}</p>
          </div>

          <div className="flex-row"> </div>
          <div className="flex-row"> </div>
        </div>
      </div>
    </>
  );
}
