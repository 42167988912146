import React, { useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";

// React Icons
import * as aiIcons from "react-icons/ai";

// Utilities
import formatPhoneNumber from "../utilities/formatPhoneNumber";
import formatCurrency from "../utilities/formatCurrency";

// Pages
import TrackingItemsModalInfo from "./TrackingItemsModalInfo";
import TrackingItemsModalProducts from "./TrackingItemsModalProducts";
import SalesPaymentTypes from "./SalesPaymentTypes";

// Context
import modalContext from "../context/ModalContext";
import adminContext from "../context/AdminContext";

// import productData from "../data/SalesProductrData.json"; // Temp till DB in place to use context

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function TrackingItemsModal() {
  // console.log("Render TrackingItemsModal");

  // Context
  const {
    setTrackingModalOpen,
    setInvoiceModalOpen,
    salePaymentChange,
    openPaymentTypes,
    setOpenPaymentTypes,
  } = useContext(modalContext);
  const {
    trackingSalesOrderInfo,
    trackingSalesProductData,
  } = useContext(adminContext);

  // state
  const [itemVol, setItemVol] = useState(0);
  const [netAmt, setNetAmt] = useState(0);
  const [discPerc, setDiscPerc] = useState(0);
  const [discAmt, setDiscAmt] = useState(0);
  const [sumNetAmt, setSumNetAmt] = useState(0);
  const [deliveryAmt, setDeliveryAmt] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);

  // Close
  const closeOutSide = (e) => {
    // console.log(e.target.id);
    if (e.target.id === "Orders_Close") closeClick();
  };

  const closeClick = () => {
    setTrackingModalOpen(false);
    setOpenPaymentTypes(false);
  };

  useEffect(() => {
    try {
      setItemVol(trackingSalesOrderInfo[0]?.Item_Vol);
      setNetAmt(trackingSalesOrderInfo[0]?.Net_Amt);
      setDiscPerc(trackingSalesOrderInfo[0]?.Disc_Perc);
      setDiscAmt(trackingSalesOrderInfo[0]?.Disc_Amt);
      setSumNetAmt(trackingSalesOrderInfo[0]?.SUM_Net_Amt);
      setDeliveryAmt(trackingSalesOrderInfo[0]?.Delivery_Amt);
      setTotalAmt(trackingSalesOrderInfo[0]?.Total_Amt);
    } catch (error) {}
  }, [trackingSalesOrderInfo]);

  // console.log(trackingSalesOrderInfo)
  // console.log(trackingSalesProductData)
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div
        onClick={closeOutSide}
        className="flex justify-center max-h-full font-Montserrat overflow-y-scroll no-scrollbar overflow-x-hidden text-BNPblue"
      >
        <div id="Orders_Close" className="flex-row pt-[2%] pb-100 px-100 ">
          {/* Header */}
          <div className="flex-row  w-auto h-[75px] text-BNPgoldLight bg-BNPblue shadow-md shadow-BNPblue rounded-t-md">
            <div className="flex justify-start items-start pt-5 pl-5">
              <aiIcons.AiOutlineClose
                className="rounded-full focus:outline-none transition-all duration-500 scale-[2.2] hover:font-semibold hover:scale-[2.9] hover:rotate-90
                  hover:border-2 hover:rounded-full hover:border-BNPgoldLight cursor-pointer hover:transition-all hover:duration-700"
                onClick={closeClick}
              />
            </div>

            <div className="flex justify-center items-center -mt-3 mb-5 text-3xl font-semibold">
              <h1>Order details</h1>
            </div>
          </div>

          {/* Body */}
          <div className="flex-row p-4 bg-BNPgoldLightLight text-BNPblue shadow-md shadow-BNPblue space-y-4 rounded-b-md">
            {trackingSalesOrderInfo?.map((oInfo) => (
              <div key={oInfo.Order_No}>
                {/* Top grid */}
                <div className="flex-row lg:flex space-y-2 lg:space-y-0 lg:space-x-4 space-x-0">
                  {/* Column 1 Orders info */}
                  <div className="flex justify-center">
                    <TrackingItemsModalInfo
                      Order_No={oInfo.Order_No}
                      Inv_No={oInfo.Inv_No}
                      SalesDate={oInfo.SalesDate}
                      paymentType={oInfo.paymentType}
                      paid={oInfo.paid}
                      Total_Amt={oInfo.Total_Amt}
                    />
                  </div>
                  {/* Cient Info */}

                  {/* Column 2 */}
                  <div className="flex justify-center">
                    <div className="flex-row w-[300px] items-center bg-white p-3 rounded-md">
                      {/* Client Info */}
                      <div className="flex items-center justify-between">
                        <p className="font-semibold text-lg mr-10 ">
                          Delivery Info:
                        </p>
                      </div>

                      <p> Contact: {oInfo.DeliveryName} </p>
                      <p> Tel: {formatPhoneNumber(oInfo.RecipientsCell)}</p>
                    </div>
                  </div>

                  {/* Column 3 */}
                  <div className="flex justify-center">
                    <div className="flex-row w-[300px] items-center bg-white p-3 rounded-md">
                      {/* Address Info */}
                      <div className="flex items-center justify-between">
                        <p className="font-semibold text-lg mr-10 ">
                          Delivery Address:
                        </p>
                      </div>

                      <div className="flex-row"> {oInfo.bussinesName} </div>
                      <div className="flex-row"> {oInfo.ComplexName} </div>
                      <div className="flex-row"> {oInfo.Address} </div>
                      <div className="flex-row"> {oInfo.Suburb} </div>
                      <div className="flex-row"> {oInfo.City} </div>
                      <div className="flex-row"> {oInfo.Province} </div>
                      <div className="flex-row"> {oInfo.PostalCode} </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Payment change complete*/}
            <div className="flex justify-center">
              <Transition
                show={salePaymentChange}
                enter="transition ease-in-out duration-700 transform"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in-out duration-700 transform"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="flex justify-center BNP-SubmitButtons ">
                  <button
                    onClick={() => setOpenPaymentTypes(!openPaymentTypes)}
                  >
                    <div className="flex-row lg:flex">
                      <div className="underline">Complete payment</div>
                      <div className="mx-1"> OR </div>
                      <div className="underline">Change payment method</div>
                    </div>
                  </button>
                </div>
              </Transition>
            </div>

            <div
              className={
                openPaymentTypes
                  ? "transition-height duration-700 ease-in-out s1020:h-[230px] h-[630px] bg-white rounded-lg mx-10" // Show
                  : "transition-height duration-700 ease-in-out h-0 opacity-0" // Hidden
              }
            >
              {/* Payment types */}
              <div className="flex justify-center">
                <Transition
                  show={openPaymentTypes}
                  enter="transition ease-in-out duration-700 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-700 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <SalesPaymentTypes />
                </Transition>
              </div>
            </div>
            {/* Products container */}
            <div className="flex-row lg:flex">
              <div className="flex-row space-y-2">
                {/*  Product Items */}
                {trackingSalesProductData?.map((pInfo) => (
                  <div key={pInfo.ID}>
                    <TrackingItemsModalProducts
                      Order_No={pInfo.Order_No}
                      prodType={pInfo.prodType}
                      quant={pInfo.quant}
                      size={pInfo.size}
                      reg={pInfo.reg}
                      province={pInfo.province}
                      price={pInfo.price}
                      IDimg={pInfo.IDfile}
                      Regimg={pInfo.Regfile}
                      Selfimg={pInfo.Selfie}
                    />
                  </div>
                ))}
              </div>
              {/* sales SubTotal */}
              <div className="w-100">
                <div className="flex justify-center">
                  <div className="flex-row text-center p-3 mt-2 lg:mx-2 lg:mt-0 lg:w-[90%] w-100 border-2 text-BNPblue border-BNPblue bg-BNPgoldLightLight rounded-md bg-opacity-50">
                    <p className="flex justify-center font-semibold border-b-2 border-BNPblue border-opacity-40">
                      Order Summary
                    </p>

                    {/* Product sum Amt */}
                    <div className="flex w-auto justify-between pt-3 mr-[5px]">
                      <p className="flex justify-start w-auto ml-8 mr-10">
                        Items:
                      </p>
                      <p>{itemVol}</p>
                      <div className="flex justify-end w-1/2">
                        R {formatCurrency(netAmt)}
                      </div>
                    </div>

                    <div className="flex w-auto justify-between pt-1 mr-[5px]">
                      <p className="flex justify-start w-auto ml-8 -mr-5">
                        Discount:
                      </p>
                      <p>{discPerc}%</p>
                      <div className="flex justify-end w-1/3">
                        R {formatCurrency(discAmt)}
                      </div>
                    </div>

                    <div className="flex justify-end">
                      <div className="border-t-2 border-BNPblue border-opacity-40 w-[40%]"></div>
                    </div>
                    <div className="flex w-auto justify-end pt-1 mr-[5px] font-semibold">
                      R {formatCurrency(sumNetAmt)}
                    </div>

                    <div className="flex w-auto justify-between py-1 mr-[5px]">
                      <p className="flex justify-start w-auto ml-8 mr-10">
                        Delivery:
                      </p>
                      <div className="flex justify-end w-1/2">
                        R {formatCurrency(deliveryAmt)}
                      </div>
                    </div>

                    <div className="flex justify-between  w-auto font-semibold text-lg tracking-wider border-b-2 border-BNPblue border-opacity-40">
                      <p className="flex justify-start w-auto ml-5">Total:</p>
                      <div className="flex justify-start w-auto ">
                        R {formatCurrency(totalAmt)}
                      </div>
                    </div>

                    <div className="flex justify-end items-center p-2">
                      <p className="text-xl">View:</p>
                      <p
                        onClick={() => {
                          // Validate JWT accessToken
                          document.getElementById("validateClick")?.click();
                          setInvoiceModalOpen(true);
                          setTrackingModalOpen(false);
                        }}
                        className="text-xl w-[100px] BNP-Links p-0"
                      >
                        Invoice
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
