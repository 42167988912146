import React, { useRef, useState, useContext, useEffect } from "react";
import { Transition } from "@headlessui/react";

import formatPhoneNumber from "../utilities/formatPhoneNumber";
import formatTextNumber from "../utilities/formatTextNumber";
import formatProperCase from "../utilities/formatProperCase";
import fromatRemoveFunnies from "../utilities/fromatRemoveFunnies";

// pages
import ProvincePage from "./LookupProvince";

// Context
import AddressContext from "../context/UserContext";
import ModalContext from "../context/ModalContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function AddressPage() {
  // export default function AddressPage({ BussinessName }) {
  // console.log("Render AddressPage");

  // Context
  const {
    newUser,
    setNewUser,
    BusCheck,
    DeliveryName,
    setDeliveryName,
    RecipientsCell,
    setRecipientsCell,
    bussinesName,
    setBussinesName,
    ComplexName,
    setComplexName,
    Address,
    setAddress,
    Suburb,
    setSuburb,
    City,
    setCity,
    Province,
    PostalCode,
    setPostalCode,
  } = useContext(AddressContext);

  const { userCreateClick, setAddressCompleted, setProvInValid } =
    useContext(ModalContext);

  // state
  const DeliveryNameRef = useRef();
  const [DeliveryNameMove, setDeliveryNameMove] = useState(false);

  const RecipientsCellRef = useRef();
  const [RecipientsCellMove, setRecipientsCellMove] = useState(false);

  const bussinesNameRef = useRef();
  const [bussinesNameMove, setbussinesNameMove] = useState(false);

  const ComplexNameRef = useRef();
  const [ComplexNameMove, setComplexNameMove] = useState(false);

  const AddressRef = useRef();
  const [AddressMove, setAddressMove] = useState(false);

  const SuburbRef = useRef();
  const [SuburbMove, setSuburbMove] = useState(false);

  const CityRef = useRef();
  const [CityMove, setCityMove] = useState(false);

  const PostalCodeRef = useRef();
  const [PostalCodeMove, setPostalCodeMove] = useState(false);

  const [recipientFlag, setRecipientFlag] = useState(false);
  const [cellFlag, setCellFlag] = useState(false);
  const [busNameFlag, setBusNameFlag] = useState(false);
  const [streetFlag, setStreetFlag] = useState(false);
  const [subFlag, setSubFlag] = useState(false);
  const [cityFlag, setCityFlag] = useState(false);
  const [postCodeFlag, setPostCodeFlag] = useState(false);

  const effectRanOnce = useRef(false);

  useEffect(() => {
    let CheckCnt = 0;

    if (DeliveryName.length === 0 && userCreateClick === true) {
      CheckCnt = CheckCnt + 1;
      setRecipientFlag(true);
    } else {
      setRecipientFlag(false);
    }

    if (RecipientsCell.length === 0 && userCreateClick === true) {
      CheckCnt = CheckCnt + 1;
      setCellFlag(true);
    } else {
      setCellFlag(false);
    }

    if (
      bussinesName.length === 0 &&
      BusCheck === true &&
      userCreateClick === true
    ) {
      CheckCnt = CheckCnt + 1;
      setBusNameFlag(true);
    } else {
      setBusNameFlag(false);
    }

    if (Address.length === 0 && userCreateClick === true) {
      CheckCnt = CheckCnt + 1;
      setStreetFlag(true);
    } else {
      setStreetFlag(false);
    }

    if (Suburb.length === 0 && userCreateClick === true) {
      CheckCnt = CheckCnt + 1;
      setSubFlag(true);
    } else {
      setSubFlag(false);
    }

    if (City.length === 0 && userCreateClick === true) {
      CheckCnt = CheckCnt + 1;
      setCityFlag(true);
    } else {
      setCityFlag(false);
    }

    if (Province === "" && userCreateClick === true) {
      CheckCnt = CheckCnt + 1;
      setProvInValid(true);
    } else {
      setProvInValid(false);
    }

    if (PostalCode.length === 0 && userCreateClick === true) {
      CheckCnt = CheckCnt + 1;
      setPostCodeFlag(true);
    } else {
      setPostCodeFlag(false);
    }

    // Flag missing
    if (CheckCnt === 0) {
      setAddressCompleted(true);
    } else {
      setAddressCompleted(false);
    }
    // console.log(CheckCnt);
  }, [
    DeliveryName,
    Address,
    BusCheck,
    City,
    PostalCode,
    Province,
    RecipientsCell,
    Suburb,
    bussinesName,
    setAddressCompleted,
    setProvInValid,
    userCreateClick,
  ]);

  useEffect(() => {
    if (DeliveryName.length !== 0) {
      setDeliveryNameMove(true);
    }
    if (RecipientsCell.length !== 0) {
      setRecipientsCellMove(true);
    }
    if (bussinesName.length !== 0) {
      setbussinesNameMove(true);
    }
    if (ComplexName.length !== 0) {
      setComplexNameMove(true);
    }
    if (Address.length !== 0) {
      setAddressMove(true);
    }
    if (Suburb.length !== 0) {
      setSuburbMove(true);
    }
    if (City.length !== 0) {
      setCityMove(true);
    }
    if (PostalCode.length !== 0) {
      setPostalCodeMove(true);
    }

    // Refresh already captured info it clicked away
    if (effectRanOnce.current === false) {
      if (newUser.find((data) => data.Recipient_Name !== "abc")) {
        setDeliveryName(newUser[0].Recipient_Name);
      }
      if (newUser.find((data) => data.Recipient_Cell !== "abc")) {
        setRecipientsCell(newUser[0].Recipient_Cell);
      }
      if (newUser.find((data) => data.DeliveryBusiness_Name !== "abc")) {
        setBussinesName(newUser[0].DeliveryBusiness_Name);
      }
      if (newUser.find((data) => data.Complex_Building !== "abc")) {
        setComplexName(newUser[0].Complex_Building);
      }
      if (newUser.find((data) => data.Street_Address !== "abc")) {
        setAddress(newUser[0].Street_Address);
      }
      if (newUser.find((data) => data.Suburb !== "abc")) {
        setSuburb(newUser[0].Suburb);
      }
      if (newUser.find((data) => data.City !== "abc")) {
        setCity(newUser[0].City);
      }
      if (newUser.find((data) => data.Postal_Code !== "abc")) {
        setPostalCode(newUser[0].Postal_Code);
      }
      effectRanOnce.current = true;
      // console.log("Ran address data refresh once only");
    }
  }, [
    BusCheck,
    DeliveryName,
    RecipientsCell,
    bussinesName,
    ComplexName,
    Address,
    Suburb,
    City,
    PostalCode,
    newUser,
    setAddress,
    setBussinesName,
    setCity,
    setComplexName,
    setDeliveryName,
    setPostalCode,
    setRecipientsCell,
    setSuburb,
  ]);

  // DeliveryName
  const DeliveryNameFocusOn = () => {
    setDeliveryNameMove(true);
    DeliveryNameRef.current.focus();
  };

  const DeliveryNameFocusOff = () => {
    if (DeliveryName.length !== 0) {
      setDeliveryNameMove(true);
    } else {
      setDeliveryNameMove(false);
    }
  };

  // Update Array with Recipient_Name
  function ArrayUpdateRecipient_Name(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Recipient_Name: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // RecipientsCell
  const RecipientsCellFocusOn = () => {
    setRecipientsCellMove(true);
    RecipientsCellRef.current.focus();
  };

  const RecipientsCellFocusOff = () => {
    if (RecipientsCell.length !== 0) {
      setRecipientsCellMove(true);
    } else {
      setRecipientsCellMove(false);
    }
  };

  // Update Array with Recipient_Cell
  function ArrayUpdateRecipient_Cell(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Recipient_Cell: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // bussinesName
  const bussinesNameFocusOn = () => {
    setbussinesNameMove(true);
    bussinesNameRef.current.focus();
  };

  const bussinesNameFocusOff = () => {
    if (bussinesName.length !== 0) {
      setbussinesNameMove(true);
    } else {
      setbussinesNameMove(false);
    }
  };

  // Update Array with DeliveryBusiness_Name
  function ArrayUpdateDeliveryBusiness_Name(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            DeliveryBusiness_Name: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // ComplexName
  const ComplexNameFocusOn = () => {
    setComplexNameMove(true);
    ComplexNameRef.current.focus();
  };

  const ComplexNameFocusOff = () => {
    if (ComplexName.length !== 0) {
      setComplexNameMove(true);
    } else {
      setComplexNameMove(false);
    }
  };

  // Update Array with Complex_Building
  function ArrayUpdateComplex_Building(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Complex_Building: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // Address
  const AddressFocusOn = () => {
    setAddressMove(true);
    AddressRef.current.focus();
  };

  const AddressFocusOff = () => {
    if (Address.length !== 0) {
      setAddressMove(true);
    } else {
      setAddressMove(false);
    }
  };

  // Update Array with Street_Address
  function ArrayUpdateStreet_Address(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Street_Address: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // Suburb
  const SuburbFocusOn = () => {
    setSuburbMove(true);
    SuburbRef.current.focus();
  };

  const SuburbFocusOff = () => {
    if (Suburb.length !== 0) {
      setSuburbMove(true);
    } else {
      setSuburbMove(false);
    }
  };

  // Update Array with Suburb
  function ArrayUpdateSuburb(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Suburb: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // City
  const CityFocusOn = () => {
    setCityMove(true);
    CityRef.current.focus();
  };

  const CityFocusOff = () => {
    if (City.length !== 0) {
      setCityMove(true);
    } else {
      setCityMove(false);
    }
  };

  // Update Array with City
  function ArrayUpdateCity(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            City: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // PostalCode
  const PostalCodeFocusOn = () => {
    setPostalCodeMove(true);
    PostalCodeRef.current.focus();
  };

  const PostalCodeFocusOff = () => {
    if (PostalCode.length !== 0) {
      setPostalCodeMove(true);
    } else {
      setPostalCodeMove(false);
    }
  };

  // Update Array with Postal_Code
  function ArrayUpdatePostal_Code(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Postal_Code: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {/* container */}
      <div className="p-2 flex-row font-Montserrat md:p-4 text-BNPblue BNP-Trans300">
        <div className="w-full items-center ">
          {/* Header */}
          <h1 className="pl-4 text-lg font-bold mr-2 md:mr-14 BNP-Trans300">
            Address Details
          </h1>

          {/* Recipients Name */}
          <div className="pl-1 text-md">
            <div className="flex justify-end w-[350px]">
              <p
                onClick={() => {
                  let RepName =
                    newUser[0].Prefered_Name + " " + newUser[0].Surname;
                  if (
                    newUser[0].Prefered_Name === "abc" ||
                    newUser[0].Prefered_Name === "" ||
                    newUser[0].Surname === "abc"
                  ) {
                    // console.log("The Prefered name or Surname is missing");
                    // console.log(newUser[0].Prefered_Name);
                    if (
                      newUser[0].Prefered_Name === "abc" ||
                      newUser[0].Prefered_Name === ""
                    ) {
                      document.getElementById("prefNameCopy")?.click();
                    }
                    RepName = newUser[0].Name + " " + newUser[0].Surname;
                    setDeliveryName(RepName);
                    ArrayUpdateRecipient_Name(RepName);
                    DeliveryNameRef.current.focus();
                  } else {
                    setDeliveryName(RepName);
                    ArrayUpdateRecipient_Name(RepName);
                    DeliveryNameRef.current.focus();
                  }
                }}
                className="p-0 pr-3 BNP-Links"
              >
                copy
              </p>
            </div>
            <div
              onClick={() => DeliveryNameFocusOn()}
              className="group mt-1 h-[40px] BNP-LableDIV cursor-text"
            >
              <input
                value={DeliveryName}
                onChange={(e) => {
                  setDeliveryName(formatProperCase(e.target.value));
                  ArrayUpdateDeliveryBusiness_Name(
                    formatProperCase(e.target.value)
                  );
                }}
                onBlur={() => DeliveryNameFocusOff()}
                type="text"
                ref={DeliveryNameRef}
                autoComplete="off"
                className={
                  recipientFlag
                    ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                    : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                }
              />
              <h4
                className={
                  DeliveryNameMove
                    ? "w-[108px] BNP-LableMove -translate-y-[50px]"
                    : "w-[170px] BNP-LableStart pt-[2px] bg-opacity-0"
                }
              >
                <div className="flex">
                  Recipient Name
                  <div
                    className={
                      DeliveryNameMove
                        ? "hidden"
                        : "font-semibold pl-1 text-red-700"
                    }
                  >
                    *
                  </div>
                </div>
              </h4>
            </div>
          </div>

          {/* Recipients Cell */}
          <div className="pl-1 text-md">
            <div className="flex justify-end w-[200px]">
              <p
                onClick={() => {
                  let RepCell = newUser[0].Cell_No;
                  if (RepCell === "abc") {
                    console.log("The Cell number is missing");
                  } else {
                    setRecipientsCell(formatPhoneNumber(RepCell));
                    ArrayUpdateRecipient_Cell(fromatRemoveFunnies(RepCell));
                    RecipientsCellRef.current.focus();
                  }
                }}
                className="p-0 pr-3 BNP-Links"
              >
                copy
              </p>
            </div>
            <div
              onClick={() => RecipientsCellFocusOn()}
              className="group mt-1 h-[40px] w-[200px] BNP-LableDIV cursor-text"
            >
              <input
                value={RecipientsCell}
                onChange={(e) => {
                  setRecipientsCell(formatPhoneNumber(e.target.value));
                  ArrayUpdateRecipient_Cell(
                    fromatRemoveFunnies(e.target.value)
                  );
                }}
                onBlur={() => RecipientsCellFocusOff()}
                type="text"
                ref={RecipientsCellRef}
                autoComplete="off"
                className={
                  cellFlag
                    ? "w-[200px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                    : "w-[200px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                }
              />
              <h4
                className={
                  RecipientsCellMove
                    ? "w-[93px] BNP-LableMove -translate-y-[50px]"
                    : "w-[150px] BNP-LableStart pt-[2px] bg-opacity-0"
                }
              >
                <div className="flex">
                  Recipient Cell
                  <div
                    className={
                      RecipientsCellMove
                        ? "hidden"
                        : "font-semibold pl-1 text-red-700"
                    }
                  >
                    *
                  </div>
                </div>
              </h4>
            </div>
          </div>

          <div
            className={
              BusCheck
                ? "transition-height duration-500 ease-in-out h-[68px]" // Show
                : "transition-height duration-500 ease-in-out h-[3px] opacity-0" // Hidden
            }
          >
            <Transition
              show={BusCheck}
              enter="transition ease-in-out duration-500 transform"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in-out duration-700 transform"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {/* Business Name */}
              <div className="pl-1 pb-1 text-md">
                <div className="flex justify-end w-[350px]">
                  {/* disable copy on Ind */}
                  {BusCheck === true ? (
                    <p
                      onClick={() => {
                        let RepBuss = newUser[0].Business_Name;
                        if (RepBuss === "abc") {
                          console.log("The Business name is missing");
                        } else {
                          setBussinesName(RepBuss);
                          ArrayUpdateDeliveryBusiness_Name(RepBuss);
                        }
                      }}
                      className="p-0 pr-3 BNP-Links"
                    >
                      copy
                    </p>
                  ) : (
                    <p className="p-0 pr-3 BNP-Links">copy</p>
                  )}
                </div>
                <div
                  onClick={() => bussinesNameFocusOn()}
                  className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
                >
                  {/* disable copy on Ind */}
                  {BusCheck === true ? (
                    <input
                      value={bussinesName}
                      onChange={(e) => {
                        setBussinesName(formatProperCase(e.target.value));
                        ArrayUpdateDeliveryBusiness_Name(
                          formatProperCase(e.target.value)
                        );
                      }}
                      onBlur={() => bussinesNameFocusOff()}
                      type="text"
                      ref={bussinesNameRef}
                      autoComplete="off"
                      className={
                        busNameFlag
                          ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                          : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                      }
                    />
                  ) : (
                    <input
                      value={bussinesName}
                      onChange={(e) => formatProperCase(e.target.value)}
                      type="text"
                      ref={bussinesNameRef}
                      className="w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                    />
                  )}
                  <h4
                    className={
                      bussinesNameMove
                        ? "w-[104px] BNP-LableMove -translate-y-[50px]"
                        : "w-[160px] BNP-LableStart pt-[2px] bg-opacity-0"
                    }
                  >
                    <div className="flex">
                      Business Name
                      <div
                        className={
                          bussinesNameMove
                            ? "hidden"
                            : "font-semibold pl-1 text-red-700"
                        }
                      >
                        *
                      </div>
                    </div>
                  </h4>
                </div>
              </div>
            </Transition>
          </div>

          {/* Building/Complex Name */}
          <div className="p-1 mt-[9px] text-md">
            <div
              onClick={() => ComplexNameFocusOn()}
              className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
            >
              <input
                value={ComplexName}
                onChange={(e) => {
                  setComplexName(formatProperCase(e.target.value));
                  ArrayUpdateComplex_Building(formatProperCase(e.target.value));
                }}
                onBlur={() => ComplexNameFocusOff()}
                type="text"
                ref={ComplexNameRef}
                autoComplete="off"
                className="w-[350px] h-[40px] pl-4 BNP-Input-thin  text-lg text-BNPblue cursor-text"
              />
              <h4
                className={
                  ComplexNameMove
                    ? "w-[160px] BNP-LableMove -translate-y-[50px]"
                    : "w-[345px] BNP-LableStart pt-[3px] bg-opacity-0"
                }
              >
                <div className="flex">Building/Complex Name</div>
              </h4>
            </div>
          </div>
        </div>

        {/* Address*/}
        <div className="p-1 mt-[9px] text-md">
          <div
            onClick={() => AddressFocusOn()}
            className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
          >
            <input
              value={Address}
              onChange={(e) => {
                setAddress(formatProperCase(e.target.value));
                ArrayUpdateStreet_Address(formatProperCase(e.target.value));
              }}
              onBlur={() => AddressFocusOff()}
              type="text"
              ref={AddressRef}
              autoComplete="off"
              className={
                streetFlag
                  ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                  : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
              }
            />
            <h4
              className={
                AddressMove
                  ? "w-[99px] BNP-LableMove -translate-y-[50px]"
                  : "w-[160px] BNP-LableStart pt-[3px] bg-opacity-0"
              }
            >
              <div className="flex">
                Street Address
                <div
                  className={
                    AddressMove ? "hidden" : "font-semibold pl-1 text-red-700"
                  }
                >
                  *
                </div>
              </div>
            </h4>
          </div>
        </div>

        {/* Suburb Name */}
        <div className="p-1 mt-[9px] text-md">
          <div
            onClick={() => SuburbFocusOn()}
            className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
          >
            <input
              value={Suburb}
              onChange={(e) => {
                setSuburb(formatProperCase(e.target.value));
                ArrayUpdateSuburb(formatProperCase(e.target.value));
              }}
              onBlur={() => SuburbFocusOff()}
              type="text"
              ref={SuburbRef}
              autoComplete="off"
              className={
                subFlag
                  ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                  : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
              }
            />
            <h4
              className={
                SuburbMove
                  ? "w-[54px] BNP-LableMove -translate-y-[50px]"
                  : "w-[100px] BNP-LableStart pt-[3px] bg-opacity-0"
              }
            >
              <div className="flex">
                Suburb
                <div
                  className={
                    SuburbMove ? "hidden" : "font-semibold pl-1 text-red-700"
                  }
                >
                  *
                </div>
              </div>
            </h4>
          </div>
        </div>

        {/* City/Town Name */}
        <div className="p-1 mt-[9px] text-md">
          <div
            onClick={() => CityFocusOn()}
            className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
          >
            <input
              value={City}
              onChange={(e) => {
                setCity(formatProperCase(e.target.value));
                ArrayUpdateCity(formatProperCase(e.target.value));
              }}
              onBlur={() => CityFocusOff()}
              type="text"
              ref={CityRef}
              autoComplete="off"
              className={
                cityFlag
                  ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                  : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
              }
            />
            <h4
              className={
                CityMove
                  ? "w-[69px] BNP-LableMove -translate-y-[50px]"
                  : "w-[115px] BNP-LableStart pt-[3px] bg-opacity-0"
              }
            >
              <div className="flex">
                City/Town
                <div
                  className={
                    CityMove ? "hidden" : "font-semibold pl-1 text-red-700"
                  }
                >
                  *
                </div>
              </div>
            </h4>
          </div>
        </div>

        {/* Province Name */}
        <div className="group ">
          <ProvincePage />
        </div>

        {/* Postal Code */}
        <div className="p-1 text-md">
          <div
            onClick={() => PostalCodeFocusOn()}
            className="group w-[150px] h-[40px] -mt-3 BNP-LableDIV cursor-text"
          >
            <input
              value={PostalCode}
              onChange={(e) => {
                setPostalCode(formatTextNumber(e.target.value));
                ArrayUpdatePostal_Code(formatTextNumber(e.target.value));
              }}
              onBlur={() => PostalCodeFocusOff()}
              type="text"
              ref={PostalCodeRef}
              autoComplete="off"
              className={
                postCodeFlag
                  ? "w-[150px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                  : "w-[150px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
              }
            />
            <h4
              className={
                PostalCodeMove
                  ? "w-[78px] BNP-LableMove -translate-y-[50px]"
                  : "w-[125px] BNP-LableStart pt-[3px] bg-opacity-0"
              }
            >
              <div className="flex">
                Postal Code
                <div
                  className={
                    PostalCodeMove
                      ? "hidden"
                      : "font-semibold pl-1 text-red-700"
                  }
                >
                  *
                </div>
              </div>
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}
