import { createContext, useState } from "react";

// Context
const UrlContext = createContext();

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
export function UrlProvider({ children }) {
  // State

  const [userRegister, setRegister] = useState("");
  const [userLogin, setUserLogin] = useState("");
  const [userCookieLogin, setUserCookieLogin] = useState("");
  const [userTokenClear, setUserTokenClear] = useState("");
  const [userToken, setUserToken] = useState("");
  const [userTestAccessToken, setUserTestAccessToken] = useState("");
  const [userLoginTracking, setUserLoginTracking] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userPasswordChange, setUserPasswordChange] = useState("");
  const [userPasswordReset, setUserPasswordReset] = useState("");
  const [userDeliveryAddressUpdate, setUserDeliveryAddressUpdate] =
    useState("");
    const [userAccInfo, setUserAccInfo] = useState("");
     const [userAccInfoUpdate, setUserAccInfoUpdate] = useState("");

  const [callbackInsert, setCallbackInsert] = useState("");

  const [BNPdiscounts, setBNPdiscounts] = useState("");
  const [dealerDiscounts, setDealerDiscounts] = useState("");
  const [salePush, setSalePush] = useState("");
  const [salePushItems, setSalePushItems] = useState("");
  const [salesCreateSlimUCN, setSalesCreateSlimUCN] = useState("");
  const [salesPaymentTypeUpdate, setSalesPaymentTypeUpdate] = useState("");

  const [merchID, setMerchID] = useState("");
  const [merchKey, setMerchKey] = useState("");
  const [passphrase, setPassphrase] = useState("");
  const [payFast, setPayFast] = useState("");
  const [pfReturn, setPfReturn] = useState("");
  const [pfCancel, setPfCancel] = useState("");
  const [pfNotify, setPfNotify] = useState("");

  const [googleImgUpload, setGoogleImgUpload] = useState("");
  const [googleImgGet, setGoogleImgGet] = useState("");

  const [trackingData, setTrackingData] = useState("");
  const [trackingDataProducts, settrackingDataProducts] = useState("");
  const [trackingModal, setTrackingModal] = useState("");

  const [adminSalesSummary, setAdminSalesSummary] = useState("");
  const [updatePaidURL, setUpdatePaidURL] = useState("");
  const [adminSalesProduct, setAdminSalesProduct] = useState("");
  const [updateValidated, setUpdateValidated] = useState("");
  const [updateOrderReady, setUpdateOrderReady] = useState("");

  const [URLsLoaded, setURLsLoaded] = useState("");

  const [PwResetEmail, setPwResetEmail] = useState("");

  const BNPloadURLs = () => {
    let db = "";
    // db = "http://localhost:8081/api"; // Local  // Add when testing // Remove when live
    db = "https://api.bespokenp.co.za/api"; // Live Kamatera  // Remove when testing

    // Vercel - emails apiv
    // let dbVe =     "http://localhost:3002/api"; // Local  // Add when testing // Remove when live
    let dbVe = "https://bespokenp-emails.vercel.app/api"; // Live  // Remove when testing

    ////////// PayFast processing
    let db2 = "https://api.bespokenp.co.za/api"; // Live Kamatera
    // db2 = "https://7358-197-245-114-22.ngrok-free.app/api"; // Ngrok   // Add when testing

    // User info loading to mySQL db
    setRegister(db + "/user/userCreate/"); // User Registration
    setUserLogin(db + "/user/ClientValidate/"); // User login
    setUserCookieLogin(db + "/user/ClientValidateCookie/"); // User login via cookie
    setUserLoginTracking(db + "/user/userLoginTracking/"); // User login tracking
    setUserTokenClear(db + "/user/userLogOut/"); // User Logout
    setUserToken(db + "/user/getNewToken/"); // Refresh access token
    setUserTestAccessToken(db + "/user/testAccessToken/"); // Refresh access token
    setUserAddress(db + "/user/ClientAddress/"); // User Address for sales
    setUserPasswordChange(db + "/user/pwChange"); // User Password Rest
    setUserPasswordReset(db + "/user/pwReset"); // User Password Rest
    setUserDeliveryAddressUpdate(db + "/user/ClientDeliveryAddressUpdate/"); // Update delivery address at Sale time
    setUserAccInfo(db + "/user/ClientAccInfo/"); // User Account Info from DB
    setUserAccInfoUpdate(db + "/user/ClientAccInfoUpdate"); // User Account Info Update

    // Callbacks
    setCallbackInsert(db + "/callback/CallBackInsert/"); // Insert into call back table

    // Sales pages loading to mySQL db
    setBNPdiscounts(db + "/sales/discounts/"); // Available Discounts listing
    setDealerDiscounts(db + "/sales/DealerDiscounts/"); // Dealer Discounts
    setSalePush(db + "/sales/saleOrder/"); // Sale push to Order tbl in db
    setSalePushItems(db + "/sales/saleOrderItems/"); // Sale. push Items to saleItems tbl in db
    setSalesCreateSlimUCN(db + "/sales/saleCreateUserProfile/"); // Sales. Create a Slim UCN to complete sale
    setSalesPaymentTypeUpdate(db + "/sales/salePaymentTypeUpdate/"); // Update Sales Payment Type in db

    // PayFast processing
    setMerchID("10033231"); // testing update to 24098530
    setMerchKey("q86wdu76z665c"); // testing update to umei0euxixqf1
    setPassphrase("BNP_test-2024"); // testing update to BNP$rules#2024
    setPayFast("https://sandbox.payfast.co.za​/eng/process"); //     While Testing
    // setPayFast("https://www.payfast.co.za/eng/process"); //     Go Live

    setPfReturn("https://www.bespokenp.co.za/PaySuccess");
    setPfCancel("https://www.bespokenp.co.za/tracking");
    setPfNotify(db2 + "/PayFastNotify"); // "http://www.bespokenp.co.za/PayFastNotify"

    // Google Images
    setGoogleImgUpload(db + "/google/imgUpload");
    setGoogleImgGet(db + "/google/imgGet/");

    // Tracking pages
    setTrackingData(db + "/tracking/trackingData");
    settrackingDataProducts(db + "/tracking/trackingProducts/");
    setTrackingModal(db + "/tracking/trackingModal/");

    // Admin and Sales processing
    setAdminSalesSummary(db + "/admin/adminSalesSummary");
    setUpdatePaidURL(db + "/admin/adminUpdatePaid/");
    setAdminSalesProduct(db + "/admin/adminSalesProduct/");
    setUpdateValidated(db + "/admin/adminSaleValidated/");
    setUpdateOrderReady(db + "/admin/adminUpdateOrderReady/");

    // Emails
    setPwResetEmail(dbVe + "/PwResetEmail");

    setURLsLoaded("done");
  };

  return (
    <UrlContext.Provider
      value={{
        BNPloadURLs,

        userRegister,
        userLogin,
        userCookieLogin,
        userLoginTracking,
        userTokenClear,
        userToken,
        userTestAccessToken,
        userAddress,
        userPasswordChange,
        userPasswordReset,
        userDeliveryAddressUpdate,
        userAccInfo,
        userAccInfoUpdate,        

        callbackInsert,

        BNPdiscounts,
        dealerDiscounts,
        salePush,
        salePushItems,
        salesCreateSlimUCN,
        salesPaymentTypeUpdate,

        merchID,
        merchKey,
        passphrase,
        payFast,
        pfReturn,
        pfCancel,
        pfNotify,

        googleImgUpload,
        googleImgGet,

        trackingData,
        trackingDataProducts,
        trackingModal,

        adminSalesSummary,
        updatePaidURL,
        adminSalesProduct,
        updateValidated,
        updateOrderReady,

        PwResetEmail,

        URLsLoaded,
      }}
    >
      {children}
    </UrlContext.Provider>
  );
}

export default UrlContext;
