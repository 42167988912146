import React, { useContext, useEffect, useRef, useState } from "react";
import md5 from "md5";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// formating
import formatCurrency from "../utilities/formatCurrency";

// Context
import SalesCartContext from "../context/SalesCartContext";
import UrlContext from "../context/URLContext";
import userContext from "../context/UserContext";
import modalContext from "../context/ModalContext";
import AdminContext from "../context/AdminContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function PayFastPage() {
  // console.log("Render PayFastPage");

  // Context
  const {
    totalAmt,
    invNo,
    emptyCart, // savePF2LocalSales,
  } = useContext(SalesCartContext);
  const { payFastInvTracking, payFastTotalTracking } = useContext(AdminContext);

  const {
    merchID,
    merchKey,
    passphrase,
    payFast,
    pfReturn,
    pfCancel,
    pfNotify,
  } = useContext(UrlContext);
  const { openPaymentTypes, setSalePayFastOpen, testing } =
    useContext(modalContext);

  const {
    // userEmail,
    userID,
    // PrefName,
  } = useContext(userContext);

  // State
  let baseSec = 5;
  const [time, setTime] = useState(0);
  const [msg, setMsg] = useState(time);
  const [progressPerc, setProgressPerc] = useState(0);
  const [pbBackground, setpbBackground] = useState(false);
  const [overRide, setOverRide] = useState(false);
  const [adminTools, setAdminTools] = useState(false);
  const [thinking, setThinking] = useState(false);

  const effectRanLoop = useRef(false);
  const effectRunOnce = useRef(false);

  // BNP details
  let returnURL = pfReturn;
  let cancelURL = pfCancel;
  let notifyURL = pfNotify;
  let MD5Signature = "";

  // Buyers details
  // let firstName = tPrefName;
  // let eMail = tUserEmail;

  // Transaction details
  let paymentID = userID; // Unique payment ID on the merchant’s system - CusteromID
  let amount = formatCurrency(totalAmt).replace(" ", "");
  let payment_name = invNo; // The name of the item being charged for, or in the case of multiple items the order number.
  // let description_if_any = "plates";
  // let custome_integer_value_if_any = "a";
  let custome_string_value_if_any1 = "CustNo:" + userID;
  // let custome_string_value_if_any2 = "c";

  // if Payment is retry
  if (openPaymentTypes === true) {
    returnURL = pfCancel;
    payment_name = payFastInvTracking;
    amount = formatCurrency(payFastTotalTracking).replace(" ", "");
  }

  if (effectRunOnce.current === false) {
    // console.log(paymentID);
    // console.log(payment_name);
    // console.log(amount);
    // console.log(returnURL);

    effectRunOnce.current = true;
  }

  const updatePayFastData = () => {
    // Update Signature info
    const params = new URLSearchParams({
      merchant_id: merchID,
      merchant_key: merchKey,
      return_url: returnURL,
      cancel_url: cancelURL,
      notify_url: notifyURL,
      // name_first: firstName,
      // email_address: eMail,
      m_payment_id: paymentID,
      amount: amount,
      item_name: payment_name,
      // item_description: description_if_any,
      // custom_int1: custome_integer_value_if_any,
      custom_str1: custome_string_value_if_any1,
      // custom_str2: custome_string_value_if_any2,
    });
    // console.log(paymentID)

    // create an md5 signature of it.
    MD5Signature = md5(params.toString());
    // console.log(MD5Signature);
    // console.log(params.toString());
    // savePF2LocalSales(params.toString());

    // Clear out the cart
    if (testing === 0) {
      emptyCart();
    }
  };

  useEffect(() => {
    if (time <= baseSec) {
      if (time <= baseSec - 1 && effectRanLoop.current === false) {
        if (overRide === false) {
          setTimeout(() => setTime(time + 1), 1000);
          setpbBackground(false);
          setMsg(time);
          setAdminTools(false);
        } else {
          effectRanLoop.current = true;
        }
        setProgressPerc(Math.round((time / baseSec) * 100));
      } else {
        effectRanLoop.current = true;
        setProgressPerc(100);
        setMsg("Go");
        setpbBackground(true);
        setAdminTools(true);
        document.getElementById("IDClick2")?.click();
        // Open Page
        if (MD5Signature.length > 0) {
          document.getElementById("IDClick")?.click();
        } else {
          console.log("Signarture not updated!!!! ", MD5Signature);
        }
      }
    }
  }, [
    userID,
    msg,
    adminTools,
    overRide,
    time,
    merchID,
    merchKey,
    passphrase,
    baseSec,
    MD5Signature,
    testing,
  ]);

  const handelReset = () => {
    setTime(0);
    setProgressPerc(0);
    setOverRide(false);
    setpbBackground(false);
    effectRanLoop.current = false;
    updatePayFastData();
    setThinking(false);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="flex justify-center max-h-full font-Montserrat">
        <div className="flex-row pt-[5%] pb-100 px-100">
          {/* Header */}
          <div className="relative flex justify-center items-center w-[500px] h-[70px] text-BNPgoldLight bg-BNPblue shadow-md shadow-BNPblue">
            <div className="text-3xl font-semibold">
              <h1>Order Confirmation.</h1>
            </div>
          </div>
          {/* Body */}
          <div className="flex-row justify-between px-2 py-4 bg-white rounded-b-md text-BNPblue shadow-md shadow-BNPblue">
            <div className="flex-row items-center justify-between  mx-14">
              <div className="flex-row items-center font-bold text-center text-xl pb-2">
                <h1>You will now be redirected to the</h1>
                <div className="font-normal">
                  <h1
                    onClick={() => {
                      setOverRide(true);
                      setThinking(true);
                      setTimeout(baseSec);
                    }}
                    className={
                      thinking
                        ? "BNP-Links -mt-1 hover:cursor-progress"
                        : "BNP-Links -mt-1"
                    }
                  >
                    PayFast payments
                  </h1>
                </div>
                <h1 className="-mt-1">portal to complete the payment!</h1>
              </div>
              <div className="flex justify-center items-center text-lg">
                <p>Payment reference: </p>
                <p className=" font-semibold text-center text-lg ml-3">
                  {payment_name}
                </p>
              </div>
              <form action={payFast} method="POST">
                <input type="hidden" name="merchant_id" value={merchID} />
                <input type="hidden" name="merchant_key" value={merchKey} />
                <input type="hidden" name="return_url" value={returnURL} />
                <input type="hidden" name="cancel_url" value={cancelURL} />
                <input type="hidden" name="notify_url" value={notifyURL} />
                {/* <input type="hidden" name="name_first" value={firstName} />
        <input type="hidden" name="email_address" value={eMail} /> */}
                <input type="hidden" name="m_payment_id" value={paymentID} />
                <input type="hidden" name="amount" value={amount} />
                <input type="hidden" name="item_name" value={payment_name} />
                {/* <input type="hidden" name="item_description" value={description_if_any} />
        <input type="hidden" name="custom_int1" value={custome_integer_value_if_any} />*/}
                <input
                  type="hidden"
                  name="custom_str1"
                  value={custome_string_value_if_any1}
                />
                {/*<input type="hidden" name="custom_str2" value={custome_string_value_if_any2} /> */}

                <input type="hidden" name="signature" value={MD5Signature} />
                <button
                  id="IDClick"
                  onClick={() => updatePayFastData()}
                  className="hidden"
                >
                  PAY NOW!
                </button>
                <div className="flex justify-center text-center text-lg p-3 ">
                  <div className="relative">
                    <div
                      className={
                        pbBackground
                          ? "w-[70px] h-[70px] font-semibold rounded-full bg-green-700"
                          : "w-[70px] h-[70px] font-semibold rounded-full bg-red-700"
                      }
                    >
                      <CircularProgressbar
                        value={progressPerc}
                        strokeWidth={12}
                        styles={buildStyles({
                          pathColor: "#0B60B0",
                          trailColor: "#dbcca4",
                        })}
                      />
                      <div className="absolute top-0 flex w-100 justify-center h-100 items-center text-white -mt-[2px] tracking-wider text-2xl">
                        {msg}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="flex justify-center text-center text-lg mt-3 pt-3 border-t-2 border-BNPblue border-opacity-40 ">
                <p>
                  PayFast will return you back to the BNP site once completed.
                </p>
              </div>
            </div>
            <div className="flex justify-between text-xs h-1">
              <div
                onClick={() => handelReset()}
                className={
                  adminTools
                    ? "transition-height cursor-pointer opacity-40 duration-3000 "
                    : "transition-height cursor-pointer opacity-0 duration-3000"
                }
              >
                .refresh
              </div>
              <button
                id="IDClick2"
                onClick={() => updatePayFastData()}
                className="hidden" /// use this for testing the code
              >
                PAY NOW!
              </button>
              <div
                onClick={() => setSalePayFastOpen(false)}
                className={
                  adminTools
                    ? "transition-height cursor-pointer opacity-40 duration-3000 "
                    : "transition-height cursor-pointer opacity-0 duration-3000"
                }
              >
                .close
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
