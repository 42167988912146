import React, { useContext, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

// Img
import * as riIcons from "react-icons/ri";

// Context
import ModalContext from "../context/ModalContext";

/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
export default function FAQPage() {
  // console.log("Render test FAQPage")

  // Nav
  const nav = useNavigate();

  // Context
  const { faq3show, setFAQ3show, faqBackShow, setFAQbackShow } =
    useContext(ModalContext);

  // State
  const refQ1 = useRef(null);
  const [q1show, setQ1show] = useState(false);
  const [q2show, setQ2show] = useState(false);
  const [q4show, setQ4show] = useState(false);
  const refQ2 = useRef(null);
  const refQ3 = useRef(null);
  const refQ4 = useRef(null);

  // const [q5show, setQ5show] = useState(false);
  // const [q6show, setQ6show] = useState(false);

  const handleMinamise = (i) => {
    if (i === "q1") {
      setQ1show(!q1show);
    } else {
      setQ1show(false);
    }

    if (i === "q2") {
      setQ2show(!q2show);
    } else {
      setQ2show(false);
    }

    if (i === "q3") {
      setFAQ3show(!faq3show);
    } else {
      setFAQ3show(false);
    }

    if (i === "q4") {
      setQ4show(!q4show);
    } else {
      setQ4show(false);
    }

    onResize();
  };

  const onResize = React.useCallback(() => {
    if (refQ1.current && q1show === true) {
      // let q1 = refQ1.current.clientHeight;
      // console.log(q1);
    }
    if (refQ2.current && q2show === true) {
      let q2 = refQ2.current.clientHeight;
      console.log(q2);
    }
  }, [q1show, q2show]);

  React.useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="flex-row w-100 font-Montserrat text-BNPblue">
        {/* Search Section */}
        <dir className="flex justify-center">
          <div className="flex justify-center w-[98%] md:w-[95%] lg:w-[90%] BNP-Trans500">
            <div className="flex-row justify-center items-center text-center w-100">
              <h1 className="pt-3 md:pt-6 text-5xl font-bold BNP-Trans500">
                FAQ
              </h1>

              {/* Question 1 */}
              <div className="flex justify-center">
                <div className="flex-row justify-center items-center z-20 lg:w-[80%] BNP-Trans500">
                  <div className="flex justify-center">
                    <div
                      onClick={() => handleMinamise("q1")}
                      className="flex justify-center items-center w-auto cursor-pointer font-semibold"
                    >
                      <p className="ml-2 text-xl">How long does it take.</p>
                      <riIcons.RiArrowDropDownLine
                        className={
                          q1show
                            ? "w-10 h-10 min-w-10 min-h-10 text-3xl BNP-Trans500 rotate-180 text-BNPblue"
                            : "w-10 h-10 min-w-10 min-h-10 text-3xl BNP-Trans500 text-BNPblue"
                        }
                      />
                    </div>
                  </div>
                  {/* Question 1 Reply */}
                  <div
                    className={
                      q1show
                        ? "transition-height duration-500 ease-in-out mb-3 h-[432px] s620:h-[408px] s660:h-[384px] s680:h-[360px]  s820:h-[336px] s860:h-[312px] s940:h-[288px] s1180:h-[264px] s1600:h-[240px] s1740:h-[216px] opacity-100" // Show
                        : "transition-height duration-500 ease-in-out h-0 opacity-0" // Hidden
                    }
                  >
                    <div
                      ref={refQ1}
                      className="bg-white rounded-xl px-2 py-3 BNP-Trans500"
                    >
                      <Transition
                        show={q1show}
                        enter="transition ease-in-out duration-500 transform"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in-out duration-500 transform"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="flex-row">
                          <p className="text-xl mt-1">
                            <strong>Delivery.</strong>
                          </p>
                          <p>
                            Hand delivering in the Pretoria area only - Placing
                            an order before 12pm will result in a delivery
                            before COB the same day, orders placed after 12pm
                            will be delivered before 12pm the following day.
                          </p>
                          <p className="text-xl mt-1">
                            <strong>Courier.</strong>
                          </p>
                          <p>
                            Using Pudo lockers, your order will be placed in a
                            locker within 24h and then delivered to a locker of
                            your preference in the standard Pudo delivery times.
                          </p>
                          <p className="text-xl mt-1">
                            <strong>Collect.</strong>
                          </p>
                          <p>
                            Collecting directly from our premisis results in a
                            10min wait to emboss the plates and another 15min to
                            do the installation onto the vehicle.
                          </p>
                        </div>
                      </Transition>
                    </div>
                  </div>
                </div>
              </div>

              {/* Question 3 */}
              <div className="flex justify-center">
                <div className="flex-row justify-center items-center mt-3 z-20 BNP-Trans500">
                  {/* Q3 - Heading */}
                  <div className="flex justify-center ">
                    <div
                      id="FAQ-Docs"
                      onClick={() => handleMinamise("q3")}
                      className="flex justify-center items-center w-auto cursor-pointer font-semibold"
                    >
                      <p className="ml-2 text-xl">What documents are needed.</p>
                      <riIcons.RiArrowDropDownLine
                        className={
                          faq3show
                            ? "w-10 h-10 min-w-10 min-h-10 text-3xl BNP-Trans500 rotate-180 text-BNPblue"
                            : "w-10 h-10 min-w-10 min-h-10 text-3xl BNP-Trans500 text-BNPblue"
                        }
                      />
                    </div>
                  </div>
                  {/* Question 3 Reply */}
                  <div
                    className={
                      faq3show
                        ? "transition-height duration-500 ease-in-out mb-3 h-[1840px] s680:h-[1800px] s700:h-[1780px] s1000:h-[1340px] s1400:h-[1330px] s1500:h-[900px] w-100" // Show
                        : "transition-height duration-1000 ease-in-out h-0 opacity-0" // Hidden
                    }
                  >
                    <div
                      id="ID_refQ3"
                      ref={refQ3}
                      className="bg-white rounded-xl px-2 pt-3 min-w-700 BNP-Trans500"
                    >
                      <Transition
                        show={faq3show}
                        enter="transition ease-in-out duration-700 transform"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in-out duration-700 transform"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="relative flex justify-center">
                          <div
                            onClick={() => {
                              nav("/Sales");
                              setFAQbackShow(false);
                            }}
                            className={
                              faqBackShow
                                ? "absolute flex justify-center items-center w-9 h-9 top-1 left-4 hover:cursor-pointer hover:font-semibold transition-all duration-300 ease-in-out "
                                : "hidden"
                            }
                          >
                            <div className="relative">
                              <div className="absolute text-xs mt-1 -ml-4">
                                back
                              </div>
                              <riIcons.RiArrowGoBackFill className="scale-[2.5] hover:scale-[2.9] text-red-800 transition-all duration-300 ease-in-out" />
                            </div>
                          </div>
                          <div className="flex-row">
                            <div className="flex justify-center">
                              <p className="font-semibold text-xl w-[400px] s700:w-[450px] s750:w-[500px] s800:w-[550px] s900:w-auto BNP-Trans300">
                                The following three documents will need to
                                uploaded.
                              </p>
                            </div>
                            {/* Docs listed */}
                            <div className="flex-row s1500:flex  BNP-Trans500">
                              {/* Group 1st to Items for flexbox */}
                              <div className="flex-row s1000:flex BNP-Trans500">
                                {/* Reg Docs */}
                                <div className="flex justify-start s800:justify-center BNP-Trans500">
                                  <div className="flex-row min-w-[450px] max-w-[550px] mb-3 BNP-Trans500  ">
                                    <p className="font-semibold py-1">
                                      Vehicle Registration Document
                                    </p>
                                    <p>
                                      Either the{" "}
                                      <span className="inline-block">
                                        (
                                        <span className="font-semibold">
                                          RC1
                                        </span>
                                        ) or (
                                        <span className="font-semibold">
                                          MVL1
                                        </span>
                                        )
                                      </span>
                                      natis vehicle documents.
                                    </p>

                                    {/* Notes */}
                                    <div className="flex-col mx-6 mt-4">
                                      <div className="flex justify-start">
                                        <div className="flex-row text-left">
                                          <p>1. Client Name.</p>

                                          <p className="pl-4 text-left">
                                            Used to validate against the{" "}
                                            <span className="font-semibold">
                                              ID/Passport
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex justify-start">
                                        <div className="flex-row text-left">
                                          <p>2. Registration Number.</p>

                                          <p className="pl-4 text-left">
                                            The number plate with be embossed
                                            with this information.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex justify-start">
                                        <div className="flex-row text-left">
                                          <p>3. PDF417 Barcode.</p>

                                          <p className="pl-4 text-left">
                                            Used to Auto complete some of the
                                            vehicle information at a later
                                            stage.
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="flex justify-center hover:cursor-zoom-in hover:scale-150 transition-all duration-500 ease-in-out">
                                      <img
                                        src="../images/MLV1v3.png"
                                        alt=""
                                        className="h-[600px]"
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/* ID Docs */}
                                <div className="flex justify-start w-100 s800:justify-center BNP-Trans500">
                                  <div className="flex-row min-w-[400px] max-w-[700px] BNP-Trans500 ">
                                    <p className="font-semibold py-1">
                                      Identification Document
                                    </p>
                                    <div className="text-center">
                                      <p>
                                        A valid form of Identification{" "}
                                        <span className="font-semibold">
                                          ID/Passport
                                        </span>
                                      </p>
                                      <p>
                                        Note. The{" "}
                                        <span className="font-semibold">
                                          ID/Passport
                                        </span>{" "}
                                        must be the same as on the{" "}
                                        <span className="inline-block">
                                          (
                                          <span className="font-semibold">
                                            RC1
                                          </span>
                                          ) or (
                                          <span className="font-semibold">
                                            MVL1
                                          </span>
                                          )
                                        </span>{" "}
                                        document.
                                      </p>
                                    </div>

                                    {/* Notes */}
                                    <div className="flex-col mx-6 mt-4">
                                      <div className="flex justify-start">
                                        <div className="flex-row text-left">
                                          <p>1. Client Name.</p>

                                          <p className="pl-4 text-left">
                                            Used to validate against the vehicle
                                            owner names found on the{" "}
                                            <span className="inline-block">
                                              (
                                              <span className="font-semibold">
                                                RC1
                                              </span>
                                              ) or (
                                              <span className="font-semibold">
                                                MVL1
                                              </span>
                                              )
                                            </span>{" "}
                                            forms.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex justify-start">
                                        <div className="flex-row text-left">
                                          <p>4. Client Image.</p>

                                          <p className="pl-4 text-left">
                                            Used to validate against the client
                                            Selfie.
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="flex justify-center m-4 hover:cursor-zoom-in hover:scale-150 transition-all duration-500 ease-in-out">
                                      <img
                                        src="../images/ZA_ID.png"
                                        alt=""
                                        className="h-[200px]"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Selfie Img */}
                              <div className="flex justify-start s800:justify-center BNP-Trans500">
                                <div className="flex-row s1500:w-1/2 min-w-[350px] max-w-[400px] BNP-Trans500  ">
                                  <p className="font-semibold py-1">
                                    Selfie Image
                                  </p>
                                  <div className="text-center">
                                    <p>
                                      The selfie is used to validate the person
                                      requesting the{" "}
                                      <span className="font-semibold">
                                        Number Plate
                                      </span>{" "}
                                      is indeed the owner of the vehicle.
                                    </p>
                                  </div>
                                  <div className="flex justify-center m-4">
                                    <img
                                      src="../images/SelfieImg.jpg"
                                      alt=""
                                      className="h-[300px] rounded-lg"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Transition>
                    </div>
                  </div>
                </div>
              </div>

              {/* Question 2 */}
              <div className="flex justify-center">
                <div className="flex-row justify-center items-center mt-3 z-20 lg:w-[80%] BNP-Trans500">
                  <div className="flex justify-center ">
                    <div
                      onClick={() => handleMinamise("q2")}
                      className="flex justify-center items-center w-auto cursor-pointer font-semibold"
                    >
                      <p className="ml-2 text-xl">
                        What is the SABS and why is it important.
                      </p>
                      <riIcons.RiArrowDropDownLine
                        className={
                          q2show
                            ? "w-10 h-10 min-w-10 min-h-10 text-3xl BNP-Trans500 rotate-180 text-BNPblue"
                            : "w-10 h-10 min-w-10 min-h-10 text-3xl BNP-Trans500 text-BNPblue"
                        }
                      />
                    </div>
                  </div>
                  {/* Question 2 Reply */}
                  <div
                    className={
                      q2show
                        ? "transition-height duration-500 ease-in-out h-[575px] s680:h-[550px] s760:h-[550px] s900:h-[550px] s920:h-[530px] s960:h-[520px] s980:h-[520px] s1000:h-[490px] s1020:h-[460px] s1200:h-[460px] s1300:h-[450px] s1320:h-[425px] s1420:h-[380px] opacity-100" // Show
                        : "transition-height duration-1000 ease-in-out h-0 opacity-0" // Hidden
                    }
                  >
                    <div
                      ref={refQ2}
                      className="bg-white rounded-xl px-2 py-3 BNP-Trans500"
                    >
                      <Transition
                        show={q2show}
                        enter="transition ease-in-out duration-700 transform"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in-out duration-700 transform"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <p className="">
                          SABS stands for "South African Bureau of Standards".
                        </p>
                        <p className="">
                          This is the govering body that controls the standards
                          and quality of good manufactured in South Africa.
                        </p>
                        <p className="mt-2">
                          Embossers are requered by law to register with the
                          SABS and to keep this registration valid all year
                          round.
                        </p>
                        <p>
                          A SABS registered embosser must place a small sticker
                          on each number plate that looks like this.
                        </p>
                        <p>
                          <strong>
                            The number plate isn't valid if this SABS sticker is
                            not pressent.
                          </strong>
                        </p>

                        <div className="flex-row lg:flex justify-center items-center mt-2 BNP-Trans500">
                          <div className="flex justify-center">
                            <img
                              className="w-[470px]"
                              src="../images/SABS_Sticker.png"
                              alt=""
                            />
                          </div>
                          <a
                            href="https://www.sabs.co.za/About-SABS/index.asp"
                            target="_blank"
                            rel="noreferrer"
                            className="flex justify-center BNP-Links"
                          >
                            {/* SABS */}

                            <div className="flex-row items-center ">
                              <div className="flex justify-center">
                                <img
                                  className="h-[50px] w-[70px] text-blue-700"
                                  src="../images/SABS-approved.png"
                                  alt=""
                                />
                              </div>
                              <p className="w-[190px]">
                                https://www.sabs.co.za
                              </p>
                            </div>
                          </a>
                        </div>
                        <p className="mt-2">
                          <strong>
                            Validate the permit number on the SABS website.
                          </strong>
                        </p>
                        <a
                          href="https://www.sabs.co.za/Certification/certificationfilter.asp"
                          target="_blank"
                          rel="noreferrer"
                          className="flex justify-center BNP-Links"
                        >
                          {" "}
                          https://www.sabs.co.za/Certification/certificationfilter.asp{" "}
                        </a>
                      </Transition>
                    </div>
                  </div>
                </div>
              </div>

              {/* Question 4 */}
              <div className="flex justify-center">
                <div className="flex-row justify-center items-center mt-3 z-20 lg:w-[80%] BNP-Trans500">
                  <div className="flex justify-center ">
                    <div
                      onClick={() => handleMinamise("q4")}
                      className="flex justify-center items-center w-auto cursor-pointer font-semibold"
                    >
                      <p className="ml-2 text-xl">
                        Does the ebosser need to be registed with the "Gauteng
                        Department of Transport".
                      </p>
                      <riIcons.RiArrowDropDownLine
                        className={
                          q4show
                            ? "w-10 h-10 min-w-10 min-h-10 text-3xl BNP-Trans500 rotate-180 text-BNPblue"
                            : "w-10 h-10 min-w-10 min-h-10 text-3xl BNP-Trans500 text-BNPblue"
                        }
                      />
                    </div>
                  </div>
                  {/* Question 4 Reply */}
                  <div
                    className={
                      q4show
                        ? "transition-height duration-500 ease-in-out h-[541px] s620:h-[557px] s640:h-[537px] s680:h-[522px] s690:h-[500px] s700:h-[480px] s820:h-[465px] s940:h-[465px] s1020:h-[375px] s1140:h-[351px] s1220:h-[327px] s1240:h-[303px] opacity-100" // Show
                        : "transition-height duration-1000 ease-in-out h-0 opacity-0" // Hidden
                    }
                  >
                    <div
                      ref={refQ4}
                      className="bg-white rounded-xl px-2 py-3 BNP-Trans500"
                    >
                      <Transition
                        show={q4show}
                        enter="transition ease-in-out duration-700 transform"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in-out duration-700 transform"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <strong>
                          Yes, all embossers must be registered with the
                          "Gauteng Department of Transport" if they sell "GP"
                          number plates.
                        </strong>
                      </Transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dir>
      </div>
    </>
  );
}
