import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import axios from "axios";
import md5 from "md5";

// React Icons
import * as aiIcons from "react-icons/ai";

// formating
import formatPhoneNumber from "../utilities/formatPhoneNumber";
import formatProperCase from "../utilities/formatProperCase";
import RemoveFunnies from "../utilities/fromatRemoveFunnies";

// Context
import userContext from "../context/UserContext";
import UrlContext from "../context/URLContext";
import modalContext from "../context/ModalContext";
import SalesCartContext from "../context/SalesCartContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function SalesPaymentCheckLoginPage() {
  // console.log("Render SalesPaymentCheckLoginPage");
  //

  // Context
  const { salesCreateSlimUCN } = useContext(UrlContext);

  const {
    DeliveryName,
    setDeliveryName,
    RecipientsCell,
    setRecipientsCell,
    userEmail,
    setuserEmail,
    setUserID,
    setDeliverAddressUpdate,
    setRefreshToken,
    setTokenTime,
    setAccessToken,
  } = useContext(userContext);

  const {
    setSalePaymentCheckLoginOpen,
    setloginModal,
    setMsgOpen,
    msgClean,
    setMsgTxt,
    setMsgType,
    setNavLink,
    setNavLinkTxt,
    setTsAndCsSaleButton,
  } = useContext(modalContext);

  const { cartItems, setSaleRefreshPay } = useContext(SalesCartContext);

  // State
  const userEmailRef = useRef();
  const [userEmailMove, setuserEmailMove] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const [EmailErrorMsg, setEmailErrorMsg] = useState("");
  const DeliveryNameRef = useRef();
  const [DeliveryNameMove, setDeliveryNameMove] = useState(false);
  const RecipientsCellRef = useRef();
  const [RecipientsCellMove, setCellMove] = useState(false);

  const ValidateRanOnce = useRef(false);
  const [errorMsg, setErrorMsg] = useState("help");
  const [errorMsgOpen, setErrorMsgOpen] = useState(false);

  const TsCsRef = useRef();
  const [checkedTs_Cs, setCheckedTs_Cs] = useState(0);

  const [emailFlag, setEmailFlag] = useState(false);
  const [nameFlag, setNameFlag] = useState(false);
  const [cellNoFlag, setCellNoFlag] = useState(false);
  const [tscsFlag, setTsCsFlag] = useState(false);

  const effectRunOnce = useRef(false);

  // Close
  const closeClick = () => {
    setSalePaymentCheckLoginOpen(false);
  };

  useEffect(() => {
    if (RecipientsCell.length !== 0) {
      setCellMove(true);
      setCellNoFlag(false);
    }
    if (DeliveryName.length !== 0) {
      setDeliveryNameMove(true);
      setNameFlag(false);
      if (
        errorMsg === "Error with data supplied! Name & Surname is too short!"
      ) {
        setErrorMsg("");
      }
    }

    if (userEmail.length !== 0) {
      setuserEmailMove(true);
      setEmailFlag(false);
      if (
        errorMsg ===
        "Error with eMail address supplied! Check info and try again!"
      ) {
        setErrorMsg("");
      }
    }

    if (checkedTs_Cs === 1) {
      setTsCsFlag(false);
      if (errorMsg === "T's & C's not accepted.") {
        setErrorMsg("");
      }
    }
  }, [RecipientsCell, DeliveryName, userEmail, checkedTs_Cs, errorMsg]);

  // eMail Address
  const userEmailFocusOn = () => {
    setuserEmailMove(true);
    userEmailRef.current.focus();
  };

  const userEmailFocusOff = () => {
    if (userEmail.length !== 0) {
      setuserEmailMove(true);
    } else {
      setuserEmailMove(false);
    }
  };

  // DeliveryName
  const DeliveryNameFocusOn = () => {
    setDeliveryNameMove(true);
    DeliveryNameRef.current.focus();
  };

  const DeliveryNameFocusOff = () => {
    if (DeliveryName.length !== 0) {
      setDeliveryNameMove(true);
    } else {
      setDeliveryNameMove(false);
    }
  };

  // CellNo
  const RecipientsCellFocusOn = () => {
    setCellMove(true);
    RecipientsCellRef.current.focus();
  };

  const RecipientsCellFocusOff = () => {
    if (RecipientsCell.length !== 0) {
      setCellMove(true);
    } else {
      setCellMove(false);
    }
  };

  useEffect(() => {
    // Email validate
    if (ValidateRanOnce.current === true) {
      if (userEmail.length > 4) {
        setEmailError(true);

        var emailcase =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (userEmail.length < 4) {
          setEmailErrorMsg("Email length is too short.");
        } else if (userEmail.charAt(userEmail.length - 1) === ".") {
          setEmailErrorMsg("An email address can not end with a PERIOD!");
        } else if (!userEmail.match("@")) {
          setEmailErrorMsg("An email address must have a @ in it!");
        } else if (!userEmail.match(emailcase)) {
          setEmailErrorMsg("Invalid email address!");
        } else {
          setEmailErrorMsg("valid email supplied!");
          setEmailError(false);
        }
      } else setEmailError(false);
      ValidateRanOnce.current = false;
    }
  }, [userEmail]);

  //   Build name from Sales page if deliveryName is empty
  useEffect(() => {
    // use name if Recipient is missing
    const plates = cartItems.filter((item) => {
      return item.prodType === "plate";
    });

    // Get distinct name and surnames from plates
    const unique = [
      ...new Set(plates.map((item) => item.name + " " + item.surname)),
    ];
    // console.log(unique[0]);

    // Update Name from plate only once.
    if (effectRunOnce.current === false) {
      if (unique.length === 1 && DeliveryName.length === 0) {
        setDeliveryName(unique[0]);
      }
      effectRunOnce.current = true;
    }
  }, [cartItems, setDeliveryName, DeliveryName]);

  // open Login modal
  const LoginClick = () => {
    setSalePaymentCheckLoginOpen(false);
    setloginModal(true);
  };

  const handleContinueBTN = () => {
    let i = 0;

    // Check T's and C's accepted
    if (checkedTs_Cs === 0) {
      setErrorMsg("T's & C's not accepted.");
      i = 1;
      setTsCsFlag(true);
      TsCsRef.current.focus();
      //   console.log(errorMsg);
    }
    // Check Cell number inserted
    if (RemoveFunnies(RecipientsCell).length < 10) {
      setErrorMsg("Error with Cell number supplied! Check info and try again!");
      i = i + 1;
      setCellNoFlag(true);
      RecipientsCellRef.current.focus();
      //   console.log(errorMsg);
    }
    // Check Name inserted
    if (DeliveryName.length < 4) {
      setErrorMsg("Error with data supplied! Name & Surname is too short!");
      i = i + 1;
      setNameFlag(true);
      DeliveryNameRef.current.focus();
      //   console.log(errorMsg);
    }
    // Check eMail inserted
    if (EmailError === true || userEmail.length < 4) {
      setErrorMsg(
        "Error with eMail address supplied! Check info and try again!"
      );
      i = 1;
      setEmailFlag(true);
      userEmailRef.current.focus();
      //   console.log(errorMsg);
    }

    // Show error message for submit.
    if (i > 0) {
      setErrorMsgOpen(true); // Error other inputs
      //   console.log("issues");
    } else {
      setErrorMsgOpen(false);
      //   console.log("complete sale");
      createSlimUCN();
    }
  };

  const createSlimUCN = async () => {
    // Push User data to mySQL db.
    let error = 0;

    //  Hash build
    let pw = md5(userEmail + process.env.REACT_APP_UCNPASSPHRASE);

    let url = salesCreateSlimUCN;
    const result = await axios
      .post(url, {
        userEmail,
        checkedTs_Cs,
        z: pw,
      })
      .then((res) => res)
      .catch((err) => {
        error = 1;
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });
    // console.log(result);

    if (error === 0) {
      if (result.status === 200) {
        const data = result.data;
        if (data.auth === true) {
          if (data.sqlData[0][0].Msg === "Successful - Account created") {
            setUserID(data.sqlData[0][0].UCN);
            setRefreshToken(data.refreshToken);
            setAccessToken(data.accessToken);
            setTokenTime(new Date().getTime() + 1000);
            setSaleRefreshPay(1);
            setSalePaymentCheckLoginOpen(false);
          } else {
            msgClean();
            setMsgTxt(
              "The email address supplied already exists in our data. Please Log into the account before continuing!"
            );
            setMsgType("Warning");
            setNavLink("loginModal");
            setNavLinkTxt("Login");
            setMsgOpen(true);
          }
        }
      }
    }
  };

  // T's & C's
  const termsChange = (e) => {
    if (e.target.checked) {
      setCheckedTs_Cs(1);
    } else {
      setCheckedTs_Cs(0);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="flex justify-center max-h-full font-Montserrat">
        <div className="flex-row pt-[5%] pb-100 px-100">
          {/* Header */}
          <div className="relative flex justify-center items-center w-[600px] h-[70px] text-BNPgoldLight bg-BNPblue shadow-md shadow-BNPblue">
            <div className="text-3xl font-semibold">
              <h1>Minimum client info needed.</h1>
            </div>
            <div className="absolute top-5 left-5">
              <div className="pt-1 pl-1">
                <aiIcons.AiOutlineClose
                  className="rounded-full focus:outline-none transition-all duration-500 scale-[2.2] hover:font-semibold hover:scale-[2.9] hover:rotate-90
                  hover:border-2 hover:rounded-full hover:border-BNPgoldLight cursor-pointer hover:transition-all hover:duration-700"
                  onClick={closeClick}
                />
              </div>
            </div>
          </div>
          {/* Body */}
          <div className="flex-row justify-between px-2 py-4 bg-white rounded-b-md text-BNPblue shadow-md shadow-BNPblue">
            <div className="flex-row items-center justify-between  mx-14">
              <div className="flex-row justify-center items-center text-center pt-3 border-b-4 border-BNPblue border-opacity-40 ">
                <h1 className="text-2xl mr-2">
                  If you have an account, please login
                </h1>
                <p
                  onClick={() => LoginClick()}
                  className="text-2xl BNP-Links py-2 m-0"
                >
                  Login
                </p>
                <h1 className="text-2xl pb-3 pt-1">
                  otherwise complete the detials needed below.
                </h1>
              </div>
            </div>

            {/* eMail Address */}
            <div className="flex justify-center my-3">
              <div className="flex-row justify-center h-[65px]">
                <div className="flex justify-center">
                  <div
                    onClick={() => userEmailFocusOn()}
                    className="group mt-1 h-[47px] BNP-LableDIV cursor-text"
                  >
                    <input
                      value={userEmail}
                      onChange={(e) => {
                        setuserEmail(e.target.value);
                        ValidateRanOnce.current = true;
                      }}
                      onBlur={() => userEmailFocusOff()}
                      type="text"
                      ref={userEmailRef}
                      autoComplete="off"
                      className={
                        emailFlag
                          ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                          : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                      }
                    />
                    <h4
                      className={
                        userEmailMove
                          ? "w-[95px] BNP-LableMove -translate-y-[50px]"
                          : "w-[170px] BNP-LableStart pt-[4px] bg-opacity-0"
                      }
                    >
                      <div className="flex">
                        eMail Address
                        <div
                          className={
                            userEmailMove
                              ? "hidden"
                              : "font-semibold pl-1 text-red-700"
                          }
                        >
                          *
                        </div>
                      </div>
                    </h4>
                  </div>
                </div>
                {/*  Error Message */}
                <div className="flex justify w-[350px] text-sm mt-1 -z-20">
                  <div
                    className={
                      EmailError
                        ? "w-[500px] ml-1 font-semibold text-red-600 mr-1"
                        : "hidden"
                    }
                  >
                    <Transition
                      show={EmailError}
                      enter="transition ease-in-out duration-500 transform"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition ease-in-out duration-500 transform"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      {EmailErrorMsg}
                    </Transition>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center gap-2">
              {/* Recipients Name */}
              <div className="flex justify-center">
                <div
                  onClick={() => DeliveryNameFocusOn()}
                  className="group mt-1 h-[40px] BNP-LableDIV cursor-text"
                >
                  <input
                    value={DeliveryName}
                    onChange={(e) => {
                      setDeliveryName(formatProperCase(e.target.value));
                      setDeliverAddressUpdate(true);
                    }}
                    onBlur={() => DeliveryNameFocusOff()}
                    type="text"
                    ref={DeliveryNameRef}
                    autoComplete="off"
                    className={
                      nameFlag
                        ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                        : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                    }
                  />
                  <h4
                    className={
                      DeliveryNameMove
                        ? "w-[117px] BNP-LableMove -translate-y-[50px]"
                        : "w-[170px] BNP-LableStart pt-[2px] bg-opacity-0"
                    }
                  >
                    <div className="flex">
                      Name & Surname
                      <div
                        className={
                          DeliveryNameMove
                            ? "hidden"
                            : "font-semibold pl-1 text-red-700"
                        }
                      >
                        *
                      </div>
                    </div>
                  </h4>
                </div>
              </div>
              {/* Recipients Cell */}
              <div className="flex justify-center">
                <div
                  onClick={() => RecipientsCellFocusOn()}
                  className="group mt-1 h-[40px] w-[200px] BNP-LableDIV cursor-text"
                >
                  <input
                    value={RecipientsCell}
                    onChange={(e) => {
                      setRecipientsCell(formatPhoneNumber(e.target.value));
                      setDeliverAddressUpdate(true);
                    }}
                    onBlur={() => RecipientsCellFocusOff()}
                    type="text"
                    ref={RecipientsCellRef}
                    autoComplete="off"
                    className={
                      cellNoFlag
                        ? "w-[200px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                        : "w-[200px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                    }
                  />
                  <h4
                    className={
                      RecipientsCellMove
                        ? "w-[88px] BNP-LableMove -translate-y-[50px]"
                        : "w-[150px] BNP-LableStart pt-[2px] bg-opacity-0"
                    }
                  >
                    <div className="flex">
                      Cell Number
                      <div
                        className={
                          RecipientsCellMove
                            ? "hidden"
                            : "font-semibold pl-1 text-red-700"
                        }
                      >
                        *
                      </div>
                    </div>
                  </h4>
                </div>
              </div>
            </div>

            {/* T's & C's */}
            <div className="flex justify-center items-center h-10 text-l font-medium mt-2">
              <div className="relative flex justify-center">
                <div
                  className={
                    tscsFlag
                      ? "flex justify-center items-center border-4 border-red-600 rounded-md bg-red-600"
                      : "mt-[9px] ml-[5px] w-[30px] h-[30px] "
                  }
                >
                  <input
                    id="terms"
                    aria-describedby="terms"
                    type="checkbox"
                    onChange={termsChange}
                    className="w-6 h-6"
                  />
                </div>
                <div className="flex ml-5 text-l font-medium justify-center items-center text-center">
                  <div className="flex w-[300px]">
                    <div className="font-medium text-gray-500">
                      I accept the
                      <Link
                        ref={TsCsRef}
                        to={"/Ts&Cs"}
                        onClick={() => {
                          setTsAndCsSaleButton(true);
                          setSalePaymentCheckLoginOpen(false);
                        }} // come back here from T's and C's page
                        className="BNP-Links"
                      >
                        Terms and Conditions
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-2 h-16">
              <div className="flex-row justify-center">
                <div className="flex justify-center mb-1">
                  <button
                    onClick={() => handleContinueBTN()}
                    className="BNP-SubmitButtonsBlue h-[40px] w-44 flex justify-center items-center"
                  >
                    Continue
                  </button>
                </div>
                {/*  Error Message */}
                <div className="flex justify text-sm">
                  <div
                    className={
                      errorMsgOpen ? "font-semibold text-red-600" : "hidden"
                    }
                  >
                    <Transition
                      show={errorMsgOpen}
                      enter="transition ease-in-out duration-500 transform"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition ease-in-out duration-500 transform"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="text-center"
                    >
                      {errorMsg}
                    </Transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
