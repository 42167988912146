import React, { useRef, useState, useContext, useEffect } from "react";
import { Transition } from "@headlessui/react";
// import CircularProgress from "@mui/material/CircularProgress";
import validSouthAfricanId from "valid-south-african-id";
import { HashLink } from "react-router-hash-link";
import md5 from "md5";
import axios from "axios";
import compressor from "compressorjs";
// import Tesseract from "tesseract.js";

// data
import storePlateInfo from "../data/Lookups/plates.json";
import bPlateInfo from "../data/Lookups/bplates.json";
import plateCaseInfo from "../data/Lookups/plateCase.json";
import pricingInfo from "../data/Lookups/prices.json";
import otherSalesInfo from "../data/Lookups/otherForSaleData.json";

// formating
import formatFunny from "../utilities/fromatRemoveFunnies";
import formatProperCase from "../utilities/formatProperCase";
import formatCurrency from "../utilities/formatCurrency";
import validateRegNo from "../utilities/validateRegistrationNo";
import formatRegistrationNo from "../utilities/formatRegistrationNo";
import RemoveFunniesIMG from "../utilities/formatRemoveFunniesIMG";

// Context
import ModalContext from "../context/ModalContext";
import SalesCartContext from "../context/SalesCartContext";
import UrlContext from "../context/URLContext";
import UserContext from "../context/UserContext";

// React Icons
import * as luIcons from "react-icons/lu";
import * as riIcons from "react-icons/ri";
import * as faIcons from "react-icons/fa";

// Page Components
import DropIDType from "./LookupIDPassport";
import SalesPageMap from "./SalesPageMap";

// Utilities
import CarouselImg from "../utilities/carouselImg";
import CarouselImgBP from "../utilities/carouselImgBackingPlate";
import RemoveFunnies from "../utilities/fromatRemoveFunnies";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function SalesPage() {
  // console.log("Render SalesPage");

  // Context
  const { googleImgUpload } = useContext(UrlContext);
  const { IdType, setIdType } = useContext(UserContext);
  const {
    testing,
    setSalesModalPrevOpen,
    setPrevType,
    setPrevDoc,
    msgClean,
    setMsgOpen,
    setMsgTxt,
    setMsgType,
    setIDTypeInValid,
    setCookieModal,
    cookieModalDelay,
    sidebarSize,
    setFAQ3show,
    setFAQbackShow,
  } = useContext(ModalContext);

  const {
    saveLocalSales,
    cartItems,
    setCartItems,
    CartVoladd,
    plateVol,
    bPlateVol,
    rivetVol,
    IDfile,
    setIDfile,
    Regfile,
    setRegfile,
    Selfiefile,
    setSelfiefile,
    province,
  } = useContext(SalesCartContext);

  // const [idTextResult, setIDTextResult] = useState("");
  // const [progress, setProgress] = useState(0);

  // Max img size to Google cloud
  const uploadSize = 512000; // 512000 is 500kb

  const [run, setRun] = useState(false);
  const [runCount, setRunCount] = useState(0);
  const [pSize, setPSize] = useState("520");
  const [RegNoValidate, setRegNoValidate] = useState("Fail:9");
  const [RegNoValidView, setRegNoValidView] = useState(false);

  const [plateActive, setPlateActive] = useState("b");
  const [action, setAction] = useState(false);
  const [plateA, setPlateA] = useState(true);
  const [plateB, setPlateB] = useState(false);
  const [plateImgA, setPlateImgA] = useState("./images/Plate_GP520.png");
  const [plateImgB, setPlateImgB] = useState("./images/Plate_GP520.png");
  const [plate, setPlate] = useState("./images/Plate_GP520.png");
  const [platePrice, setPlatePrice] = useState(0);
  const [plateSize, setPlateSize] = useState("520mm x 110mm");
  const [pCaseSize, setpCaseSize] = useState("w-[300px]");

  const [pSizeA, setpSizeA] = useState("520");
  const [casePlateSizeA, setCasePlateSizeA] = useState("w-[520px]");
  const [pSizeB, setpSizeB] = useState("520");
  const [casePlateSizeB, setCasePlateSizeB] = useState("w-[520px]");
  const [cDirectionA, setCDirectionA] = useState("left");
  const [cDirectionB, setCDirectionB] = useState("left");

  const [provinceWording, setProvinceWording] = useState("Gauteng");

  const [bpsize520, setBpSize520] = useState(true);
  const [bpsize440, setBpSize440] = useState(false);
  const [bpsize250, setBpSize250] = useState(false);
  const [bPlate, setBPlate] = useState("");
  const [bPlate520, setBPlate520] = useState("");
  const [bPlate440, setBPlate440] = useState("");
  const [bPlate250, setBPlate250] = useState("");
  const [bpcDirection, setBpCDirection] = useState("left");
  const [bpPrice, setBpPrice] = useState(0);
  const [rivetPrice, setRivetPrice] = useState(0);
  const [rivetImg, setRivetImg] = useState("");
  const [rCaseSize, setRCaseSize] = useState("");

  // Plates structure
  const [provVisable, setProvVisable] = useState(false);
  const [plateWording, setPlateWording] = useState("");
  const [plateProvince, setPlateProvince] = useState("");

  // Other State
  const [IDfileWording, setIDfileWording] = useState("ID Document upload:");
  const [IDfileNewName, setIDfileNewName] = useState("");
  const [IDMove, setIDMove] = useState(false);
  const [IDimgError, setIDimgError] = useState(false);
  const [IDErrorMsg, setIDErrorMsg] = useState("");
  const [idBtnWait, setIDBtnWait] = useState(false);

  const [RegfileWording, setRegfileWording] = useState(
    "Vehicle Registration Document upload:"
  );
  const [RegMove, setRegMove] = useState(false);
  const [RegNewFileName, setRegNewFileName] = useState("");
  const [RegimgError, setRegimgError] = useState(false);
  const [RegErrorMsg, setRegErrorMsg] = useState("");
  const [regBtnWait, setRegBtnWait] = useState(false);

  const [SelfiefileWording, setSelfiefileWording] = useState(
    "Selfie Image upload:"
  );
  const [SelfieMove, setSelfieMove] = useState(false);
  const [SelfieNewName, setSelfieNewName] = useState("");
  const [SelfieimgError, setSelfieimgError] = useState(false);
  const [SelfieErrorMsg, setSelfieErrorMsg] = useState("");
  const [selfieBtnWait, setSelfieBtnWait] = useState(false);

  const regNoRef = useRef();
  const [regNo, setRegNo] = useState("");
  const [regNoMove, setRegNoMove] = useState(false);

  const nameRef = useRef();
  const [name, setName] = useState("");
  const [nameMove, setNameMove] = useState(false);

  const surnameRef = useRef();
  const [surname, setSurname] = useState("");
  const [surnameMove, setSurnameMove] = useState(false);

  const idNoRef = useRef();
  const [idNo, setIDNo] = useState("");
  const [idNoValid, setIDnoValid] = useState(false);
  const [idNoMove, setIDNoMove] = useState(false);

  // counts
  const [plateCountWording, setPlateCountWording] = useState("plate");
  // BpCounts
  const [bpPlateCountWording, setBpPlateCountWording] = useState("plate");
  // Rivet Counts
  const [revetCountWording, setRivetCountWording] = useState("rivet");

  // Google upload result mark icon
  const [IDimgUploadSuccess, setIDimgUploadSuccess] = useState(false);
  const [IDimgUpload, setIDimgUpload] = useState(true);
  const [RegimgUploadSuccess, setRegimgUploadSuccess] = useState(false);
  const [RegimgUpload, setRegimgUpload] = useState(true);
  const [SelfimgUploadSuccess, setSelfimgUploadSuccess] = useState(false);
  const [SelfimgUpload, setSelfimgUpload] = useState(true);

  const [IdUploadFlag, setIdUploadFlag] = useState(false);
  const [regUploadFlag, setRegUploadFlag] = useState(false);
  const [selfieUploadFlag, setSelfieUploadFlag] = useState(false);
  const [registrFlag, setRegistrFlag] = useState(false);
  const [nameFlag, setNameFlag] = useState(false);
  const [surnameFlag, setSurnameFlag] = useState(false);
  const [idPassportFlag, setIdPassportFlag] = useState(false);

  const [addCartClick, setAddCartClick] = useState(false);

  const effectRunOnce = useRef(false);

  // ToolTips
  const [BTNcartAddTT, setBTNcartAddTT] = useState(false);

  // Check if CookieModal delayed
  const cookieModalCheck = () => {
    if (cookieModalDelay === true) setCookieModal(true);
  };

  // Plate count wording
  useEffect(() => {
    // Testing
    if (testing === 1 && effectRunOnce.current === false) {
      setRegNo("BX 30 XB GP");
      setName("John");
      setSurname("Doe");

      effectRunOnce.current = true;
    }

    if (cartItems.length !== 0) {
      localStorage.setItem("BNPcart", JSON.stringify(cartItems));
    }

    let provID;
    let plateSizeID;

    if (plateVol === 1) {
      setPlateCountWording("Plate");
    } else {
      setPlateCountWording("Plate's");
    }

    if (bPlateVol === 1) {
      setBpPlateCountWording("Backing plate");
    } else {
      setBpPlateCountWording("Backing plate's");
    }

    if (revetCountWording === 1) {
      setRivetCountWording("Rivet packet");
    } else {
      setRivetCountWording("Rivet packet's");
    }

    // Get Array index
    if (province === "NC") {
      provID = 0;
    } else if (province === "WP") {
      provID = 1;
    } else if (province === "NW") {
      provID = 2;
    } else if (province === "FS") {
      provID = 3;
    } else if (province === "GP") {
      provID = 4;
    } else if (province === "MP") {
      provID = 5;
    } else if (province === "L") {
      provID = 6;
    } else if (province === "ZN") {
      provID = 7;
    } else if (province === "EC") {
      provID = 8;
    } else if (province === "BP") {
      provID = 9;
    }

    // Size Index
    if (pSize === "520") {
      plateSizeID = 0;
    } else if (pSize === "440") {
      plateSizeID = 1;
    } else if (pSize === "250") {
      plateSizeID = 2;
    }

    // plate info
    const numbers = [storePlateInfo[provID]];
    const newValue = numbers.find((prov) => (prov = 1));
    // console.log(newValue);

    // pricing
    const pricing = [pricingInfo[provID]];
    const newPricing = pricing.find((prov) => (prov = 1));
    // console.log(newPricing);

    // backing plate info
    const bpInfo = [bPlateInfo[plateSizeID]];
    const newBpInfo = bpInfo.find((size) => (size = 1));

    // BpPricing
    const bpPricing = [pricingInfo[9]];
    const bpNewPricing = bpPricing.find((prov) => (prov = 1));
    // console.log(bpNewPricing);

    // Rivet Into
    const rivets = [otherSalesInfo[0]];
    const rivetNewInfo = rivets.find((id) => (id = 1));
    // console.log(rivetNewInfo);

    // Plate Case
    const pLateCase = [plateCaseInfo[plateSizeID]];
    const newPlateCase = pLateCase.find((size) => (size = 1));
    // console.log(newPlateCase);

    // Rivet info update
    setRivetPrice(rivetNewInfo.price);
    setRivetImg(rivetNewInfo.img);
    setRCaseSize(newPlateCase.rivet);

    // Size info update i.e Pricing Backing plate, Cart Case and Size desctiption
    if (pSize === "520") {
      setPlatePrice(newPricing.price520);
      setBpPrice(bpNewPricing.price520);
      setBPlate520(newBpInfo.bpsize520);
      setBpCDirection(newBpInfo.cDirection);
      setPlateSize("520mm x 110mm");
      setpCaseSize(newPlateCase.pSizeCart520);
      setBPlate(newBpInfo.bpsize520);
    } else if (pSize === "440") {
      setPlatePrice(newPricing.price440);
      setBpPrice(bpNewPricing.price440);
      setBPlate440(newBpInfo.bpsize440);
      setBpCDirection(newBpInfo.cDirection);
      setPlateSize("440mm x 120mm");
      setpCaseSize(newPlateCase.pSizeCart440);
      setBPlate(newBpInfo.bpsize440);
    } else if (pSize === "250") {
      setPlatePrice(newPricing.price250);
      setBpPrice(bpNewPricing.price250);
      setBPlate250(newBpInfo.bpsize250);
      setBpCDirection(newBpInfo.cDirection);
      setPlateSize("250mm x 205mm");
      setpCaseSize(newPlateCase.pSizeCart250);
      setBPlate(newBpInfo.bpsize250);
    }

    // Flag Direction
    if (run === true && runCount !== 1) {
      // console.log("start")

      setProvinceWording(newValue.name);

      // Clear items
      setPlateA(false);
      setPlateB(false);

      if (plateActive === "b") {
        // console.log("b-Run");

        setPlateB(true);

        if (action === true) {
          setCDirectionB("left");
        } else {
          setCDirectionB(newValue.cDirection);
        }

        if (pSize === "520") {
          setPlateImgB(newValue.size520);
          setCasePlateSizeB(newPlateCase.pSizeSP520);
          setPlate(newValue.size520);
          setpSizeB(pSize);
        } else if (pSize === "440") {
          setPlateImgB(newValue.size440);
          setCasePlateSizeB(newPlateCase.pSizeSP440);
          setPlate(newValue.size440);
          setpSizeB(pSize);
        } else if (pSize === "250") {
          setPlateImgB(newValue.size250);
          setCasePlateSizeB(newPlateCase.pSizeSP250);
          setPlate(newValue.size250);
          setpSizeB(pSize);
        }

        // console.log(pSizeB)
        setPlateActive("a");
        ////////////////////////////////////////////////////////////
      }
      ////////////////////////////////////////////////////////////
      else {
        // console.log("a-Run")

        setPlateA(true);
        if (action === true) {
          setCDirectionA("left");
        } else {
          setCDirectionA(newValue.cDirection);
        }

        if (pSize === "520") {
          setPlateImgA(newValue.size520);
          setCasePlateSizeA(newPlateCase.pSizeSP520);
          setPlate(newValue.size520);
          setpSizeA(pSize);
        } else if (pSize === "440") {
          setPlateImgA(newValue.size440);
          setCasePlateSizeA(newPlateCase.pSizeSP440);
          setPlate(newValue.size440);
          setpSizeA(pSize);
        } else if (pSize === "250") {
          setPlateImgA(newValue.size250);
          setCasePlateSizeA(newPlateCase.pSizeSP250);
          setPlate(newValue.size250);
          setpSizeA(pSize);
        }

        // console.log(pSizeA)
        setPlateActive("b");
      }

      // Set Defaults
      setProvVisable(false);
      setAction(false);
      setRun(false);
      setRunCount(runCount + 1);
      // console.log("finish")
    }

    // Validatae RegNo
    if (regNo?.length > 0) {
      let provClean = formatFunny(regNo.toUpperCase());
      if (
        // GP, NC, WP, EC, ZN, MP, FS, NW
        provClean.substring(provClean.length - 2) === "GP" ||
        provClean.substring(provClean.length - 2) === "NC" ||
        provClean.substring(provClean.length - 2) === "WP" ||
        provClean.substring(provClean.length - 2) === "EC" ||
        provClean.substring(provClean.length - 2) === "ZN" ||
        provClean.substring(provClean.length - 2) === "MP" ||
        provClean.substring(provClean.length - 2) === "FS" ||
        provClean.substring(provClean.length - 2) === "NW"
      ) {
        setPlateWording(regNo.substring(0, regNo.length - 2));
        setPlateProvince(provClean.substring(provClean.length - 2));
      } else if (
        // L
        provClean.substring(provClean.length - 1) === "L"
      ) {
        setPlateWording(regNo.substring(0, regNo.length - 1));
        setPlateProvince(provClean.substring(provClean.length - 1));
      } else {
        setPlateWording(regNo);
        setPlateProvince("");
      }
    } else {
      setPlateWording("");
      setPlateProvince("");
    }

    if (RegNoValidate === "Fail" || plateProvince !== province) {
      if (plateProvince !== "" && plateProvince !== province) {
        setRegNoValidView(true);
      } else {
        setRegNoValidView(false);
      }
    } else {
      if (pSize === "250" && formatFunny(regNo).length > 8) {
        setRegNoValidView(true);
      } else {
        setRegNoValidView(false);
      }
    }

    // Update original name if already entered
    const date = new Date();
    if (IDfile.name?.length > 0) {
      setIDfileNewName(
        date.toISOString().split("T")[0].replace(/-/g, "") +
          "-" +
          RemoveFunnies(regNo.toUpperCase()) +
          "-ID|" +
          IDfile.name
      );
    }
    if (Regfile.name?.length > 0) {
      setRegNewFileName(
        date.toISOString().split("T")[0].replace(/-/g, "") +
          "-" +
          RemoveFunnies(regNo.toUpperCase()) +
          "-Reg|" +
          Regfile.name
      );
    }
    if (Selfiefile.name?.length > 0) {
      setSelfieNewName(
        date.toISOString().split("T")[0].replace(/-/g, "") +
          "-" +
          RemoveFunnies(regNo.toUpperCase()) +
          "-Selfie|" +
          Selfiefile.name
      );
    }
  }, [
    testing,
    run,
    plateActive,
    province,
    pSize,
    plateVol,
    bPlateVol,
    runCount,
    action,
    cartItems,
    revetCountWording,
    regNo,
    RegNoValidate,
    IDfileNewName,
    RegNewFileName,
    SelfieNewName,
    IDfile,
    Regfile,
    Selfiefile,
    setIdType,
    plateProvince,
  ]);

  // console.log(casePlateSizeA)
  // console.log(casePlateSizeB)
  // console.log(pSize)
  // console.log(platePrice)
  // console.log(plateActive);
  // console.log(run);
  // console.log(runCount);
  // console.log(province);

  useEffect(() => {
    // Check valid ID number
    if (IdType === "SA ID") {
      if (idNo.length > 0 && validSouthAfricanId(idNo) === false) {
        setIDnoValid(true);
      } else {
        setIDnoValid(false);
      }
    } else {
      setIDnoValid(false);
    }

    // Check if data missing
    if (IDfile?.length > 0 || IDfile.name?.length > 0) {
      setIDMove(true);
      setIDfileWording("ID Document:");
      setIDimgError(false);
    } else {
      setIDMove(false);
      setIDfileWording("ID Document upload:");
    }
    if (Regfile?.length > 0 || Regfile.name?.length > 0) {
      setRegMove(true);
      setRegfileWording("Registration Document:");
      setRegimgError(false);
    } else {
      setRegMove(false);
      setRegfileWording("Vehicle Registration Document upload:");
    }
    if (Selfiefile?.length > 0 || Selfiefile.name?.length > 0) {
      setSelfieMove(true);
      setSelfiefileWording("Selfie:");
      setSelfieimgError(false);
    } else {
      setSelfieMove(false);
      setSelfiefileWording("Selfie Image upload:");
    }
    if (regNo.length !== 0) {
      setRegNoMove(true);
      setRegistrFlag(false);
    }
    if (name.length !== 0) {
      setNameMove(true);
      setNameFlag(false);
    }
    if (surname.length !== 0) {
      setSurnameMove(true);
      setSurnameFlag(false);
    }
    if (idNo.length !== 0) {
      setIDNoMove(true);
      setIdPassportFlag(false);
    }
    if (IdType !== "") {
      setIdType(IdType);
      setIDTypeInValid(false);
    }
  }, [
    IDfile,
    Regfile,
    Selfiefile,
    regNo,
    name,
    surname,
    idNo,
    IdType,
    setIdType,
    setIDTypeInValid,
  ]);

  // ID info
  const ChangeIDfile = (event) => {
    let i = 0;

    try {
      let file = event.target.files[0];
      let imgLen = file.size;
      let imgName = file.name;

      // Check file type
      if (imgName.length !== 0) {
        if (
          imgName.substring(imgName.length - 4).toLowerCase() !== ".jpg" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".png" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".gif" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== "jpeg" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".bmp" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".svg"
        ) {
          setIDimgError(true);
          setIDErrorMsg(
            "Please use a prefered file formats! i.e. jpg, jpeg, bmp, png, svg, gif."
          );
        } else {
          setIDimgError(false);
          setIDErrorMsg("");
        }
      } else {
        setIDimgError(false);
        setIDErrorMsg("");
      }

      // Error message clear
      setIDimgError(false);
      setIDErrorMsg("");

      // Check size of img size for compressing
      // console.log("original size: ",imgLen);
      if (imgLen >= uploadSize) {
        // compress image
        new compressor(file, {
          quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
          success(result) {
            // console.log("compressed file: ", result.size)
            setIDfile(result);
          },
        });
      } else {
        // File is smaller than 500kb, no compression needed
        setIDfile(file);
      }

      NameFocusOn();
      i = 1;
    } catch (error) {
      setIDMove(false);
      setIDfile([]);
      setIDfileNewName("");
    }

    if (addCartClick === true) {
      if (i === 1) {
        setIdUploadFlag(true);
        setIDimgError(false);
      } else {
        setIdUploadFlag(false);
        setIDimgError(true);
      }
    }
  };

  const NameFocusOn = () => {
    setIDMove(true);
    setIDfileWording("ID Document:");
  };

  const NameFocusOff = () => {
    if (IDfile?.length !== 0) {
      setIDMove(true);
    } else {
      setIDMove(false);
    }
  };

  const PreviewIDload = () => {
    if (IDfile.length === 0) {
      setIDimgError(true);
      setIDErrorMsg("Please select a file to preview!");
    } else {
      setPrevDoc(URL.createObjectURL(IDfile));
      setPrevType("ID");

      setSalesModalPrevOpen(true);
    }
  };

  // Vehicle Reg info
  const ChangeRegfile = (event) => {
    let i = 0;
    try {
      let file = event.target.files[0];
      let imgLen = file.size;
      let imgName = file.name;

      // Check file type
      if (imgName.length !== 0) {
        if (
          imgName.substring(imgName.length - 4).toLowerCase() !== ".jpg" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".png" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".gif" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== "jpeg" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".bmp" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".svg"
        ) {
          setRegimgError(true);
          setRegErrorMsg(
            "Please use a prefered file formats! i.e. jpg, jpeg, bmp, png, svg, gif."
          );
        } else {
          setRegimgError(false);
          setRegErrorMsg("");
        }
      } else {
        setRegimgError(false);
        setRegErrorMsg("");
      }

      // Error message clear
      setRegimgError(false);
      setRegErrorMsg("");

      // Check size of img size for compressing
      // console.log("original size: ",imgLen);
      if (imgLen >= uploadSize) {
        // compress image
        new compressor(file, {
          quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
          success(result) {
            // console.log("compressed file: ", result.size)
            setRegfile(result);
          },
        });
      } else {
        // File is smaller than 500kb, no compression needed
        setRegfile(file);
      }

      RegFocusOn();
      i = 1;
    } catch (error) {
      setRegMove(false);
      setRegfile([]);
      setRegNewFileName("");
    }

    if (addCartClick === true) {
      if (i === 1) {
        setRegUploadFlag(true);
        setRegimgError(false);
      } else {
        setRegUploadFlag(false);
        setRegimgError(true);
      }
    }
  };

  const RegFocusOn = () => {
    setRegMove(true);
    setRegfileWording("Registration Document:");
  };

  const RegFocusOff = () => {
    if (Regfile?.length !== 0) {
      setRegMove(true);
    } else {
      setRegMove(false);
    }
  };

  const PreviewRegload = () => {
    if (Regfile.length === 0) {
      setRegimgError(true);
      setRegErrorMsg("Please select a file to preview!");
    } else {
      setPrevDoc(URL.createObjectURL(Regfile));
      setPrevType("Registration");

      setSalesModalPrevOpen(true);
    }
  };

  // Selfie import info
  const ChangeSelfiefile = (event) => {
    let i = 0;
    try {
      let file = event.target.files[0];
      let imgLen = file.size;
      let imgName = file.name;

      // Check file type
      if (imgName.length !== 0) {
        if (
          imgName.substring(imgName.length - 4).toLowerCase() !== ".jpg" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".png" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".gif" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== "jpeg" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".bmp" &&
          imgName.substring(imgName.length - 4).toLowerCase() !== ".svg"
        ) {
          setSelfieimgError(true);
          setSelfieErrorMsg(
            "Please use a prefered file formats! i.e. jpg, jpeg, bmp, png, svg, gif."
          );
        } else {
          setSelfieimgError(false);
          setSelfieErrorMsg("");
        }
      } else {
        setSelfieimgError(false);
        setSelfieErrorMsg("");
      }

      // Error message clear
      setSelfieimgError(false);
      setSelfieErrorMsg("");

      // Check size of img size for compressing
      // console.log("original size: ",imgLen);
      if (imgLen >= uploadSize) {
        // compress image
        new compressor(file, {
          quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
          success(result) {
            // console.log("compressed file: ", result.size)
            setSelfiefile(result);
          },
        });
      } else {
        // File is smaller than 500kb, no compression needed
        setSelfiefile(file);
      }

      SelfieFocusOn();
      i = 1;
    } catch (error) {
      setSelfieMove(false);
      setSelfiefile([]);
      setSelfieNewName("");
    }

    if (addCartClick === true) {
      if (i === 1) {
        setSelfieUploadFlag(true);
        setSelfieimgError(false);
      } else {
        setSelfieUploadFlag(false);
        setSelfieimgError(true);
      }
    }
  };

  const SelfieFocusOn = () => {
    setSelfieMove(true);
    setSelfiefileWording("Selfie:");
  };

  const SelfieFocusOff = () => {
    if (Selfiefile?.length !== 0) {
      setSelfieMove(true);
    } else {
      setSelfieMove(false);
    }
  };

  const PreviewSelfieload = () => {
    if (Selfiefile.length === 0) {
      setSelfieimgError(true);
      setSelfieErrorMsg("Please select a file to preview!");
    } else {
      setPrevDoc(URL.createObjectURL(Selfiefile));
      setPrevType("Selfie");

      setSalesModalPrevOpen(true);
    }
  };

  // convert img to text
  // const processImage = () => {
  //   setIDTextResult("");
  //   Tesseract.recognize(Regfile, "eng", {
  //     logger: (m) => {
  //       if (m.status === "recognizing text") {
  //         setProgress(m.progress);
  //       }
  //     },
  //   }).then(({ data: { text } }) => {
  //     setIDTextResult(text);
  //   });
  // };

  // RegNo Info
  const handleRegNo = (e) => {
    let valid = validateRegNo(e.target.value.toUpperCase(), province, pSize);

    // Validate after update
    setRegNoValidate(valid);

    setRegNo(formatRegistrationNo(e.target.value.toUpperCase(), pSize, valid));
  };

  // Update RegNo and formating after map click
  const UpdateRegNoValideMapClick = () => {
    setRun(true);
    setRunCount(0);

    if (RemoveFunnies(regNo.toUpperCase()).length > 8) {
      setRegNo(RemoveFunnies(regNo.toUpperCase()));
    }
    setRegNoValidate(validateRegNo(regNo.toUpperCase(), province));
  };

  const RegNoFocusOn = () => {
    setRegNoMove(true);
    regNoRef.current.focus();
  };

  const RegNoFocusOff = () => {
    if (regNo?.length !== 0) {
      setRegNoMove(true);
    } else {
      setRegNoMove(false);
    }
  };

  // name
  const handleName = (e) => {
    setName(formatProperCase(e.target.value.toUpperCase()));
  };

  const nameFocusOn = () => {
    setNameMove(true);
    nameRef.current.focus();
  };

  const nameFocusOff = () => {
    if (name?.length !== 0) {
      setNameMove(true);
    } else {
      setNameMove(false);
    }
  };

  // Surname
  const handleSurname = (e) => {
    setSurname(formatProperCase(e.target.value.toUpperCase()));
  };

  const surnameFocusOn = () => {
    setSurnameMove(true);
    surnameRef.current.focus();
  };

  const surnameFocusOff = () => {
    if (surname?.length !== 0) {
      setSurnameMove(true);
    } else {
      setSurnameMove(false);
    }
  };

  // ID No
  const handleIDNo = (e) => {
    setIDNo(formatFunny(e.target.value.toUpperCase()));

    if (1 === 2) {
      // console.log(progress);
      // console.log(processImage);
      console.log(provVisable);
      console.log(plate);
    }
  };

  const idNoFocusOn = () => {
    setIDNoMove(true);
    idNoRef.current.focus();
  };

  const idNoFocusOff = () => {
    if (idNo?.length !== 0) {
      setIDNoMove(true);
    } else {
      setIDNoMove(false);
    }
  };

  // Tool Tips
  // add to Cart checks

  const [required, setRequired] = useState("Add to Cart");
  const [requiredCnt, setrequiredCnt] = useState(0);
  const [reqMissing, setreqMissing] = useState(true);

  const addToCartLeave = () => {
    setBTNcartAddTT(false);
    if (addCartClick === false) {
      setIDimgError(false);
      setIdUploadFlag(false);
      setRegimgError(false);
      setRegUploadFlag(false);
      setSelfieimgError(false);
      setSelfieUploadFlag(false);
      setRegistrFlag(false);
      setNameFlag(false);
      setSurnameFlag(false);
      setIdPassportFlag(false);
      setIDTypeInValid(false);
    }
  };

  const addToCartEnter = () => {
    let CheckCnt = 0;
    setAddCartClick(false);

    if (testing === 0) {
      if (IDfileNewName.length === 0 || IDimgUploadSuccess === false) {
        CheckCnt = CheckCnt + 1;
        if (IDfileNewName.length === 0) setIDimgError(true);
        if (IDfileNewName.length > 0 && IDimgUploadSuccess === false)
          setIdUploadFlag(true);
      }
      if (RegNewFileName.length === 0 || RegimgUploadSuccess === false) {
        CheckCnt = CheckCnt + 1;
        if (RegNewFileName.length === 0) setRegimgError(true);
        if (RegNewFileName.length > 0 && RegimgUploadSuccess === false)
          setRegUploadFlag(true);
      }
      if (SelfieNewName.length === 0 || SelfimgUploadSuccess === false) {
        CheckCnt = CheckCnt + 1;
        if (SelfieNewName.length === 0) setSelfieimgError(true);
        if (SelfieNewName.length > 0 && SelfimgUploadSuccess === false)
          setSelfieUploadFlag(true);
      }
    }

    if (regNo.length === 0) {
      CheckCnt = CheckCnt + 1;
      setRegistrFlag(true);
    }
    if (name.length === 0) {
      CheckCnt = CheckCnt + 1;
      setNameFlag(true);
    }
    if (surname.length === 0) {
      CheckCnt = CheckCnt + 1;
      setSurnameFlag(true);
    }
    if (idNo.length === 0) {
      CheckCnt = CheckCnt + 1;
      setIdPassportFlag(true);
    }
    if (IdType === "" || idNoValid === true) {
      CheckCnt = CheckCnt + 1;
      setIDTypeInValid(true);
    }

    if (RegNoValidate.substring(0, 4) === "Fail") {
      CheckCnt = CheckCnt + 1;
      setRegistrFlag(true);
    }

    if (CheckCnt === 0) {
      setRequired("Add to Cart");
      setreqMissing(false);
    } else {
      setBTNcartAddTT(true);
      setreqMissing(true);
      setRequired("Missing * " + CheckCnt);
    }
    // console.log(CheckCnt)
    // console.log(reqMissing)
    setrequiredCnt(CheckCnt);
  };

  // add Plate Click
  const addPlateToCart = () => {
    setAddCartClick(true);

    if (requiredCnt === 0) {
      let UnqID =
        province + "^" + pSize + "^" + RemoveFunnies(regNo.toUpperCase());

      const cart = {
        id: UnqID,
        prodType: "plate",
        quant: 1,
        province: provinceWording,
        size: plateSize,
        pCase: pCaseSize,
        reg: regNo,
        plate: plate,
        price: platePrice,
        name: name,
        surname: surname,
        idNo: idNo,
        IDType: IdType,
        IDfile: RemoveFunniesIMG(IDfileNewName),
        Regfile: RemoveFunniesIMG(RegNewFileName),
        Selfie: RemoveFunniesIMG(SelfieNewName),
      };

      setCartItems((cartItems) => {
        if (cartItems.find((item) => item.id === UnqID) == null) {
          return [...cartItems, cart];
        } else {
          return cartItems.map((item) => {
            if (item.id === UnqID) {
              return { ...item, quant: item.quant + 1 };
            } else {
              return item;
            }
          });
        }
      });
      CartVoladd("plate");

      // Auto add Rivets
      if (rivetVol <= plateVol) {
        addRivetsToCart();
      }
      saveLocalSales();
    }
  };

  //
  const addBpPlateToCart = () => {
    // console.log(pSize + "^BackPlate");

    let UnqID = pSize + "^BackPlate";

    const cart = {
      id: UnqID,
      prodType: "bplate",
      quant: 1,
      province: "bp",
      size: plateSize,
      pCase: pCaseSize,
      reg: "",
      plate: bPlate,
      price: bpPrice,
      name: name,
      surname: surname,
      idNo: idNo,
      IDType: IdType,
      IDfile: "",
      Regfile: "",
      Selfie: "",
    };

    setCartItems((cartItems) => {
      if (cartItems.find((item) => item.id === UnqID) == null) {
        return [...cartItems, cart];
      } else {
        return cartItems.map((item) => {
          if (item.id === UnqID) {
            return { ...item, quant: item.quant + 1 };
          } else {
            return item;
          }
        });
      }
    });

    CartVoladd("bplate");
    saveLocalSales();
  };

  //
  const addRivetsToCart = () => {
    let UnqID = "Rivet";

    const cart = {
      id: UnqID,
      prodType: "rivet",
      quant: 1,
      province: "Rivets",
      size: "",
      pCase: rCaseSize,
      reg: "",
      plate: rivetImg,
      price: rivetPrice,
      name: name,
      surname: surname,
      idNo: idNo,
      IDType: IdType,
      IDfile: "",
      Regfile: "",
      Selfie: "",
    };

    setCartItems((cartItems) => {
      if (cartItems.find((item) => item.id === UnqID) == null) {
        return [...cartItems, cart];
      } else {
        return cartItems.map((item) => {
          if (item.id === UnqID) {
            return { ...item, quant: item.quant + 1 };
          } else {
            return item;
          }
        });
      }
    });

    CartVoladd("rivet");
    saveLocalSales();
  };

  const googleCloud = async (i) => {
    // Check if upload files are the same
    let idName = IDfile.name;
    let regName = Regfile.name;
    let selfieName = Selfiefile.name;

    if (typeof idName === "undefined" || idName === "") {
      idName = "defaultIDFileName";
    }
    if (typeof regName === "undefined" || regName === "") {
      regName = "defaultRegFileName";
    }
    if (typeof selfieName === "undefined" || selfieName === "") {
      selfieName = "defaultSelfFileName";
    }

    if (idName === regName || regName === selfieName || selfieName === idName) {
      msgClean();
      setMsgTxt("Please select different files for each upload!");
      setMsgType("Error");
      setMsgOpen(true);

      // Reset buttons
      if (i === "ID") {
        setIDBtnWait(false);
        setIDimgError(true);
        setIDErrorMsg("Please select a distinct file to upload!");
      }
      if (i === "Reg") {
        setRegBtnWait(false);
        setRegimgError(true);
        setRegErrorMsg("Please select a distinct file to upload!");
      }
      if (i === "Selfie") {
        setSelfieBtnWait(false);
        setSelfieimgError(true);
        setSelfieErrorMsg("Please select a distinct file to upload!");
      }

      return;
    }

    // run process
    let run = 0;
    let img = "";
    let name = "";

    if (i === "ID" && IDMove === true && IDfile.name !== "defaultIDFileName") {
      setIDBtnWait(true);
      run = 1;
      img = IDfile;
      name = IDfileNewName;

      // ID checks
      if (IDMove === false) {
        setIDimgError(true);
        setIDErrorMsg("Please select a file to preview!");
      }
    } else if (i === "ID" && IDMove === false) {
      setIDimgError(true);
      setIDErrorMsg("Please select a file to upload!");
    }
    // Reg checks
    if (
      i === "Reg" &&
      RegMove === true &&
      Regfile.name !== "defaultRegFileName"
    ) {
      setRegBtnWait(true);
      run = 1;
      img = Regfile;
      name = RegNewFileName;
    } else if (i === "Reg" && RegMove === false) {
      setRegimgError(true);
      setRegErrorMsg("Please select a file to upload!");
    }

    // Selfie checks
    if (
      i === "Selfie" &&
      SelfieMove === true &&
      Selfiefile.name !== "defaultSelfFileName"
    ) {
      setSelfieBtnWait(true);
      run = 1;
      img = Selfiefile;
      name = SelfieNewName;
    } else if (i === "Selfie" && SelfieMove === false) {
      setSelfieimgError(true);
      setSelfieErrorMsg("Please select a file to upload!");
    }

    if (run === 1) {
      try {
        const file = img;
        let uploadProgress = 0;

        // Clean name before encryption and upload
        let nameClean = RemoveFunniesIMG(name.trim());
        const formData = new FormData();
        formData.append("file", file);
        formData.append("image_Newname", nameClean);

        //  Hash build
        let pw = md5(nameClean + process.env.REACT_APP_UCNPASSPHRASE);
        formData.append("z", pw);

        let url = googleImgUpload;
        const result = await axios({
          method: "post",
          url: url,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            uploadProgress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            console.log("Upload Progres: " + uploadProgress + "%");
          },
        })
          .then((res) => res)
          .catch((err) => console.log(err));
        // console.log(result);

        // console.log(name)
        // console.log(userData)

        if (result.status === 200 && uploadProgress === 100) {
          if (i === "ID") {
            setIDimgUploadSuccess(true);
            setIDimgUpload(false);
          }
          if (i === "Reg") {
            setRegimgUploadSuccess(true);
            setRegimgUpload(false);
          }
          if (i === "Selfie") {
            setSelfimgUploadSuccess(true);
            setSelfimgUpload(false);
          }
        } else {
          msgClean();
          setMsgTxt(
            "Error with Image supplied!  Check your Image and try again"
          );
          setMsgType("Error");
          setMsgOpen(true);
        }
      } catch (error) {
        msgClean();
        setMsgTxt("Error uploading Image!  Please try again");
        setMsgType("Error");
        setMsgOpen(true);
      }
    }
    setIDBtnWait(false);
    setRegBtnWait(false);
    setSelfieBtnWait(false);
  };

  // console.log(province);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////-/////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div onClick={cookieModalCheck()} className="font-Montserrat">
        <div
          className={
            sidebarSize
              ? "flex-row s1580:flex s1580:justify-start ml-2 BNP-Trans500 min-w-[600px]"
              : "flex-row s1720:flex s1720:justify-start ml-2 BNP-Trans500 min-w-[600px]"
          }
        >
          {/* First Tile on page */}
          <div className="flex justify-start s1000:justify-center tx-1 px-1 ">
            <div className="relative h-[400px] w-[500px] s1000:h-[550px] s1000:w-[630px] min-w-[520px] BNP-Trans500">
              <p className="mt-5 absolute text-2xl ml-5 s1000:ml-16 text-BNPblue BNP-Trans500">
                Click on a Province:
              </p>
              {/*  Map of ZA */}
              <SalesPageMap />
              <div
                id="mapZA"
                onClick={() => {
                  UpdateRegNoValideMapClick();
                  // console.log("Map Clicked");
                }}
              ></div>
            </div>
          </div>

          <div
            className={
              sidebarSize
                ? "flex-row tx-1 px-1 gap-10 s1000:flex s1000:justify-center BNP-Trans500"
                : "flex-row gap-10 s1200:flex s1200:justify-center BNP-Trans500"
            }
          >
            {/* Second tile on page */}
            <div className="flex justify-start BNP-Trans500 min-w-[520px]">
              <div>
                <p className="mt-0 mb-3 text-5xl font-semibold flex justify-center s1000:mt-2 text-BNPblue BNP-Trans500">
                  {provinceWording}
                </p>
                {/* Number Plate Image */}
                <div className="flex justify-center items-center scale-[85%] -mt-4 -ml-6 s1000:m-0 s1000:scale-100 w-[520px] h-[205px] min-w-[520px]  BNP-Trans500">
                  <div className={" flex justify-center items-center "}>
                    {/* Plates size transition */}
                    {/* PlateA */}
                    <div className="absolute justify-center BNP-Trans700">
                      <CarouselImg
                        Ctrigger={plateA}
                        Cdirection={cDirectionA}
                        Cimgs={plateImgA}
                        CimgClass={casePlateSizeA}
                        CpSize={pSizeA}
                        CpWording={plateWording}
                        CpProvince={plateProvince}
                        CRnValidate={RegNoValidate}
                        CCProvince={province}
                      />
                    </div>
                    {/* Plate B */}
                    <div className="absolute justify-center BNP-Trans700">
                      <CarouselImg
                        Ctrigger={plateB}
                        Cdirection={cDirectionB}
                        Cimgs={plateImgB}
                        CimgClass={casePlateSizeB}
                        CpSize={pSizeB}
                        CpWording={plateWording}
                        CpProvince={plateProvince}
                        CRnValidate={RegNoValidate}
                        CCProvince={province}
                      />
                    </div>
                  </div>
                </div>
                {/* Select a plate size */}
                <div className="flex items-center justify-between">
                  <p className="m-2 font-bold text-BNPblue ">
                    Number Plate Sizes:
                  </p>
                  {/* Plate Pricing */}
                  <div className="flex justify-center w-auto h-9 text-lg px-4 items-center mr-16 s1000:mr-4 border-2 font-bold text-BNPblue border-BNPgoldLight rounded-md BNP-Trans500">
                    R {formatCurrency(platePrice)}
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <div>
                    {/* 520 */}
                    <div
                      className={
                        bpsize520
                          ? "bg-BNPgoldLight font-semibold w-[200px] rounded-md "
                          : ""
                      }
                    >
                      <p
                        id="size520"
                        onClick={() => {
                          setPSize("520");
                          setBpSize250(false);
                          setBpSize440(false);
                          setBpSize520(true);
                          setRun(true);
                          setRunCount(0);
                          setAction(true);
                          setRegNo(
                            formatRegistrationNo(
                              regNo.toUpperCase(),
                              "520",
                              RegNoValidate
                            )
                          );
                        }}
                        className="mb-3 w-[200px] h-[42px] flex BNP-PlateSize"
                      >
                        520mm x 110mm
                      </p>
                    </div>
                    {/* 440 */}
                    <div className="flex justify-center">
                      <div
                        className={
                          bpsize440
                            ? "bg-BNPgoldLight font-semibold w-[157px] rounded-md"
                            : ""
                        }
                      >
                        <p
                          id="size440"
                          onClick={() => {
                            setPSize("440");
                            setBpSize250(false);
                            setBpSize520(false);
                            setBpSize440(true);
                            setRun(true);
                            setRunCount(0);
                            setAction(true);
                            setRegNo(
                              formatRegistrationNo(
                                regNo.toUpperCase(),
                                "440",
                                RegNoValidate
                              )
                            );
                          }}
                          className="w-[157px] h-[46px]  flex BNP-PlateSize"
                        >
                          440mm x 120mm
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* 250 */}
                  <div
                    className={
                      bpsize250
                        ? "ml-4 w-[96px] h-[75px] bg-BNPgoldLight font-semibold rounded-md"
                        : "ml-4 w-[96px] h-[75px]"
                    }
                  >
                    <div className="flex-row BNP-PlateSize ">
                      <p
                        id="size250"
                        onClick={() => {
                          setPSize("250");
                          setBpSize520(false);
                          setBpSize440(false);
                          setBpSize250(true);
                          setRun(true);
                          setRunCount(0);
                          setAction(true);
                          setRegNo(
                            formatRegistrationNo(
                              regNo.toUpperCase(),
                              "250",
                              RegNoValidate
                            )
                          );
                        }}
                        className="flex items-center justify-center"
                      >
                        250mm
                      </p>
                      <p
                        id="size250"
                        onClick={() => {
                          setPSize("250");
                          setBpSize520(false);
                          setBpSize440(false);
                          setBpSize250(true);
                          setRun(true);
                          setRunCount(0);
                          setAction(true);
                        }}
                        className="flex items-center justify-center"
                      >
                        x
                      </p>
                      <p
                        id="size250"
                        onClick={() => {
                          setPSize("250");
                          setBpSize520(false);
                          setBpSize440(false);
                          setBpSize250(true);
                          setRun(true);
                          setRunCount(0);
                          setAction(true);
                        }}
                        className="flex items-center justify-center"
                      >
                        205mm
                      </p>
                    </div>
                  </div>
                </div>

                {/* ID image import */}
                <div>
                  <div className="flex mt-5">
                    <HashLink
                      smooth
                      to="/FAQ#ID_refQ3"
                      onClick={() => {
                        setFAQ3show(true);
                        setFAQbackShow(true);
                      }}
                    >
                      <faIcons.FaInfoCircle className="h-7 w-7 -mt-[12px] scale-75 rounded-full BNP-SubmitButtons-info" />
                    </HashLink>
                    <div className="flex items-center text-md">
                      <div
                        onClick={() => {
                          document.getElementById("IDClick")?.click();
                          setIDimgUploadSuccess(false);
                          setIDimgUpload(true);
                        }}
                        className="group h-[45px] BNP-LableDIV cursor-text"
                      >
                        <div
                          className={
                            IDimgError
                              ? "w-96 h-10 py-[7px] BNP-Input-thin BNP-ErrorBoarder"
                              : "w-96 h-10 py-[7px] BNP-Input-thin"
                          }
                        >
                          <div
                            onBlur={() => NameFocusOff()}
                            type="text"
                            autoComplete="off"
                            className={
                              IDimgUploadSuccess
                                ? "pl-3 w-[373px] focus:outline-none cursor-default text-nowrap overflow-hidden opacity-50"
                                : "pl-3 w-[373px] focus:outline-none cursor-text text-nowrap overflow-hidden"
                            }
                          >
                            {IDfile.name}
                          </div>
                        </div>

                        <h4
                          className={
                            IDMove
                              ? "w-[96px] BNP-LableMove -translate-y-[50px] text-BNPblue"
                              : "w-[250px] BNP-LableStart py-[4px] text-BNPblue bg-opacity-0"
                          }
                        >
                          <div className="flex">
                            {IDfileWording}
                            <div
                              className={
                                IDMove
                                  ? "hidden"
                                  : "font-semibold pl-1 text-red-700"
                              }
                            >
                              *
                            </div>
                          </div>
                        </h4>
                      </div>

                      <div className="relative">
                        <Transition
                          show={IDimgUpload}
                          enter="transition ease-in-out duration-500 transform"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in-out duration-500 transform"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <luIcons.LuUpload
                            onClick={() => googleCloud("ID")}
                            className={
                              IdUploadFlag
                                ? "absolute flex -m-5 h-10 w-10 scale-90 ml-1 BNP-SubmitButtons rounded-full p-2 BNP-ErrorBoarder"
                                : idBtnWait
                                ? "absolute flex -m-5 h-10 w-10 scale-90 ml-1 BNP-SubmitButtonsWait rounded-full p-2"
                                : "absolute flex -m-5 h-10 w-10 scale-90 ml-1 BNP-SubmitButtons rounded-full p-2"
                            }
                          />
                        </Transition>

                        <Transition
                          show={IDimgUploadSuccess}
                          enter="transition ease-in-out duration-500 transform"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in-out duration-500 transform"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <faIcons.FaCheck className="absolute flex -m-5 h-10 w-10 scale-90 ml-1 BNP-SubmitButtons-done rounded-full p-2" />
                        </Transition>
                      </div>

                      <input
                        id="IDClick"
                        type="file"
                        disabled={IDimgUploadSuccess}
                        onChange={ChangeIDfile}
                        accept="image/*"
                        className="hidden"
                      />
                    </div>
                  </div>

                  {/*  Error Message */}
                  <div className="flex justify-end w-[377px] ml-8 text-sm -mt-1">
                    <div
                      className={
                        IDimgError
                          ? "w-[312px] ml-1 font-semibold text-red-600 mr-1"
                          : "hidden"
                      }
                    >
                      <Transition
                        show={IDimgError}
                        enter="transition ease-in-out duration-500 transform"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in-out duration-500 transform"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        {IDErrorMsg}
                      </Transition>
                    </div>

                    <p onClick={PreviewIDload} className="BNP-Links ml-1 p-0">
                      preview
                    </p>
                  </div>
                </div>

                {/* Vehicle Registration image import */}
                <div>
                  <div className="flex mt-5">
                    <HashLink
                      smooth
                      to="/FAQ#ID_refQ3"
                      onClick={() => {
                        setFAQ3show(true);
                        setFAQbackShow(true);
                      }}
                    >
                      <faIcons.FaInfoCircle className="h-7 w-7 -mt-[12px] scale-75 rounded-full BNP-SubmitButtons-info" />
                    </HashLink>
                    <div className="flex items-center text-md">
                      {/* <progress value={progress} max={1}  className="ml-36 h-2 text-red-500  border-2" /> */}
                      <div className="flex items-center">
                        <div
                          onClick={() => {
                            document.getElementById("RegClick")?.click();
                            setRegimgUpload(true);
                            // setRegimgUploadSuccess(false);
                          }}
                          className="group h-[45px] BNP-LableDIV cursor-text"
                        >
                          <div
                            className={
                              RegimgError
                                ? "w-96 h-10 py-[7px] BNP-Input-thin BNP-ErrorBoarder"
                                : "w-96 h-10 py-[7px] BNP-Input-thin"
                            }
                          >
                            <div
                              onBlur={() => RegFocusOff()}
                              type="text"
                              autoComplete="off"
                              className={
                                RegimgUploadSuccess
                                  ? "pl-3 w-[373px] focus:outline-none cursor-default text-nowrap overflow-hidden opacity-50"
                                  : "pl-3 w-[373px] focus:outline-none cursor-text text-nowrap overflow-hidden"
                              }
                            >
                              {Regfile.name}
                            </div>
                          </div>

                          <h4
                            className={
                              RegMove
                                ? "w-[157px] BNP-LableMove -translate-y-[50px] text-BNPblue"
                                : "w-[350px] BNP-LableStart py-[4px] text-BNPblue bg-opacity-0"
                            }
                          >
                            <div className="flex">
                              {RegfileWording}
                              <div
                                className={
                                  RegMove
                                    ? "hidden"
                                    : "font-semibold pl-1 text-red-700"
                                }
                              >
                                *
                              </div>
                            </div>
                          </h4>
                        </div>

                        <div className="relative">
                          <Transition
                            show={RegimgUpload}
                            enter="transition ease-in-out duration-500 transform"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in-out duration-500 transform"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <luIcons.LuUpload
                              onClick={() => googleCloud("Reg")}
                              className={
                                regUploadFlag
                                  ? "absolute flex -m-5 h-10 w-10 scale-90 ml-1 BNP-SubmitButtons rounded-full p-2 BNP-ErrorBoarder"
                                  : regBtnWait
                                  ? "absolute flex -m-5 h-10 w-10 scale-90 ml-1 BNP-SubmitButtonsWait rounded-full p-2"
                                  : "absolute flex -m-5 h-10 w-10 scale-90 ml-1 BNP-SubmitButtons rounded-full p-2"
                              }
                            />
                          </Transition>

                          <Transition
                            show={RegimgUploadSuccess}
                            enter="transition ease-in-out duration-500 transform"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in-out duration-500 transform"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <faIcons.FaCheck className="absolute flex -m-5 h-10 w-10 scale-90 ml-1 BNP-SubmitButtons-done rounded-full p-2" />
                          </Transition>
                        </div>

                        <input
                          id="RegClick"
                          type="file"
                          disabled={RegimgUploadSuccess}
                          onChange={ChangeRegfile}
                          accept="image/*"
                          className="hidden"
                        />
                      </div>
                    </div>
                  </div>

                  {/*  Error Message */}
                  <div className="flex justify-end w-[377px] ml-8 text-sm -mt-1">
                    <div
                      className={
                        RegimgError
                          ? "w-[312px] ml-1 font-semibold text-red-600 mr-1"
                          : "hidden"
                      }
                    >
                      <Transition
                        show={RegimgError}
                        enter="transition ease-in-out duration-500 transform"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in-out duration-500 transform"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        {RegErrorMsg}
                      </Transition>
                    </div>

                    <p onClick={PreviewRegload} className="BNP-Links ml-1 p-0">
                      preview
                    </p>
                  </div>
                </div>

                {/* Selfie image import */}
                <div>
                  <div className="flex mt-5">
                    <HashLink
                      smooth
                      to="/FAQ#ID_refQ3"
                      onClick={() => {
                        setFAQ3show(true);
                        setFAQbackShow(true);
                      }}
                    >
                      <faIcons.FaInfoCircle className="h-7 w-7 -mt-[12px] scale-75 rounded-full BNP-SubmitButtons-info" />
                    </HashLink>
                    <div className="flex items-center text-md">
                      <div
                        onClick={() => {
                          document.getElementById("SelfieClick")?.click();
                          setSelfimgUpload(true);
                          // setSelfimgUploadSuccess(false);
                        }}
                        className="group h-[45px] BNP-LableDIV cursor-text"
                      >
                        <div
                          className={
                            SelfieimgError
                              ? "w-96 h-10 py-[7px] BNP-Input-thin BNP-ErrorBoarder"
                              : "w-96 h-10 py-[7px] BNP-Input-thin"
                          }
                        >
                          <div
                            onBlur={() => SelfieFocusOff()}
                            type="text"
                            autoComplete="off"
                            className={
                              SelfimgUploadSuccess
                                ? "pl-3 w-[373px] focus:outline-none cursor-default text-nowrap overflow-hidden opacity-50"
                                : "pl-3 w-[373px] focus:outline-none cursor-text text-nowrap overflow-hidden"
                            }
                          >
                            {Selfiefile.name}
                          </div>
                        </div>

                        <h4
                          className={
                            SelfieMove
                              ? "w-[46px] BNP-LableMove -translate-y-[50px] text-BNPblue"
                              : "w-[195px] BNP-LableStart py-[4px] text-BNPblue bg-opacity-0"
                          }
                        >
                          <div className="flex">
                            {SelfiefileWording}
                            <div
                              className={
                                SelfieMove
                                  ? "hidden"
                                  : "font-semibold pl-1 text-red-700"
                              }
                            >
                              *
                            </div>
                          </div>
                        </h4>
                      </div>

                      <div className="relative">
                        <Transition
                          show={SelfimgUpload}
                          enter="transition ease-in-out duration-500 transform"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in-out duration-500 transform"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <luIcons.LuUpload
                            onClick={() => googleCloud("Selfie")}
                            className={
                              selfieUploadFlag
                                ? "absolute flex -m-5 h-10 w-10 scale-90 ml-1 BNP-SubmitButtons rounded-full p-2 BNP-ErrorBoarder"
                                : selfieBtnWait
                                ? "absolute flex -m-5 h-10 w-10 scale-90 ml-1 BNP-SubmitButtonsWait rounded-full p-2"
                                : "absolute flex -m-5 h-10 w-10 scale-90 ml-1 BNP-SubmitButtons rounded-full p-2"
                            }
                          />
                        </Transition>

                        <Transition
                          show={SelfimgUploadSuccess}
                          enter="transition ease-in-out duration-500 transform"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in-out duration-500 transform"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <faIcons.FaCheck className="absolute flex -m-5 h-10 w-10 scale-90 ml-1 BNP-SubmitButtons-done rounded-full p-2" />
                        </Transition>
                      </div>

                      <input
                        id="SelfieClick"
                        type="file"
                        disabled={SelfimgUploadSuccess}
                        onChange={ChangeSelfiefile}
                        accept="image/*"
                        className="hidden"
                      />
                    </div>
                  </div>

                  {/*  Error Message */}
                  <div className="flex justify-end ml-8 w-[377px] text-sm -mt-1">
                    <div
                      className={
                        SelfieimgError
                          ? "w-[312px] ml-1 font-semibold text-red-600 mr-1"
                          : "hidden"
                      }
                    >
                      <Transition
                        show={SelfieimgError}
                        enter="transition ease-in-out duration-500 transform"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in-out duration-500 transform"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        {SelfieErrorMsg}
                      </Transition>
                    </div>

                    <p
                      onClick={PreviewSelfieload}
                      className="BNP-Links ml-1 p-0"
                    >
                      preview
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Third tile on page */}
            {/* Client and Reg info */}
            <div className="flex justify-start mt-5 s1000:mt-0 BNP-Trans500">
              <div className="flex-row justify-center items-center">
                {/* Registration No */}
                <div className="flex">
                  <div
                    onClick={() => RegNoFocusOn()}
                    className="group mt-3 h-[49px] BNP-LableDIV cursor-text"
                  >
                    <input
                      value={regNo}
                      onChange={handleRegNo}
                      // onKeyUp={handleRegNo}
                      onBlur={() => RegNoFocusOff()}
                      type="text"
                      ref={regNoRef}
                      autoComplete="off"
                      className={
                        registrFlag
                          ? "w-[200px] h-11 pl-4 BNP-Input-thin text-xl font-semibold text-BNPblue cursor-text BNP-ErrorBoarder"
                          : "w-[200px] h-11 pl-4 BNP-Input-thin text-xl font-semibold text-BNPblue cursor-text"
                      }
                    />
                    <h4
                      className={
                        regNoMove
                          ? "w-[108px] BNP-LableMove -translate-y-[54px]"
                          : "w-[160px] BNP-LableStart pt-[2px] bg-opacity-0"
                      }
                    >
                      <div className="flex">
                        Registration No:
                        <div
                          className={
                            regNoMove
                              ? "hidden"
                              : "font-semibold pl-1 text-red-700"
                          }
                        >
                          *
                        </div>
                      </div>
                    </h4>
                  </div>

                  <div className="flex-row justify-center items-center">
                    <div className="flex mt-4 h-5 w-8 ml-1">
                      <Transition
                        show={RegNoValidView}
                        enter="transition ease-in-out duration-500 transform"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in-out duration-500 transform"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <riIcons.RiCloseCircleLine className="text-3xl font-bold text-red-600 animate-pulse duration-300" />
                      </Transition>
                    </div>
                    <p className="mt-1 -ml-3 text-xs">
                      {9 - RemoveFunnies(regNo).length}
                    </p>
                  </div>
                </div>

                {/* Client Name */}
                <div
                  onClick={() => nameFocusOn()}
                  className="group mt-3 h-[49px] BNP-LableDIV cursor-text"
                >
                  <input
                    value={name}
                    onChange={handleName}
                    onKeyUp={handleName}
                    onBlur={() => nameFocusOff()}
                    type="text"
                    ref={nameRef}
                    autoComplete="off"
                    className={
                      nameFlag
                        ? "w-[250px] h-11 pl-4 BNP-Input-thin text-xl text-BNPblue cursor-text BNP-ErrorBoarder"
                        : "w-[250px] h-11 pl-4 BNP-Input-thin text-xl text-BNPblue cursor-text"
                    }
                  />
                  <h4
                    className={
                      nameMove
                        ? "w-[51px] BNP-LableMove -translate-y-[54px]"
                        : "w-[1px] BNP-LableStart pt-[2px] bg-opacity-0"
                    }
                  >
                    <div className="flex">
                      Name:
                      <div
                        className={
                          nameMove
                            ? "hidden"
                            : "font-semibold pl-1 text-red-700"
                        }
                      >
                        *
                      </div>
                    </div>
                  </h4>
                </div>

                {/* Surname Name */}
                <div
                  onClick={() => surnameFocusOn()}
                  className="group mt-3 h-[49px] BNP-LableDIV cursor-text"
                >
                  <input
                    value={surname}
                    onChange={handleSurname}
                    onKeyUp={handleSurname}
                    onBlur={() => surnameFocusOff()}
                    type="text"
                    ref={surnameRef}
                    autoComplete="off"
                    className={
                      surnameFlag
                        ? "w-[250px] h-11 pl-4 BNP-Input-thin text-xl text-BNPblue cursor-text BNP-ErrorBoarder"
                        : "w-[250px] h-11 pl-4 BNP-Input-thin text-xl text-BNPblue cursor-text"
                    }
                  />
                  <h4
                    className={
                      surnameMove
                        ? "w-[69px] BNP-LableMove -translate-y-[54px]"
                        : "w-[1px] BNP-LableStart pt-[2px] bg-opacity-0"
                    }
                  >
                    <div className="flex">
                      Surname:
                      <div
                        className={
                          surnameMove
                            ? "hidden"
                            : "font-semibold pl-1 text-red-700"
                        }
                      >
                        *
                      </div>
                    </div>
                  </h4>
                </div>

                {/* ID No & Passport */}
                <div>
                  <div className="flex">
                    <div
                      onClick={() => idNoFocusOn()}
                      className="group mt-3 h-[49px] BNP-LableDIV cursor-text"
                    >
                      <input
                        value={idNo}
                        onChange={handleIDNo}
                        onKeyUp={handleIDNo}
                        onBlur={() => idNoFocusOff()}
                        type="text"
                        ref={idNoRef}
                        autoComplete="off"
                        className={
                          idPassportFlag
                            ? "w-[225px] h-11 pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                            : "w-[225px] h-11 pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                        }
                      />
                      <h4
                        className={
                          idNoMove
                            ? "w-[105px] BNP-LableMove -translate-y-[54px]"
                            : "w-[158px] BNP-LableStart pt-[2px] bg-opacity-0"
                        }
                      >
                        <div className="flex">
                          ID No/Passport:
                          <div
                            className={
                              idNoMove
                                ? "hidden"
                                : "font-semibold pl-1 text-red-700"
                            }
                          >
                            *
                          </div>
                        </div>
                      </h4>
                    </div>
                    <div className="flex mt-4 h-5 w-8 ml-1">
                      <Transition
                        show={idNoValid}
                        enter="transition ease-in-out duration-500 transform"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in-out duration-500 transform"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <riIcons.RiCloseCircleLine className="text-3xl font-bold text-red-600 animate-pulse duration-300" />
                      </Transition>
                    </div>
                  </div>
                  <div
                    className={
                      idNoValid
                        ? "transition-height duration-500 ease-in-out h-[25px] s820:h-[30px] -mt-[10px] pt-[7px]" // Show
                        : "transition-height duration-500 ease-in-out h-0 opacity-0" // Hidden
                    }
                  >
                    <Transition
                      show={idNoValid}
                      enter="transition ease-in-out duration-500 transform"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition ease-in-out duration-500 transform"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="flex text-red-600 font-semibold">
                        Not a valid ID number!
                      </div>
                    </Transition>
                  </div>
                </div>

                {/* ID type */}

                <div className="group flex justify-center mt-1">
                  <div className=" scale-[.9] ">
                    <DropIDType />
                  </div>
                </div>

                {/* Plate volumes */}
                <div className="-mt-1">
                  <div className="flex items-center flex-col">
                    <div className="flex items-center justify-center gap-2">
                      <button
                        onMouseEnter={addToCartEnter}
                        onMouseLeave={() => addToCartLeave()}
                        onClick={addPlateToCart}
                        className="BNP-SubmitButtons-small w-[140px]"
                      >
                        Add to Cart
                      </button>
                      <div className="flex justify-center items-center w-[90px]">
                        <span className="text-xl font-mono text-BNPblue">
                          {plateVol}
                        </span>
                        <div className="text-sm ml-1 text-BNPblue">
                          No. {plateCountWording}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      BTNcartAddTT
                        ? "absolute ml-[43px] w-[115PX] BNP-Tooltip"
                        : "hidden"
                    }
                  >
                    <p
                      className={
                        reqMissing
                          ? "text-red-700 bg-BNPgoldLight text-center rounded-sm opacity-80"
                          : ""
                      }
                    >
                      {required}
                    </p>
                  </div>
                </div>
                {/* Extra Items */}
                <div className="">
                  <div className="flex justify-between mt-4 pt-2 mr-2 border-t-2 border-BNPblue border-opacity-40 w-auto">
                    <div className=" text-BNPblue text-2xl font-semibold">
                      Add Extras
                    </div>
                    {/* Plate Pricing */}
                    <div className="flex justify-center w-[100px] h-8 items-center mr-4 border-2 font-semibold text-BNPblue border-BNPgoldLight rounded-md">
                      R {formatCurrency(bpPrice)}
                    </div>
                  </div>
                  {/* Transition Backing Plates */}
                  <div className="flex justify-start items-center h-[100px] w-[280px] overflow-hidden">
                    {/* Transition Backing Plate 520 */}
                    <div className="absolute w-[280px] overflow-hidden">
                      <CarouselImgBP
                        Ctrigger={bpsize520}
                        Cdirection={bpcDirection}
                        Cimgs={bPlate520}
                        CimgClass="h-100 scale-[95%]"
                      />
                    </div>
                    {/* Transition Backing Plate 440 */}
                    <div className="absolute w-[280px] overflow-hidden">
                      <CarouselImgBP
                        Ctrigger={bpsize440}
                        Cdirection={bpcDirection}
                        Cimgs={bPlate440}
                        CimgClass="h-100 scale-[90%]"
                      />
                    </div>
                    {/* Transition Backing Plate 250 */}
                    <div className="flex items-center justify-center w-[280px]">
                      <div className="absolute w-[120px] h-[100px] overflow-hidden ">
                        <CarouselImgBP
                          Ctrigger={bpsize250}
                          Cdirection={bpcDirection}
                          Cimgs={bPlate250}
                          CimgClass="scale-90"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 pb-3 mr-2 border-b-2 BNP-border w-auto">
                    <div className="flex items-center flex-col mr-2">
                      <div className="flex items-center justify-center gap-2">
                        <button
                          onClick={addBpPlateToCart}
                          className="BNP-SubmitButtons-small w-[140px]"
                        >
                          Add to Cart
                        </button>
                        <div className="flex justify-center items-center w-[75px] cursor-default ">
                          <span className="text-xl font-mono text-BNPblue ml-3">
                            {bPlateVol}
                          </span>
                          <div className="text-sm ml-1 text-BNPblue">
                            {bpPlateCountWording}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between mt-2 mr-2 w-auto">
                
                  <div className="flex ml-4 w-[120px]">
                    <img src={rivetImg} alt="" className={rCaseSize} />
                  </div>
                  {/* Rivets Pricing */}
                  <div className="flex justify-center w-[100px] h-8 items-center mr-4 border-2 font-semibold text-BNPblue border-BNPgoldLight rounded-md">
                    R {formatCurrency(rivetPrice)}
                  </div>
                </div>

                <div className="mt-2 mr-4 w-auto mb-2">
                  <div className="flex items-center flex-col">
                    <div className="flex items-center justify-center gap-2">
                      <button
                        onClick={addRivetsToCart}
                        className="BNP-SubmitButtons-small w-[140px]"
                      >
                        Add to Cart
                      </button>
                      <div className="flex justify-center items-center w-[75px] cursor-default ">
                        <span className="text-xl font-mono text-BNPblue ml-3">
                          {rivetVol}
                        </span>
                        <div className="text-sm ml-1 text-BNPblue">
                          {revetCountWording}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <p className="flex items-center ml-2 mt-3"> {RegNoValidate}</p> */}
            </div>
          </div>
        </div>

        {/* {idTextResult && (
          <div className="box">
            <p>{idTextResult}</p>
          </div>
        )} */}
      </div>
    </>
  );
}
