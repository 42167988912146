import React, { useEffect, useState } from "react";

// Data lookups
import PlateInfo from "../data/Lookups/plates.json";
import bPlateInfo from "../data/Lookups/bplates.json";
import plateCaseInfo from "../data/Lookups/plateCase.json";
import otherSalesInfo from "../data/Lookups/otherForSaleData.json";

// formating
import ViewRegImg from "../utilities/regPlateimgView";
import validateRegNo from "../utilities/validateRegistrationNo";
import formatRegistrationNo from "../utilities/formatRegistrationNo";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function TrackingItemsProducts(props) {
  // console.log("Render TrackingItemsProducts");

  // State
  const [img, setImg] = useState("");
  const [imgCase, setImgCase] = useState("");
  const [imgDesc, setImgDesc] = useState("");
  const [newReg, setNewReg] = useState(props.reg);

  // console.log("a");
  useEffect(() => {
    // console.log(props);

    let provID;
    let plateSizeID;
    // Get Array index
    if (props.province === "NC") {
      provID = 0;
    } else if (props.province === "WP") {
      provID = 1;
    } else if (props.province === "NW") {
      provID = 2;
    } else if (props.province === "FS") {
      provID = 3;
    } else if (props.province === "GP") {
      provID = 4;
    } else if (props.province === "MP") {
      provID = 5;
    } else if (props.province === "L") {
      provID = 6;
    } else if (props.province === "ZN") {
      provID = 7;
    } else if (props.province === "EC") {
      provID = 8;
    } else if (props.prodType === "bplate") {
      provID = 9;
    }

    // Size Index
    if (props.size === "520") {
      plateSizeID = 0;
    } else if (props.size === "440") {
      plateSizeID = 1;
    } else if (props.size === "250") {
      plateSizeID = 2;
    } else {
      plateSizeID = 0;
    }

    // console.log(props.size)
    // console.log(plateSizeID)
    // console.log(provID);
    // plate info
    const numbers = [PlateInfo[provID]];
    const newValue = numbers.find((prov) => (prov = 1));

    if (props.prodType === "plate") {
      // console.log(props.prodType);
      // console.log(newValue);
    }

    // backing plate info
    const bpInfo = [bPlateInfo[plateSizeID]];
    const newBpInfo = bpInfo.find((size) => (size = 1));
    if (props.prodType === "bplate") {
      // console.log(props.prodType);
      // console.log(newBpInfo);
    }

    // Plate Case
    const pLateCase = [plateCaseInfo[plateSizeID]];
    const newPlateCase = pLateCase.find((size) => (size = 1));
    //  console.log(newPlateCase)

    // Rivet Into
    const rivets = [otherSalesInfo[0]];
    const rivetNewInfo = rivets.find((id) => (id = 1));

    // console.log(rivetNewInfo);
    if (props.prodType === "rivet") {
      setImg(rivetNewInfo.img);
      setImgCase(newPlateCase.rivetTracking);
      setImgDesc("(" + rivetNewInfo.desc + ") x " + props.quant);

      // console.log(props.prodType);
      // console.log(rivetNewInfo);
    }

    // Size info update i.e Pricing Backing plate, Cart Case and Size desctiption

    let sz = props.size;
    if (props.prodType === "plate") {
      // Format Reg no
      let valid = validateRegNo(props.reg, props.province);
      setNewReg(formatRegistrationNo(props.reg, props.size, valid));

      // Udate case and img
      setImgCase(newPlateCase.tracking);
      setImgDesc("(" + newPlateCase.sizeDesc + ") x " + props.quant);

      if (sz === "520") {
        setImg(newValue.size520);
        // console.log(520);
      } else if (sz === "440") {
        setImg(newValue.size440);
        // console.log(440);
      } else if (sz === "250") {
        setImg(newValue.size250);
        // console.log(250);
      }
    } else if (props.prodType === "bplate") {
      setImgCase(newPlateCase.tracking);
      setImgDesc("(" + newPlateCase.sizeDesc + ") x " + props.quant);

      if (sz === "520") {
        setImg(newBpInfo.bpsize520);
        // console.log(520);
      } else if (sz === "440") {
        setImg(newBpInfo.bpsize440);
        // console.log(440)
      } else if (sz === "250") {
        setImg(newBpInfo.bpsize250);
        // console.log(250)
      }
    }
  }, [props]);

  // console.log(newReg)

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="font-Montserrat flex-row justify-center w-auto p-3 text-BNPblue border-2 border-BNPblue border-opacity-40 rounded-md ">
        <ViewRegImg // Has to start with Uppercase letter
          Cformat={"2"}
          Order_No={props.Order_No}
          CType={props.prodType}
          Cimgs={img}
          CpID={props.province + "^" + props.size + "^" + props.reg}
          CregNo={newReg}
          Ccase={imgCase}
        />

        {/* <img src={img} alt="" className={imgCase} /> */}
        <p className="-mb-3 mt-[6px] ml-1 text-xs">{imgDesc}</p>
      </div>
    </>
  );
}
