import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import axios from "axios";

// Context
import UserContext from "../context/UserContext";
// import AdminContext from "../context/AdminContext";

/////////////////////////////////////////////////////////////////////////////////
export default function CallBacksPage() {
  // console.log("Render test CallBacksPage")

  // Navigate
  const nav = useNavigate();

  // Context
  const { userLogin, userAdmin } = useContext(UserContext);

  useEffect(() => {
    if (userLogin === false || userAdmin === false) {
      nav("/PageNotFound");
    }


    
  }, [nav, userLogin, userAdmin]);

  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="flex-auto justify-center items-center">
        <h1>Call Backs</h1>
        <h2>Who must we call.</h2>
      </div>
    </>
  );
}
