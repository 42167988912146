import React, { useContext } from "react";
import axios from "axios";

// Context
import AdminContext from "../context/AdminContext";
import modalContext from "../context/ModalContext";
import UserContext from "../context/UserContext";
import UrlContext from "../context/URLContext";
import SalesCartContext from "../context/SalesCartContext";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function AccountInfoPage() {
  // console.log("Render AccountInfoPage")

  // Context
  const {
    trackingSalesOrderInfo,
    salesOrderInfo,
    setSalesOrderInfo,
    trackingInfo,
    setTrackingInfo,
  } = useContext(AdminContext);
  const { accessToken } = useContext(UserContext);
  const { salesPaymentTypeUpdate } = useContext(UrlContext);
  const { setInvNo } = useContext(SalesCartContext);

  const {
    setMsgOpen,
    msgClean,
    setMsgTxt,
    setMsgType,
    setSalePayFastOpen,
    setSalePayShapOpen,
    setSaleYocoCashOpen,
    setSalePaymentType,
    salePaymentType,
    setOpenPaymentTypes,
    setTrackingRefresh,
  } = useContext(modalContext);

  const processPayments = () => {
    msgClean();

    if (
      salePaymentType === trackingSalesOrderInfo[0].paymentType.toLowerCase() &&
      salePaymentType !== "payfast"
    ) {
      setMsgOpen(true);
      setMsgTxt("Payment method did'nt change.");
      setMsgType("Info");
      setOpenPaymentTypes(false);
      return;
    }

    // check token is still valid
    // document.getElementById("validateClick")?.click();

    // Update Sales Payment on DB
    updateSalesPayment(trackingSalesOrderInfo[0].Order_No, salePaymentType);

    let n = trackingSalesOrderInfo[0].Order_No;
    var zerofilled = ("00000" + n).slice(-5);
    setInvNo("INV" + zerofilled);

    let payTP = "";

    if (salePaymentType === "payfast") {
      payTP = "PayFast";
      setSalePayFastOpen(true);
    } else if (salePaymentType === "payshap") {
      payTP = "PayShap";
      setSalePayShapOpen(true);
    } else if (salePaymentType === "yoco") {
      payTP = "Yoco";
      setSaleYocoCashOpen(true);
    } else if (salePaymentType === "cash") {
      payTP = "Cash";
      setSaleYocoCashOpen(true);
    }

    setTrackingRefresh(true);

    // Update trackingSalesOrderInfo array to the new paymentType
    trackingSalesOrderInfo[0].paymentType = payTP;

    // Update the setSalesOrderInfo array with the new salePaymentType for current Order_No inside salesOrderInfo array
    setSalesOrderInfo(
      salesOrderInfo.map((order) => {
        if (order.Order_No === trackingSalesOrderInfo[0].Order_No) {
          return { ...order, paymentType: payTP };
        } else {
          return order;
        }
      })
    );

    // Update the setTrackingInfo array with the new salePaymentType for current Order_No inside trackingInfo array
    setTrackingInfo(
      trackingInfo.map((order) => {
        if (order.Order_No === trackingSalesOrderInfo[0].Order_No) {
          return { ...order, paymentType: payTP };
        } else {
          return order;
        }
      })
    );
  };

  // Update Sales Payment on DB
  const updateSalesPayment = async (Order_No, payType) => {
    let url = salesPaymentTypeUpdate;
    const result = await axios
      .post(
        url,
        {
          Order_No,
          payType,
        },
        { headers: { Authorization: accessToken } } // JWS Token
      )
      .then((res) => res)
      .catch((err) => {
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });
    // console.log(result);

    if (result.status === 200) {
      if (result.data[0][0].Msg !== "Successful") {
        msgClean();
        setMsgTxt(
          "There was a network error. Please contact BNP admin for help!"
        );
        setMsgType("Error");
        setMsgOpen(true);
        return;
      }
    }
  };

  // console.log(trackingSalesOrderInfo);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {/* Payment Method */}
      <div className="flex-row flex-wrap s1020:flex gap-x-4 md:gap-y-4 p-3">
        {/* PayFast */}
        <div
          onClick={() => setSalePaymentType("payfast")}
          className="flex-row justify-center h-[130px] hover:shadow-md p-2 hover:shadow-BNPblue rounded-lg hover:scale-105  hover:cursor-pointer BNP-Trans300"
        >
          <div className="text-nowrap justify-center font-semibold text-center">
            Credit & Debit Card
          </div>
          <div className="flex-row pb-1">
            <div className="flex justify-center items-center h-[65px]">
              <div className="flex h-10 mt-4 my-3 justify-center">
                <img
                  src="../images/visaMasterCard.png"
                  alt="Credit & Debit Card"
                  id="visaMasterCard"
                />
              </div>
            </div>
            <div className="flex justify-center items-center">
              <input
                type="radio"
                name="payTypes"
                checked={salePaymentType === "payfast"}
                onChange={() => setSalePaymentType("payfast")}
                className="text-center hover:cursor-pointer w-6 h-6"
              />
            </div>
          </div>
        </div>

        {/* PayShap */}
        <div
          onClick={() => setSalePaymentType("payshap")}
          className="flex-row justify-center h-[130px] hover:shadow-md p-2 hover:shadow-BNPblue rounded-lg hover:scale-105 hover:cursor-pointer BNP-Trans300"
        >
          <div className="flex justify-center font-semibold">PayShap</div>
          <div className="flex-row pb-1">
            <div className="flex justify-center items-center h-[65px]">
              <div className="flex h-9">
                <img src="../images/payShap.png" alt="Payshap" id="payShap" />
              </div>
            </div>
            <div className="flex justify-center">
              <input
                type="radio"
                name="payTypes"
                checked={salePaymentType === "payshap"}
                onChange={() => setSalePaymentType("payshap")}
                className="text-center hover:cursor-pointer w-6 h-6"
              />
            </div>
          </div>
        </div>

        {/* Yoco Tap */}
        <div
          onClick={() => setSalePaymentType("yoco")}
          className="flex-row justify-center h-[130px] hover:shadow-md p-2 hover:shadow-BNPblue rounded-lg hover:scale-105 hover:cursor-pointer BNP-Trans300"
        >
          <div className="flex justify-center font-semibold">Yoco</div>
          <div className="flex-row pb-1">
            <div className="flex justify-center items-center h-[65px]">
              <div className="flex justify-center mt-2 h-[75px]">
                <img
                  src="../images/YocoTap2.webp"
                  alt="Yoco tap"
                  id="yocoTap"
                />
              </div>
            </div>
            <div className="flex justify-center">
              <input
                type="radio"
                name="payTypes"
                checked={salePaymentType === "yoco"}
                onChange={() => setSalePaymentType("yoco")}
                className="text-center hover:cursor-pointer w-6 h-6"
              />
            </div>
          </div>
        </div>

        {/* Cash */}
        <div
          onClick={() => setSalePaymentType("cash")}
          className="flex-row justify-center h-[130px] hover:shadow-md p-2 hover:shadow-BNPblue rounded-lg hover:scale-105 hover:cursor-pointer BNP-Trans300"
        >
          <div className="flex justify-center font-semibold">Cash</div>
          <div className="flex-row pb-1">
            <div className="flex justify-center items-center h-[65px]">
              <div className="flex justify-center h-[55px] -mt-2">
                <img src="../images/cash.png" alt="Pay cash" id="cashRands" />
              </div>
            </div>
            <div className="flex justify-center">
              <input
                type="radio"
                name="payTypes"
                checked={salePaymentType === "cash"}
                onChange={() => setSalePaymentType("cash")}
                className="text-center hover:cursor-pointer w-6 h-6"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          onClick={() => processPayments()}
          className="w-3/4 BNP-SubmitButtonsBlue"
        >
          Pay Now
        </button>
      </div>
    </>
  );
}
