import { useContext, useEffect, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import password from "secure-random-password";
import md5 from "md5";
import axios from "axios";

// Context
import UrlContext from "../context/URLContext";
import modalContext from "../context/ModalContext";
import UserContext from "../context/UserContext";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default function PasswordRest() {
  // console.log("Render PasswordRest");

  // Hashing
  const bcrypt = require("bcryptjs-react");

  // Context
  const { userPasswordReset, PwResetEmail } = useContext(UrlContext);
  const { testing, msgClean, setMsgOpen, setMsgTxt, setMsgType } =
    useContext(modalContext);
  const { userEmail } = useContext(UserContext);

  // State
  const eMailRef = useRef();
  const [eMail, seteMail] = useState(userEmail);
  const [eMailMove, seteMailMove] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const [EmailErrorMsg, setEmailErrorMsg] = useState("");
  const [eMailFocus, seteMailFocus] = useState(false);

  const [btnWait, setBtnWait] = useState(false);

  const effectRunOnce = useRef(false);

  useEffect(() => {
    if (effectRunOnce.current === false) {
      if (userEmail.length > 0 || userEmail !== eMail) {
        seteMail(userEmail);
      }

      if (eMail.length === 0 && testing === 1) {
        seteMail("admin@bespokenp.co.za"); // For Testing
      }
      effectRunOnce.current = true;
    }

    if (eMailFocus === false) {
      eMailRef.current.focus();
      seteMailFocus(true);
    }

    if (eMail.length > 0) {
      seteMailMove(true);
    }
  }, [eMailFocus, eMail, testing, userEmail]);

  // Email validate
  const handleUserEmail = (e) => {
    seteMail(e.target.value);
    EmailValidate();
  };

  const EmailValidate = () => {
    setEmailError(true);

    var emailcase =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (eMail.length < 4) {
      setEmailErrorMsg("Email length is too short.");
    } else if (eMail.charAt(eMail.length - 1) === ".") {
      setEmailErrorMsg("An email address can not end with a PERIOD!");
    } else if (!eMail.match(emailcase)) {
      setEmailErrorMsg("Invalid email address!");
    } else {
      setEmailErrorMsg("valid email supplied!");
      setEmailError(false);
    }
  };

  const eMailFocusOn = () => {
    seteMailMove(true);
    eMailRef.current.focus();
  };

  const eMailFocusOff = () => {
    if (eMail.length !== 0) {
      seteMailMove(true);
    } else {
      seteMailMove(false);
    }
  };

  const handleRecoverPass = async () => {
    setBtnWait(true);
    if (eMail.length !== 0 && EmailError === false) {
      //  Hash the new password before saving to db.
      let NewPw = password.randomString({ length: 12 });
      let hash = bcrypt.hashSync(NewPw, 10);

      // console.log(NewPw);
      // console.log(eMail);

      // Temp password for api
      let pw = md5(eMail + process.env.REACT_APP_UCNPASSPHRASE);

      try {
        // push new Hash password to db.
        let url = userPasswordReset;
        const result = await axios
          .post(url, {
            type: "r",
            password: hash,
            ucn_email: eMail,
            z: pw,
          })
          .then((res) => res)
          .catch((err) => {
            msgClean();
            setMsgTxt(
              "Network error!  Please check your Internet connection and try again."
            );
            setMsgType("Error");
            setMsgOpen(true);
          });
        // console.log(result);
        try {
          if (result.status === 200) {
            let Msg = result.data[0];
            if (Msg[0].Msg === "Error - Too many Reset in a row") {
              msgClean();
              setMsgTxt(
                "Too may Reset actions in a row, the account is now locked!!! Please contact us to have the account activated again."
              );
              setMsgType("Error");
              setMsgOpen(true);
            } else if (Msg[0].Msg === "Error - No such client") {
              msgClean();
              setMsgTxt('User "' + eMail + '" not found!');
              setMsgType("Error");
              setMsgOpen(true);
            } else {
              // // Send eMail to client
              url = PwResetEmail;
              const data = await axios
                .post(url, {
                  body: {
                    email: eMail,
                    password: NewPw,
                  },
                })
                .then((res) => res)
                .catch((err) => {
                  msgClean();
                  setMsgTxt(
                    "Network error!  Please check your Internet connection and try again."
                  );
                  setMsgType("Error");
                  setMsgOpen(true);
                });
              // console.log(data);

              // Message to webpage
              if (data.status === 200) {
                msgClean();
                setMsgTxt(
                  `Your temporary "P@s5w0rd" ${String.fromCodePoint(
                    "0x1f609"
                  )} has been sent to your Email!`
                );
                setMsgType("Success");
                setMsgOpen(true);

                if (testing === 0) {
                  seteMail("");
                  seteMailMove(false);
                }
              }
            }
          } else {
            msgClean();
            setMsgTxt("Network error!  Please try again later.");
            setMsgType("Error");
            setMsgOpen(true);
          }
        } catch (error) {
          console.log(error);
        }
      } catch (error) {}
    }
    setBtnWait(false);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div className="flex h-full justify-center font-Montserrat bg-BNPgoldLightLight">
        <div className="flex-row items-center justify-between pt-4">
          <h1 className="flex justify-center text-5xl font-bold">
            Recover Password
          </h1>
          <div className="mt-6 ">
            <p className="flex justify-center text-xl">
              Please enter your e-mail address:
            </p>

            {/* Email */}
            <div className="flex justify-center">
              <div
                onClick={() => eMailFocusOn()}
                className="group mt-7 h-[50px] mb-[20px] BNP-LableDIV cursor-text"
              >
                <input
                  value={eMail}
                  onChange={handleUserEmail}
                  onKeyUp={handleUserEmail}
                  onBlur={() => eMailFocusOff()}
                  type="email"
                  ref={eMailRef}
                  autoComplete="off"
                  className={
                    EmailError
                      ? "w-[400px] h-12 BNP-Input BNP-ErrorBoarder"
                      : "w-[400px] h-12 BNP-Input cursor-text"
                  }
                />
                <h4
                  className={
                    eMailMove
                      ? "w-[48px] BNP-LableMove -translate-y-[59px]"
                      : "w-[70px] BNP-LableStart bg-opacity-0"
                  }
                >
                  E-mail
                </h4>

                {/*  Error Message */}
                <div
                  className={
                    EmailError
                      ? "-translate-y-[18px] ml-2 block text-sm font-semibold text-red-600"
                      : "hidden"
                  }
                >
                  <Transition
                    show={EmailError}
                    enter="transition ease-in-out duration-500 transform"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in-out duration-500 transform"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    {EmailErrorMsg}
                  </Transition>
                </div>
              </div>
            </div>

            {/* Recovery Button */}
            <div className="flex justify-center py-2 mr-1">
              <button
                onClick={() => handleRecoverPass()}
                className={
                  btnWait
                    ? "w-[300px] px-8 text-lg rounded-md p-3 BNP-SubmitButtonsBlueWait"
                    : EmailError
                    ? "w-[300px] px-8 text-lg rounded-md p-3 BNP-SubmitButtons hover:cursor-not-allowed"
                    : "w-[300px] px-8 text-lg rounded-md p-3 BNP-SubmitButtons"
                }
              >
                Recover
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
