import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import md5 from "md5";
import axios from "axios";

// Context
import UrlContext from "../context/URLContext";
import ModalContext from "../context/ModalContext";
import userContext from "../context/UserContext";

// Icons
import * as io5Icons from "react-icons/io5";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function CreateAcctPage() {
  // console.log("Render CreateAcctPage");

  const nav = useNavigate();

  // State
  const [userEmail, setuserEmail] = useState("");
  const [EmailError, setEmailError] = useState(false);
  const [EmailErrorMsg, setEmailErrorMsg] = useState("");
  const [CheckBoxClicked, setCheckBoxClicked] = useState(false);

  const [btnDisable, setBtnDisable] = useState(false);
  const [pswTotal, setPswTotal] = useState(false);

  const pswRef = useRef();
  const [userPsw, setUserPsw] = useState("");
  const [pswMove, setPswMove] = useState(false);
  const [pswError, setPswError] = useState(true);
  const [pswErrorMsg, setPswErrorMsg] = useState("");

  const [pwShow, setPwShow] = useState(false);

  const psw2Ref = useRef();
  const [userPsw2, setUserPsw2] = useState("");
  const [psw2Move, setPsw2Move] = useState(false);
  const [psw2Error, setPsw2Error] = useState(true);
  const [psw2ErrorMsg, setPsw2ErrorMsg] = useState("");

  const [progBar1, setProgBar1] = useState(false);
  const [progBar2, setProgBar2] = useState(false);
  const [progBar3, setProgBar3] = useState(false);
  const [progBar4, setProgBar4] = useState(false);
  const [progBar5, setProgBar5] = useState(false);
  const [progBar6, setProgBar6] = useState(false);
  const [progBar7, setProgBar7] = useState(false);
  const [progBar8, setProgBar8] = useState(false);
  const [progBar9, setProgBar9] = useState(false);
  const [progBar10, setProgBar10] = useState(false);

  const [clearError, setClearError] = useState(0);

  const [pw1Flag, setPW1Flag] = useState(false);
  const [pw2Flag, setPW2Flag] = useState(false);
  const [tcFlag, setTCFlag] = useState(false);

  // Context
  const { userRegister } = useContext(UrlContext);
  const {
    setTsAndCsButton,
    msgClean,
    setMsgOpen,
    setMsgTxt,
    setMsgType,
    setLinkMsg,
    setNavLink,
    setNavLinkTxt,
    setUserCreateClick,
    userInfoCompleted,
    addressCompleted,
    userCreateClick,
  } = useContext(ModalContext);

  const {
    newUser,
    IdType,
    setNewUser,
    setUserPassword,
    Province,
    userLogOut,
  } = useContext(userContext);

  // State
  const effectRanOnce = useRef(false);
  const ValidateRanOnce = useRef(false);

  const [btnWait, setBtnWait] = useState(false);

  // Hashing
  const bcrypt = require("bcryptjs-react");

  useEffect(() => {
    if (userPsw.length === 0 && userCreateClick === true) {
      setPW1Flag(true);
    } else {
      setPW1Flag(false);
    }

    if (userPsw2.length === 0 && userCreateClick === true) {
      setPW2Flag(true);
    } else {
      setPW2Flag(false);
    }

    if (CheckBoxClicked === false && userCreateClick === true) {
      setTCFlag(true);
    } else {
      setTCFlag(false);
    }

    // console.log(CheckCnt);
  }, [CheckBoxClicked, userCreateClick, userPsw, userPsw2]);

  useEffect(() => {
    // Refresh already captured info it clicked away
    if (effectRanOnce.current === false) {
      if (newUser.find((data) => data.eMail_Address !== "abc@123.com")) {
        setuserEmail(newUser[0].eMail_Address);
      }
      effectRanOnce.current = true;
      // console.log("Ran eMail data refresh once only");
    }

    if (
      pswTotal >= 4 &&
      userPsw.length !== 0 &&
      userPsw === userPsw2 &&
      EmailError === false &&
      userEmail.length !== 0 &&
      CheckBoxClicked === true &&
      userInfoCompleted === true &&
      addressCompleted === true
    ) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }

    pswChecks(userPsw);

    // Password1 and password2 match confirm
    setPsw2Error(false);
    if (userPsw2 !== userPsw && userPsw2.length !== 0) {
      setPsw2Error(true);
      setPsw2ErrorMsg("Passwords don't match!");
    }

    if (userPsw.length !== 0) {
      setPswMove(true);
    }
    if (userPsw2.length !== 0) {
      setPsw2Move(true);
    }
  }, [
    newUser,
    pswTotal,
    psw2Error,
    userPsw,
    userPsw2,
    EmailError,
    userEmail,
    CheckBoxClicked,
    userInfoCompleted,
    addressCompleted,
  ]);

  useEffect(() => {
    if (clearError === 0) {
      msgClean();
      setClearError(1);
    }
  }, [clearError, msgClean]);

  // Update Array with email address
  function eMailUpdate(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            eMail_Address: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // UserName
  const handleUserEmail = (e) => {
    let email = e.target.value;
    setuserEmail(email);
    eMailUpdate(email);
    ValidateRanOnce.current = true;
  };

  // Password 1
  const pswFocusOn = () => {
    setPswMove(true);
    pswRef.current.focus();
  };

  const pswFocusOff = () => {
    if (userPsw.length !== 0) {
      setPswMove(true);
    } else {
      setPswMove(false);
    }
  };

  // Password strenth confirm checks
  function pswChecks(i) {
    setPswError(true);
    let a = 10;
    // regular expressions to validate password strength!
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    var special = /(?=.*[!@#$%&? "])/g;

    if (i.length < 8 && i.length !== 0) {
      setPswErrorMsg("Password should be more than 8 characters.");
      a = a - 2;
    }
    if (!i.match(lowerCase) && i.length !== 0) {
      setPswErrorMsg("Password should contains lowercase letters!");
      a = a - 2;
    }
    if (!i.match(upperCase) && i.length !== 0) {
      setPswErrorMsg("Password should contain uppercase letters!");
      a = a - 2;
    }
    if (!i.match(numbers) && i.length !== 0) {
      setPswErrorMsg("Password should contains numbers also!");
      a = a - 2;
    }
    if (!i.match(special) && i.length !== 0) {
      setPswErrorMsg("Password should contains special characters!");
      a = a - 2;
    }

    setProgBar1(false);
    setProgBar2(false);
    setProgBar3(false);
    setProgBar4(false);
    setProgBar5(false);
    setProgBar6(false);
    setProgBar7(false);
    setProgBar8(false);
    setProgBar9(false);
    setProgBar10(false);

    if (a >= 2) {
      setProgBar1(true);
      setProgBar2(true);
    }
    if (a >= 4) {
      setProgBar1(true);
      setProgBar2(true);
      setProgBar3(true);
      setProgBar4(true);
    }
    if (a >= 6) {
      setProgBar1(true);
      setProgBar2(true);
      setProgBar3(true);
      setProgBar4(true);
      setProgBar5(true);
      setProgBar6(true);
    }
    if (a >= 8) {
      setProgBar1(true);
      setProgBar2(true);
      setProgBar3(true);
      setProgBar4(true);
      setProgBar5(true);
      setProgBar6(true);
      setProgBar7(true);
      setProgBar8(true);
    }
    if (a === 10) {
      setProgBar1(true);
      setProgBar2(true);
      setProgBar3(true);
      setProgBar4(true);
      setProgBar5(true);
      setProgBar6(true);
      setProgBar7(true);
      setProgBar8(true);
      setProgBar9(true);
      setProgBar10(true);
      setPswError(false);
    }

    if (i.length === 0) {
      setProgBar1(false);
      setProgBar2(false);
      setProgBar3(false);
      setProgBar4(false);
      setProgBar5(false);
      setProgBar6(false);
      setProgBar7(false);
      setProgBar8(false);
      setProgBar9(false);
      setProgBar10(false);
    }

    setPswTotal(a);
    // console.log(a);
  }

  // Password 2 info
  const psw2FocusOn = () => {
    setPsw2Move(true);
    psw2Ref.current.focus();
  };

  const psw2FocusOff = () => {
    if (userPsw2.length !== 0) {
      setPsw2Move(true);
    } else {
      setPsw2Move(false);
    }
  };

  // T's & C's
  const termsChange = (e) => {
    if (e.target.checked) {
      setCheckBoxClicked(true);
      ArrayUpdateTs_Cs(1);
    } else {
      setCheckBoxClicked(false);
      ArrayUpdateTs_Cs(0);
    }
  };

  // Update Array with Ts_Cs
  function ArrayUpdateTs_Cs(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Ts_Cs: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // Update Array with Password address and ID_Type
  function passwordUpdateArray(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Password: value,
            Province: Province,
            ID_Type: IdType,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // console.log(newUser);
  // Submit Create account
  const Submit = async () => {
    setBtnWait(true);
    setClearError(0);
    //  Hash password before sending to db
    let hash = bcrypt.hashSync(userPsw, 10);
    setUserPassword(hash);
    passwordUpdateArray(hash);
    // console.log(hash);
    // Push User data to mySQL db.

    //  Hash build
    let newEmail = newUser[0].eMail_Address;
    let pw = md5(newEmail + process.env.REACT_APP_UCNPASSPHRASE);

    let error = 0;
    let url = userRegister;
    const result = await axios
      .post(url, {
        eMail_Address: newEmail,
        admin: newUser[0].admin,
        Business_Name: newUser[0].Business_Name,
        VAT_Number: newUser[0].VAT_Number,
        Name: newUser[0].Name,
        Prefered_Name: newUser[0].Prefered_Name,
        Surname: newUser[0].Surname,
        ID_No: newUser[0].ID_No,
        ID_Type: IdType,
        Cell_No: newUser[0].Cell_No,
        Telephone: newUser[0].Telephone,
        Ts_Cs: newUser[0].Ts_Cs,

        Recipient_Name: newUser[0].Recipient_Name,
        Recipient_Cell: newUser[0].Recipient_Cell,
        DeliveryBusiness_Name: newUser[0].DeliveryBusiness_Name,
        Complex_Building: newUser[0].Complex_Building,
        Street_Address: newUser[0].Street_Address,
        Suburb: newUser[0].Suburb,
        City: newUser[0].City,
        Province: Province,
        Postal_Code: newUser[0].Postal_Code,

        Password: hash,
        z: pw,
      })
      .then((res) => res)
      .catch((err) => {
        error = 1;
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });
    // console.log(result);

    if (error === 0) {
      if (result.status === 200) {
        if (result.data.length > 0) {
          msgClean();
          setMsgTxt("Your Account has been created Successfully!");
          setMsgType("Success");
          setLinkMsg("");
          setNavLink("loginModal");
          setNavLinkTxt("Login");
          nav("/");
          userLogOut();

          setMsgOpen(true);
        }
         else {
          msgClean();
          setMsgTxt(
            "The eMail address " +
              String.fromCodePoint(34) +
              newUser[0].eMail_Address +
              String.fromCodePoint(34) +
              " already exists!"
          );
          setMsgType("Error");
          setLinkMsg("Recover your password:");
          setNavLink("/PasswordRecover");
          setNavLinkTxt("Forgot Password");
          setMsgOpen(true);
        }
      }
    }
    setBtnWait(false);
  };

  useEffect(() => {
    // Email validate
    if (ValidateRanOnce.current === true) {
      if (userEmail.length > 4) {
        setEmailError(true);

        var emailcase =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (userEmail.length < 4) {
          setEmailErrorMsg("Email length is too short.");
        } else if (userEmail.charAt(userEmail.length - 1) === ".") {
          setEmailErrorMsg("An email address can not end with a PERIOD!");
        } else if (!userEmail.match("@")) {
          setEmailErrorMsg("An email address must have a @ in it!");
        } else if (!userEmail.match(emailcase)) {
          setEmailErrorMsg("Invalid email address!");
        } else {
          setEmailErrorMsg("valid email supplied!");
          setEmailError(false);
        }
      }
      ValidateRanOnce.current = false;
    }
  }, [userEmail.length, userEmail]);

  // console.log(EmailError);
  // console.log(PswError);
  // console.log(Psw2Error);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="relative p-2 space-y-4 md:space-y-4 md:p-4 md:ml-2">
        <h1 className="text-3xl font-bold">Create and account</h1>
        <div className=" text-xl">
          {/* UserName */}
          <div className="group h-[100px]">
            <p className="block mb-1 font-medium">Your email</p>
            <input
              value={userEmail}
              onChange={handleUserEmail}
              type="email"
              name="email"
              id="createemail"
              autoComplete="on"
              placeholder="name@company.co.za"
              className={
                EmailError
                  ? "w-[500px] BNP-Input border-4 focus:ring-4 focus:ring-red-500 focus:border-red-600 border-red-600"
                  : "w-[500px] BNP-Input"
              }
            />

            <div
              className={
                setEmailError
                  ? "block pt-2 text-sm font-semibold text-red-600"
                  : "block pt-2 text-sm font-semibold text-green-600"
              }
            >
              <Transition
                show={EmailError}
                enter="transition ease-in-out duration-500 transform"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in-out duration-500 transform"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {EmailErrorMsg}
              </Transition>
            </div>
          </div>
          {/* New Password */}
          <div className="flex justify-start border-t-2 border-BNPblue border-opacity-40 mt-[18px]">
            <div className="flex-row justify-center mt-[18px] h-[75px] text-md ml-5">
              <div
                onClick={() => pswFocusOn()}
                className="relative group w-[350px] h-[40px] BNP-LableDIV cursor-text"
              >
                <input
                  value={userPsw}
                  onChange={(e) => setUserPsw(e.target.value)}
                  onBlur={() => pswFocusOff()}
                  type={pwShow ? "text" : "password"}
                  ref={pswRef}
                  autoComplete="off"
                  className={
                    pw1Flag
                      ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                      : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                  }
                />
                <h4
                  className={
                    pswMove
                      ? "w-[100px] BNP-LableMove -translate-y-[50px] "
                      : "w-[170px] BNP-LableStart mt-[3px] bg-opacity-0"
                  }
                >
                  <div className="flex">
                    New Password
                    <div
                      className={
                        pswMove ? "hidden" : "font-semibold pl-1 text-red-700"
                      }
                    >
                      *
                    </div>
                  </div>
                </h4>
                <div
                  className="absolute flex justify-center items-center top-[6px] right-2 h-8 w-8 scale-105 cursor-pointer"
                  onClick={() => {
                    if (userPsw?.length >= 1) {
                      setPwShow(!pwShow);
                    }
                  }}
                  onMouseLeave={() => {
                    setPwShow(false);
                  }}
                >
                  <div className="relative flex justify-center items-center">
                    <io5Icons.IoEyeSharp
                      className={
                        pwShow
                          ? "absolute transition-height duration-300 ease-in-out" // Show
                          : "absolute transition-height duration-300 ease-in-out opacity-0" // Hidden
                      }
                    />
                    <io5Icons.IoEyeOffSharp
                      className={
                        pwShow
                          ? "absolute transition-height duration-300 ease-in-out opacity-0" // Hidden
                          : "absolute transition-height duration-300 ease-in-out" // Show
                      }
                    />
                  </div>
                </div>
              </div>
              {/* Password status bar */}
              <div className="flex justify-center w-100">
                <div className="flex justify-between w-[98.5%] h-[10px] border-2 rounded-md border-BNPblue border-opacity-40">
                  <div
                    className={
                      progBar1
                        ? "flex justify-center w-100 bg-gradient-to-r from-red-800 to-orange-800"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar2
                        ? "flex justify-center w-100 bg-gradient-to-r from-orange-800 to-orange-600"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar3
                        ? "flex justify-center w-100 bg-gradient-to-r from-orange-600 to-orange-500"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar4
                        ? "flex justify-center w-100 bg-gradient-to-r from-orange-500 to-orange-400"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar5
                        ? "flex justify-center w-100 bg-gradient-to-r from-orange-400 to-orange-300"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar6
                        ? "flex justify-center w-100 bg-gradient-to-r from-orange-300 to-orange-200"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar7
                        ? "flex justify-center w-100 bg-gradient-to-r from-orange-200 to-green-300"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar8
                        ? "flex justify-center w-100 bg-gradient-to-r from-green-300 to-green-500"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar9
                        ? "flex justify-center w-100 bg-gradient-to-r from-green-500 to-green-800"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar10
                        ? "flex justify-center w-100 bg-gradient-to-r from-green-800 to-green-900"
                        : "flex justify-center w-100"
                    }
                  ></div>
                </div>
              </div>

              {/*  Error Message */}
              <div
                className={
                  pswError
                    ? "ml-2 mt-[2px] block text-sm font-semibold text-red-600"
                    : "hidden"
                }
              >
                <Transition
                  show={pswError}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {pswErrorMsg}
                </Transition>
              </div>
            </div>
          </div>

          {/* Confirm Password */}
          <div className="flex justify-start ml-5">
            <div className="flex-row justify-center mt-[9px] h-[65px] text-md">
              <div
                onClick={() => psw2FocusOn()}
                className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
              >
                <input
                  value={userPsw2}
                  onChange={(e) => setUserPsw2(e.target.value)}
                  onBlur={() => psw2FocusOff()}
                  type="password"
                  ref={psw2Ref}
                  autoComplete="off"
                  className={
                    pw2Flag
                      ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                      : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                  }
                />
                <h4
                  className={
                    psw2Move
                      ? "w-[120px] BNP-LableMove -translate-y-[50px]"
                      : "w-[200px] BNP-LableStart mt-[3px] bg-opacity-0"
                  }
                >
                  <div className="flex">
                    Confrim Password
                    <div
                      className={
                        psw2Move ? "hidden" : "font-semibold pl-1 text-red-700"
                      }
                    >
                      *
                    </div>
                  </div>
                </h4>
              </div>
              {/*  Error Message */}
              <div
                className={
                  psw2Error
                    ? "ml-2 mt-[2px] block text-sm font-semibold text-red-600"
                    : "hidden"
                }
              >
                <Transition
                  show={psw2Error}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {psw2ErrorMsg}
                </Transition>
              </div>
            </div>
          </div>

          {/* T's & C's */}
          <div className="flex items-start text-l font-medium mt-4">
            <div className=" flex">
              <div className="flex relative">
                <div className="ml-[28px]"></div>
                <div
                  className={
                    tcFlag
                      ? "absolute flex justify-center items-center w-[32px] h-[32px] BNP-ErrorBoarder"
                      : "absolute flex justify-center items-center w-[32px] h-[32px]"
                  }
                >
                  <input
                    id="terms"
                    aria-describedby="terms"
                    type="checkbox"
                    onChange={termsChange}
                    className="w-6 h-6"
                  />
                </div>
              </div>
            </div>

            <div className="ml-5 text-l font-medium">
              <div className="font-medium text-gray-500">
                I accept the
                <Link
                  tabIndex="-1"
                  to={"/Ts&Cs"}
                  onClick={() => setTsAndCsButton(false)}
                  className="BNP-Links"
                >
                  Terms and Conditions
                </Link>
              </div>
            </div>
          </div>

          {/* Create Account Btn */}
          <div className="flex justify-center">
            {btnDisable === true ? (
              <div>
                <div
                  onMouseEnter={() => setUserCreateClick(true)}
                  // onMouseLeave={() => setUserCreateClick(false)}
                  className="mt-5 w-[400px] md:w-[500px] BNP-SubmitButtons text-center cursor-not-allowed hover:cursor-not-allowed"
                >
                  Create an account
                </div>
              </div>
            ) : (
              <div>
                <button
                  tabIndex="-1"
                  onClick={Submit}
                  className={
                    btnWait
                      ? "mt-5 w-[400px] md:w-[500px] BNP-SubmitButtonsWait"
                      : "mt-5 w-[400px] md:w-[500px] BNP-SubmitButtons BNP-SubmitButtons"
                  }
                >
                  Create an account
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
