import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Pages
import UserInfoPageUpdate from "./UserInfoPage_Update";
import RegisterAddressPageUpdate from "./RegisterAddressPage_Update";

// Context

import ModalContext from "../context/ModalContext";
import UserContext from "../context/UserContext";
import UrlContext from "../context/URLContext";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function AccountInfoPage(props) {
  // console.log("Render AccountInfoPage")

  // Context
  const { userAccInfoUpdate } = useContext(UrlContext);
  const {
    userID,
    userLogin,
    userAdmin,
    accessToken,
    setAcctInfoUpdate,
    // acctInfoUpdate,
    setPrefName,
  } = useContext(UserContext);
  const {
    setUserCreateClick,
    userInfoCompleted,
    setUserInfoCompleted,
    addressCompleted,
    setAddressCompleted,
    userAccClientInfo,
    userAccAddress,
    msgClean,
    setMsgOpen,
    setMsgTxt,
    setMsgType,
  } = useContext(ModalContext);

  // State
  const [btnWait, setBtnWait] = useState(false);
  const [userUpdate, setUserUpdate] = useState(false);

  // Navigate
  const nav = useNavigate();

  useEffect(() => {
    if (userLogin === false) {
      nav("/PageNotFound");
    }

    if (userInfoCompleted === true || addressCompleted === true) {
      setUserUpdate(true);
    } else {
      setUserUpdate(false);
    }
  }, [nav, userLogin, userAdmin, userInfoCompleted, addressCompleted]);

  const updateAcctInfo = async () => {
    if (userUpdate === false) {
      return;
    }
    if (userInfoCompleted || addressCompleted) {
      setBtnWait(true);

      // console.log("User info updated: " + userInfoCompleted);
      // console.log("Address info updated: " + addressCompleted);

      updateAccInfo();

      // Update array
      const updatedInfo = {
        Business_Name: userAccClientInfo.Business_Name,
        VAT_Number: userAccClientInfo.VAT_Number,
        Name: userAccClientInfo.Name,
        Prefered_Name: userAccClientInfo.Prefered_Name,
        Surname: userAccClientInfo.Surname,
        ID_No: userAccClientInfo.ID_No,
        Cell_No: userAccClientInfo.Cell_No,
        Telephone: userAccClientInfo.Telephone,
        ID_Type: userAccClientInfo.ID_Type,
        Recipient_Name: userAccAddress.DeliveryName,
        Recipient_Cell: userAccAddress.RecipientsCell,
        Business_Name_Delivery: userAccAddress.BussinesName,
        Complex_Building: userAccAddress.ComplexName,
        Street_Address: userAccAddress.Address,
        Suburb: userAccAddress.Suburb,
        City: userAccAddress.City,
        Province: userAccAddress.Province,
        Postal_Code: userAccAddress.PostalCode,
      };

      // Update Login Name
      if (userAccClientInfo.Prefered_Name !== "") {
        setPrefName(userAccClientInfo.Prefered_Name);
      } else {
        setPrefName(userAccClientInfo.Name);
      }

      // console.log(updatedInfo);
      setAcctInfoUpdate(updatedInfo);
      setBtnWait(false);
    }
    setUserCreateClick(false);
    setUserInfoCompleted(false);
    setAddressCompleted(false);
    setUserUpdate(false);
  };

  // Update Sales Payment on DB
  const updateAccInfo = async () => {
    const Business_Name = userAccClientInfo.Business_Name;
    const VAT_Number = userAccClientInfo.VAT_Number;
    const Name = userAccClientInfo.Name;
    const Prefered_Name = userAccClientInfo.Prefered_Name;
    const Surname = userAccClientInfo.Surname;
    const ID_No = userAccClientInfo.ID_No;
    const Cell_No = userAccClientInfo.Cell_No;
    const Telephone = userAccClientInfo.Telephone;
    const ID_Type = userAccClientInfo.ID_Type;
    const DeliveryName = userAccAddress.DeliveryName;
    const RecipientsCell = userAccAddress.RecipientsCell;
    const BussinesName = userAccAddress.BussinesName;
    const ComplexName = userAccAddress.ComplexName;
    const Address = userAccAddress.Address;
    const Suburb = userAccAddress.Suburb;
    const City = userAccAddress.City;
    const Province = userAccAddress.Province;
    const PostalCode = userAccAddress.PostalCode;

    let url = userAccInfoUpdate;
    console.log(url);
    const result = await axios
      .post(
        url,
        {
          userID: userID,
          Business_Name: Business_Name,
          VAT_Number: VAT_Number,
          Name: Name,
          Prefered_Name: Prefered_Name,
          Surname: Surname,
          ID_No: ID_No,
          Cell_No: Cell_No,
          Telephone: Telephone,
          ID_Type: ID_Type,
          DeliveryName: DeliveryName,
          RecipientsCell: RecipientsCell,
          BussinesName: BussinesName,
          ComplexName: ComplexName,
          Address: Address,
          Suburb: Suburb,
          City: City,
          Province: Province,
          PostalCode: PostalCode,
        },
        { headers: { Authorization: accessToken } } // JWS Token
      )
      .then((res) => res)
      .catch((err) => {
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });
    console.log(result);
    // console.log(result.data[0][0].Msg);

    if (result.status === 200) {
      if (result.data[0][0].Msg === "Error") {
        msgClean();
        setMsgTxt("Info already submitted, nothing updated!");
        setMsgType("Warning");
        setMsgOpen(true);
        return;
      } else if (result.data[0][0].Msg === "Successful") {
        msgClean();
        setMsgTxt("Account info updated successfully!");
        setMsgType("Success");
        setMsgOpen(true);
      }
    }
  };

  console.log("User info Update: " + userInfoCompleted);
  console.log("Address info Update: " + addressCompleted);
  // console.log(userUpdate);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="flex-row s1295:flex pl-5 justify-start BNP-Trans500">
        <div className="flex-row justify-center items-center">
          <UserInfoPageUpdate />
        </div>
        <div className="flex justify-start s1295:justify-center">
          <div className="flex my-4 s1300:border-l-2 border-BNPblue border-opacity-30 BNP-Trans500"></div>
          <div className="flex justify-center items-center w-[504PX]">
            <RegisterAddressPageUpdate />
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="s1295:flex-[500px] max-w-[501px] BNP-Trans500"></div>
        <div className="flex mb-3 justify-center w-[500px] BNP-Trans500">
          <button
            onMouseEnter={() => {
              setUserCreateClick(true);

              // Validate JWT accessToken
              document.getElementById("validateClick")?.click();
            }}
            onClick={() => updateAcctInfo()}
            className={
              userUpdate
                ? btnWait
                  ? "BNP-SubmitButtonsBlueWait w-[230px] mt-4 BNP-Trans500"
                  : "BNP-SubmitButtonsBlue w-[230px] mt-4 BNP-Trans500"
                : "BNP-SubmitButtonsBlue w-[230px] mt-4 opacity-25 hover:cursor-not-allowed BNP-Trans500"
            }
          >
            Update
          </button>
        </div>
      </div>
    </>
  );
}
