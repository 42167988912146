import React, { createContext, useState, useEffect } from "react";

// Context
const UserContext = createContext();

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
export function UserProvider({ children }) {
  const testing = 0; // 0 = Live; 1 = Testing

  //new user
  const [newUser, setNewUser] = useState([]);

  // State
  const [cookie, setCookie] = useState(false);
  const [userLogin, setUserLogin] = useState(false);
  const [userLoginCookie, setUserLoginCookie] = useState(false);
  const [userID, setUserID] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [tokenTime, setTokenTime] = useState("");
  const [userAdmin, setUserAdmin] = useState(false);
  const [businessUser, setBusinessUser] = useState(false);
  const [userEmail, setuserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const [PrefName, setPrefName] = useState("LOGIN");

  const [BusCheck, setBusCheck] = useState(false);
  const [BusName, setBusName] = useState("");
  const [RecipientName, setRecipientName] = useState("");
  const [CellNo, setCellNo] = useState("");
  const [IDno, setIDno] = useState("");
  const [IdType, setIdType] = useState("");

  const [deliverAddressUpdate, setDeliverAddressUpdate] = useState(false);
  const [addressInfo, setAddressInfo] = useState([]);
  const [acctInfoUpdate, setAcctInfoUpdate] = useState([]);
  const [DeliveryName, setDeliveryName] = useState("");
  const [RecipientsCell, setRecipientsCell] = useState("");
  const [bussinesName, setBussinesName] = useState("");
  const [ComplexName, setComplexName] = useState("");
  const [Address, setAddress] = useState("");
  const [Suburb, setSuburb] = useState("");
  const [City, setCity] = useState("");
  const [Province, setProvince] = useState("");
  const [PostalCode, setPostalCode] = useState("");

  useEffect(() => {
    if (accessToken?.length > 0) {
      if (refreshToken?.length > 0 && testing === 1) {
        console.log("New Access Token: " + accessToken);
        console.log(
          new Date(tokenTime).toLocaleString("en-SA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })
        );
      }
    }
  }, [accessToken, refreshToken, tokenTime]);

  // Write to local storage
  function saveLocalCust(t, u) {
    // console.log(t);
    // console.log(u);
    localStorage.setItem("BNPsessionID", JSON.stringify([t, u]));
  }

  // Read from local storage
  function readLocalID() {
    var items = JSON.parse(localStorage.getItem("BNPsessionID"));
    if (userLogin === false) {
      // console.log(items)
      if (items) {
        // console.log("Auto Login try");
        let ucn = items[1];
        // console.log(ucn);
        if (ucn > 0) {
          setUserLogin(items[0]);
          setUserID(ucn);
          setUserLoginCookie(true);
        }
      }
    }
  }

  function updateAddressContext(data) {
    setAddressInfo(data);
    
    const address = data.filter((data) => data.default_address === 1);

    // console.log(address[0])
    setDeliveryName(address[0]?.Recipient_Name);
    setRecipientsCell(address[0]?.Recipient_Cell);
    setBussinesName(address[0]?.Business_Name);
    setComplexName(address[0]?.Complex_Building);
    setAddress(address[0]?.Street_Address);
    setSuburb(address[0]?.Suburb);
    setCity(address[0]?.City);
    setProvince(address[0]?.Province);
    setPostalCode(address[0]?.Postal_Code);
  }


  function userLogOut() {
    setCookie(false);
    setuserEmail("");

    setUserLogin(false);
    setUserPassword("");
    setUserAdmin(false);
    setPrefName("LOGIN");
    setUserID("");
    setIdType("");
    setBusCheck(false);
    setBusName("");
    setRecipientName("");
    setCellNo("");
    setIDno("");
    setDeliveryName("");
    setRecipientsCell("");
    setBussinesName("");
    setComplexName("");
    setAddress("");
    setSuburb("");
    setCity("");
    setProvince("");
    setPostalCode("");
    setAddressInfo([]);
    setAcctInfoUpdate([]);
    setAccessToken("");
    setRefreshToken("");
    setTokenTime("");

    setNewUser([]);

    localStorage.removeItem("BNPsessionID");
  }

  // console.log("start")
  // console.log(cookie);
  // console.log(userPassword);
  // console.log(userLogin);
  // console.log(userID);
  // console.log(IDno);
  // console.log(userAdmin);

  // console.log(PrefName)

  // console.log(DeliveryName)
  // console.log(RecipientsCell)
  // console.log(Address)
  // console.log(Suburb)
  // console.log(City)
  // console.log(Province)
  // console.log(PostalCode)

  return (
    <UserContext.Provider
      value={{
        cookie,
        setCookie,
        readLocalID,

        saveLocalCust,
        newUser,
        setNewUser,

        userLogOut,

        userEmail,
        setuserEmail,
        userAdmin,
        setUserAdmin,
        businessUser,
        setBusinessUser,
        userLogin,
        setUserLogin,
        userLoginCookie,
        setUserLoginCookie,
        userID,
        setUserID,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        tokenTime,
        setTokenTime,
        userPassword,
        setUserPassword,
        PrefName,
        setPrefName,

        BusCheck,
        setBusCheck,
        BusName,
        setBusName,
        RecipientName,
        setRecipientName,
        CellNo,
        setCellNo,
        IDno,
        setIDno,
        IdType,
        setIdType,

        updateAddressContext,
        deliverAddressUpdate,
        setDeliverAddressUpdate,
        addressInfo, // For default selection. later build.
        acctInfoUpdate,
        setAcctInfoUpdate,
        DeliveryName,
        setDeliveryName,
        RecipientsCell,
        setRecipientsCell,
        bussinesName,
        setBussinesName,
        ComplexName,
        setComplexName,
        Address,
        setAddress,
        Suburb,
        setSuburb,
        City,
        setCity,
        Province,
        setProvince,
        PostalCode,
        setPostalCode,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
