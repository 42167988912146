import React, { useContext, useRef } from "react";

// React Icons
import * as aiIcons from "react-icons/ai";
import * as luIcons from "react-icons/lu";
import * as tfiIcons from "react-icons/tfi";

// Pages
import SalesInvoiceTeplate from "./SalesInvoiceTemplate";
import SalesInvoiceTemplateDownload from "./SalesInvoiceTemplateDownload";

// Context
import ModalContext from "../context/ModalContext";
import AdminContext from "../context/AdminContext";

// PDF Generator
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function SalesInvoiceModal() {
  // Context
  const {
    setInvoiceModalOpen,
    setTrackingModalOpen,
    msgClean,
    setMsgOpen,
    setMsgTxt,
    setMsgType,
  } = useContext(ModalContext);
  const { trackingSalesOrderInfo } = useContext(AdminContext);

  //state
  const SalesInvoiceRef = useRef(null);
  const SalesInvoiceRefDownload = useRef(null);

  const closeClick = () => {
    // Validate JWT accessToken
    document.getElementById("validateClick")?.click();
    setTrackingModalOpen(true);
    setInvoiceModalOpen(false);
  };

  const handleEmail = () => {
    generatePDF("email");
  };

  const handleDownload = async () => {
    generatePDF("download");
  };

  const generatePDF = async (ref) => {
    let input = SalesInvoiceRefDownload.current;
    // console.log(input)

    try {
      window.devicePixelRatio = 2;
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "a4",
      });

      const width = pdf.internal.pageSize.getWidth();
      const height = (canvas.height * width) / canvas.width;

      pdf.addImage(imgData, "JPEG", 0, 0, width, height, "someAlias", "FAST");

      if (ref === "download") {
        // Download PDF
        pdf.save("BNP Inv " + trackingSalesOrderInfo[0].Inv_No + ".pdf");
      } else {
        console.log("Email goes here");
      }
    } catch (error) {
      console.log(error);
      msgClean();
      setMsgTxt("There was an error when generating the PDF!");
      setMsgType("Error");
      setMsgOpen(true);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {/* Close Header */}
      <div className="flex justify-start lg:justify-center mt-0 lg:ml-[5%] BNP-Trans500">
        <div className="flex-row">
          <div className="flex justify-between w-auto h-[65px] text-BNPgoldLight bg-BNPblue shadow-md shadow-BNPblue rounded-t-md">
            <div className="flex justify-start items-start pt-5 pl-5">
              <aiIcons.AiOutlineClose
                className="rounded-full focus:outline-none transition-all duration-500 scale-[2.2] hover:font-semibold hover:scale-[2.9] hover:rotate-90
                  hover:border-2 hover:rounded-full hover:border-BNPgoldLight cursor-pointer hover:transition-all hover:duration-700"
                onClick={closeClick}
              />
            </div>

            <div className="flex justify-end items-center mb-3 mr-10">
              <div className="flex justify-center items-center w-14">
                <tfiIcons.TfiEmail
                  onClick={() => handleEmail}
                  className="focus:outline-none transition-all duration-500 scale-[1.5] hover:font-semibold hover:scale-[1.8] 
                  cursor-pointer"
                />
              </div>
              <div className="flex justify-center items-center w-14">
                <luIcons.LuDownload
                  onClick={() => handleDownload()}
                  className="focus:outline-none transition-all duration-500 scale-[1.5] hover:font-semibold hover:scale-[1.8] 
                  cursor-pointer"
                />
              </div>
            </div>
          </div>

          {/* Invoice */}
          <div className="InvBody InvPrint -mt-3 bg-white p-5 rounded-2xl border-4 border-blue-200 h-svh">
            <div className="relative">
              <div ref={SalesInvoiceRef}>
                <SalesInvoiceTeplate />
              </div>
              <div className="absolute w-full top-0 opacity-0">
                <div ref={SalesInvoiceRefDownload}>
                  <SalesInvoiceTemplateDownload />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalesInvoiceModal;
